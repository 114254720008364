<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import FilterModal from "../../Utils/Filter.vue";
import ProgressModal from "../../Utils/Progress.vue";
import RoleModal from "./RoleModal.vue";
import RoleRouter from "@/api/routers/Role";
export default defineComponent({
  components: {
    FilterModal,
    ProgressModal,
    RoleModal,
  },
  data() {
    return {
      loading: false,
      pagination: {},
      pageRows: 10,
      filterModal: {
        show: false,
        showFilters: ["general"],
        selectedFilters: {},
      },
      hasFilters: false,
      roles: {
        records: [],
        totalRecords: 0,
        selection: [],
      },
      roleModal: {
        show: false,
        save: false,
        valid: false,
        progress: false,
        action: "create",
        title: null,
        role: {},
      },
      filters: {
        name: {
          value: "",
          matchMode: "contains",
        },
      },
      RoleApi: null,
    };
  },
  created() {
    this.RoleApi = new RoleRouter();
    this.FilterInit();
  },
  mounted() {
    this.pageRows = this.$refs.dt.rows;
    this.LoadRoles();
  },
  methods: {
    ShowRoleModal(action, slot) {
      this.roleModal.action = action;
      this.roleModal.role = action == "update" ? { ...slot } : null;
      this.roleModal.title = `${
        action == "create" ? "Cadastrar" : "Atualizar"
      } função`;
      this.roleModal.show = true;
    },
    Valid(event) {
      event ? (this.roleModal.valid = true) : (this.roleModal.valid = false);
    },
    RoleModalResetState() {
      this.roleModal.show = false;
      this.roleModal.valid = false;
      this.roleModal.save = false;
      this.roleModal.role = {};
      this.LoadRoles();
    },
    RolesRemove() {
      this.$confirm.require({
        header: "Excluir",
        message:
          "Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-danger",
        accept: () => {
          this.RoleApi.Remove(this.roles.selection.map((c) => { return c.ID; }))
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação realizada com sucesso",
                life: 3000,
              });
            })
            .finally(this.LoadRoles());
        },
      });
    },
    LoadRoles() {
      this.loading = true;
      setTimeout(() => {
        this.RoleApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.roles.records = resp.roles;
            this.roles.totalRecords = resp.totalRecords;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadRoles();
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      this.pagination.filters = this.filters;
      this.LoadRoles();
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Funções</span>
    </template>
    <template #content>
      <Toolbar class="h-6rem">
        <template v-slot:start>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-plus"
              class="p-button-rounded p-button-success p-button-outlined p-button-raised"
              @click="ShowRoleModal('create')"
              :disabled="loading"
              title="Adicionar nova função"
            />
            <Button
              icon="fas fa-trash"
              class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
              @click="RolesRemove()"
              :disabled="roles.selection.length == 0"
              title="Excluir função selecionada"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadRoles()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        dataKey="ID"
        :value="roles.records"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="roles.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="onSort($event)"
        lazy="true"
        v-model:selection="roles.selection"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />
        <Column
          selectionMode="multiple"
          headerStyle="width:.2%; min-width:1rem;"
        />
        <Column
          field="ID"
          header="ID"
          :sortable="false"
          headerStyle="width:.30%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data?.ID }}</span>
          </template>
        </Column>
        <Column
          field="name"
          header="Nome"
          :sortable="false"
          headerStyle="width:6%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nome</span>
            <span>{{ slotProps.data?.name }}</span>
          </template>
        </Column>
        <Column
          field="description"
          header="Descrição"
          :sortable="false"
          headerStyle="width:10%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Descrição</span>
            <span>{{ slotProps.data?.description }}</span>
          </template>
        </Column>
        <Column headerStyle="width: 2%; min-width:5rem;" >
          <template #body="slotProps">
            <Button
              icon="fas fa-pencil"
              class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
              title="Editar pessoa"
              @click="ShowRoleModal('update', slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="roleModal.show"
    :header="!roleModal.progress ? roleModal.title : 'Aguarde...'"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
    :closable="!roleModal.progress"
    @hide="RoleModalResetState()"
  >
    <RoleModal
      v-if="!roleModal.progress"
      :role="roleModal.role"
      :action="roleModal.action"
      :save="roleModal.save"
      @valid="Valid($event)"
      @progress="roleModal.progress = $event"
      @close="roleModal.show = false"
    />
    <ProgressModal v-if="roleModal.progress" />
    <template #footer>
      <div
        class="flex flex-row justify-content-between"
        v-if="!roleModal.progress"
      >
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="roleModal.show = false"
          class="w-12rem"
        />
        <Button
          label="Salvar"
          iconPos="right"
          icon="pi pi-check"
          autofocus
          :disabled="!roleModal.valid"
          @click="roleModal.save = true"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>

</template>
