<script>
import { defineComponent } from 'vue';
import PersonRouter from '@/api/routers/Person';
import PersonTypesRouter from '@/api/routers/PersonTypes';
import moment from 'moment';
export default defineComponent({
    props: ['action', 'person', 'save'],
    emits: ['valid', 'close', 'progress'],
    data() {
        return {
            personForm: {
                name: null,
                txID: null,
                birthDate: null,
                phone: null,
                email: null
            },
            personTypes: {
                records: [],
                selectedPersonType: null,
                loading: false
            },
            PersonApi: null,
            PersonTypesApi: null
        };
    },
    created() {
        this.PersonApi = new PersonRouter();
        this.PersonTypesApi = new PersonTypesRouter();
    },
    async mounted() {
        await this.LoadPersonTypes();
        this.GetAction();
    },
    computed: {
        GetPersonTypeLabel() {
            let personTypes = this.personTypes.records.find((p) => p.value == this.personTypes.selectedPersonType);
            return personTypes.label;
        }
    },
    methods: {
        GetAction() {
            if (this.action == 'update') {
                this.personForm = { ...this.person };
                this.personTypes.selectedPersonType = this.person.personTypeId;
                if (this.person?.birthDate) {
                    this.personForm.birthDate = moment(this.person?.birthDate).format('DD/MM/YYYY');
                }
            }
        },
        Valid() {
            const regexPattern = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
            if (this.personForm.name?.length >= 4 && this.personForm.txID?.length >= 11 && this.personForm.phone?.length >= 11 && this.personTypes.selectedPersonType != null && regexPattern.test(this.personForm.email)) {
                this.$emit('valid', true);
            } else {
                this.$emit('valid', false);
            }
        },
        Save() {
            switch (this.action) {
                case 'create':
                    this.CreatePerson();
                    break;
                case 'update':
                    this.UpdatePerson();
                    break;
            }
        },
        async UpdatePerson() {
            this.$emit('progress', true);
            setTimeout(async () => {
                await this.PersonApi.Update(this.GetPayload(), this.personForm.ID)
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: 'Operação realizada com sucesso',
                            life: 3000
                        });
                    })
                    .finally(() => {
                        this.$emit('progress', false);
                        this.$emit('close');
                    });
            }, 2000);
        },
        async CreatePerson() {
            this.$emit('progress', true);
            setTimeout(async () => {
                await this.PersonApi.Create(this.GetPayload())
                    .then(() => {
                        this.$toast.add({
                            severity: 'success',
                            summary: 'Sucesso',
                            detail: `Operação realizada com sucesso`,
                            life: 2000
                        });
                    })
                    .finally(() => {
                        this.$emit('progress', false);
                        this.$emit('close');
                    });
            }, 2000);
        },
        async LoadCompanies() {
            this.companies.loading = true;
            this.companies.records = await this.CompanyApi.FindAll()
                .then((resp) => {
                    return resp?.companies.map((c) => {
                        return {
                            label: c.name,
                            value: c.ID
                        };
                    });
                })
                .finally((this.companies.loading = false));
        },
        async LoadPersonTypes() {
            this.personTypes.loading = true;
            this.personTypes.records = await this.PersonTypesApi.FindAll()
                .then((resp) => {
                    return resp?.personTypes.map((p) => {
                        return {
                            label: p.name,
                            value: p.ID
                        };
                    });
                })
                .finally((this.personTypes.loading = false));
        },
        GetPayload() {
            let payload = {};
            try {
                payload = {
                    name: this.personForm.name,
                    personTypeId: this.personTypes.selectedPersonType,
                    txID: this.personForm.txID,
                    phone: this.personForm.phone,
                    email: this.personForm.email
                };
                if (this.personForm?.birthDate != null) {
                    payload.birthDate = moment(this.personForm.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
                }
                return payload;
            } catch {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro critico',
                    detail: 'Ocorreu um erro ao obter as informações necessárias',
                    life: 3000
                });
            }
        }
    },
    watch: {
        save: function () {
            this.Save();
        }
    }
});
</script>

<template>
    <div class="flex flex-column field">
        <label for="personName">Nome:</label>
        <InputText id="personName" v-model="personForm.name" placeholder="Nome da pessoa" @input="Valid()" />
    </div>
    <div class="flex flex-column field">
        <label for="personTypes">Tipo de documento:</label>
        <Dropdown
            id="personTypes"
            v-model="personTypes.selectedPersonType"
            optionLabel="label"
            optionValue="value"
            placeholder="Selecione um tipo de pessoa"
            emptyFilterMessage="Sem resultados"
            emptyMessage="Sem resultados"
            :filter="true"
            :options="personTypes.records"
            :loading="personTypes.loading"
        />
    </div>
    <div class="flex flex-column field" v-if="personTypes.selectedPersonType != null">
        <label for="role">{{ GetPersonTypeLabel }}:</label>
        <InputText id="role" v-model="personForm.txID" :placeholder="GetPersonTypeLabel + ' da pessoa'" @input="Valid()" />
    </div>
    <div class="flex flex-column field">
        <label for="phone">Telefone:</label>
        <InputText id="phone" maxlength="11" v-model="personForm.phone" placeholder="Telefone da pessoa" @input="Valid()" />
    </div>
    <div class="flex flex-column field">
        <label for="mail">Email:</label>
        <InputText id="mail" type="text" v-model="personForm.email" placeholder="Email da pessoa" @input="Valid()" />
    </div>
    <div class="flex flex-column field">
        <label for="birth_date">Data de nascimento:</label>
        <Calendar :showButtonBar="true" id="birth_date" inputId="single" :showTime="false" v-model="personForm.birthDate" selectionMode="single" :manualInput="false" :showIcon="true" dateFormat="dd/mm/yy" @date-select="Valid()" />
    </div>
</template>
