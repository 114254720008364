<script>
import { defineComponent } from "vue";
import PersonRouter from "@/api/routers/Person";
import CompanyRouter from "@/api/routers/Company";
import RoleRouter from "@/api/routers/Role";
import UserRouter from "@/api/routers/User";
export default defineComponent({
  props: ["action", "user", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      people: {
        records: [],
        selectedPerson: null,
        loading: false,
      },
      companies: {
        records: [],
        selectedCompany: null,
        loading: false,
      },
      roles: {
        records: [],
        selectedRole: null,
        loading: false,
      },
      username: null,
      PersonApi: null,
      CompanyApi: null,
      RoleApi: null,
      UserApi: null,
    };
  },
  created() {
    this.PersonApi = new PersonRouter();
    this.CompanyApi = new CompanyRouter();
    this.RoleApi = new RoleRouter();
    this.UserApi = new UserRouter();
  },
  async mounted() {
    await this.LoadPeople();
    await this.LoadCompanies();
    await this.LoadRoles();
    this.GetAction();
  },
  methods: {
    GetAction() {
      if (this.action == "update") {
        this.people.selectedPerson = this.user?.personID;
        this.companies.selectedCompany = this.user?.companyID;
        this.roles.selectedRole = this.user?.roleId;
        this.username = this.user?.username;
      }
    },
    Valid() {
      const regexPattern = new RegExp("^[a-zA-Z0-9_.-]*$");
      if (
        this.people.selectedPerson != null &&
        this.companies.selectedCompany != null &&
        this.roles.selectedRole != null &&
        this.username?.length > 0 &&
        regexPattern.test(this.username)
      ) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreateUser();
          break;
        case "update":
          this.UpdateUser();
          break;
      }
    },
    GetEmailOfPersonSelected() {
      let user = this.people.records.find(
        (p) => p.value === this.people.selectedPerson
      );
      return user?.email;
    },
    async UpdateUser() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.UserApi.Update(this.GetPayload(false), this.user.ID)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreateUser() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.UserApi.Create(this.GetPayload(true))
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Usuário criado com sucesso. A senha foi enviada para o email: ${this.GetEmailOfPersonSelected()}`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async LoadRoles() {
      this.roles.loading = true;
      this.roles.records = await this.RoleApi.FindAll()
        .then((resp) => {
          return resp?.roles.map((r) => {
            return {
              label: r.name,
              value: r.ID,
            };
          });
        })
        .finally((this.roles.loading = false));
    },
    async LoadCompanies() {
      this.companies.loading = true;
      this.companies.records = await this.CompanyApi.FindAll()
        .then((resp) => {
          return resp?.companies.map((c) => {
            return {
              label: c.name,
              value: c.ID,
            };
          });
        })
        .finally((this.companies.loading = false));
    },
    async LoadPeople() {
      this.people.loading = true;
      this.people.records = await this.PersonApi.FindAll()
        .then((resp) => {
          return resp?.people.map((p) => {
            return {
              label: p.name,
              value: p.ID,
              email: p.email,
            };
          });
        })
        .finally((this.people.loading = false));
    },
    GetPayload(create) {
      let payload = {
        username: this.username,
        personID: this.people.selectedPerson,
        roleId: this.roles.selectedRole,
        companyID: this.companies.selectedCompany,
        ldapAuth: false,
      };
      if (create) {
        payload.autoSetPassword = true;
        payload.sendPasswordToMail = true;
      }
      return payload;
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="people">Pessoa:</label>
    <Dropdown
      id="people"
      v-model="people.selectedPerson"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione um pessoa"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="people.records"
      :loading="people.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="companies">Empresa:</label>
    <Dropdown
      id="companies"
      v-model="companies.selectedCompany"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione uma compania"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="companies.records"
      :loading="companies.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="role">Função</label>
    <Dropdown
      id="role"
      v-model="roles.selectedRole"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione um função"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="roles.records"
      :loading="roles.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="username">Usuário:</label>
    <InputText
      id="username"
      type="text"
      v-model="username"
      placeholder="Nome de usuário"
      @input="Valid()"
    />
  </div>
</template>
