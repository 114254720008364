<script>
    import { defineComponent } from 'vue';
    import PersonRouter from '@/api/routers/Person';
    import CompanyRouter from '@/api/routers/Company';
    import PayrollDocumentModelRouter from '@/api/routers/PayrollDocumentModel';

    export default defineComponent({
        props: ['documentSupports', 'formData', 'contentType'],
        emits: ['documentProperties', 'valid'],
        data() {
            return {
                PersonApi: null,
                CompanyApi: null,
                PayrollDocumentModelApi: null,
                owners: [],
                payrollDocumentModels: [],
                documentAttributes: {
                    owner: null,
                    reference: null,
                    model: null,
                    optionals: {
                        protect: false,
                        split: false,
                        autoSetRecipient: false
                    }
                }
            };
        },
        beforeUnmount() {
            this.$emit('documentProperties', this.documentAttributes);
        },
        created() {
            this.PersonApi = new PersonRouter();
            this.CompanyApi = new CompanyRouter();
            this.PayrollDocumentModelApi = new PayrollDocumentModelRouter();
        },
        methods: {
            async loadPeople() {
                this.owners = await this.PersonApi.FindAll().then((resp) => {
                    return resp.people;
                });
            },
            async loadCompanies() {
                this.owners = await this.CompanyApi.FindAll().then((resp) => {
                    return resp.companies;
                });
            },
            async loadDocumentModels() {
                const { payrollDocumentModels } = await this.PayrollDocumentModelApi.FindAll();
                this.payrollDocumentModels = payrollDocumentModels;
            },
            valid() {
                if (!this.documentAttributes?.owner || !this.documentAttributes?.model) {
                    this.$emit('valid', false);
                    return;
                }
                this.$emit('valid', true);
            }
        },
        mounted() {
            if (this.isPayrollDocument) {
                this.documentAttributes.optionals.autoSetRecipient = true;
                this.documentAttributes.optionals.split = true;
                this.documentAttributes.optionals.protect = true;
                this.loadCompanies();
                this.loadDocumentModels();
            } else {
                this.loadPeople();
            }
        },
        computed: {
            isPayrollDocument() {
                return this.formData?.documentType.value == 'payroll';
            }
        }
    });
</script>

<template>
    <div class="field">
        <label for="setDocOwner">Proprietário do documento:</label>
        <Dropdown
            id="setDocOwner"
            v-model="documentAttributes.owner"
            :options="owners"
            optionLabel="name"
            optionValue="ID"
            @change="valid()"
            required="true"
            placeholder="Selecione" />
        <small class="p-invalid" v-if="!documentAttributes.owner">Obrigatório</small>
    </div>
    <div class="field">
        <label for="setDocOwner">Modelo do documento:</label>
        <Dropdown
            id="docModel"
            v-model="documentAttributes.model"
            :options="payrollDocumentModels"
            optionLabel="name"
            optionValue="ID"
            @change="valid()"
            required="true"
            placeholder="Selecione" />
        <small class="p-invalid" v-if="!documentAttributes.model">Obrigatório</small>
    </div>
    <div class="field">
        <label for="setDocRefer">Data de referência:</label>
        <Calendar
            class="mt-2"
            :showButtonBar="true"
            id="setDocRefer"
            inputId="single"
            view="month"
            dateFormat="mm/yy"
            v-model="documentAttributes.reference"
            selectionMode="single"
            :manualInput="false"
            :showIcon="true" />
    </div>
    <div class="field">
        <label for="setDocOptionals">Opcionais:</label>
        <div>
            <div class="flex justify-content-start mt-1">
                <Checkbox
                    class="mr-2"
                    name="setDocProtection"
                    v-model="documentAttributes.optionals.protect"
                    :binary="true"
                    :disabled="!documentSupports?.password || contentType != 'Arquivo'" />
                <label for="setDocProtection">Proteger documento(s) com senha</label>
            </div>
            <div class="flex justify-content-start mt-2">
                <Checkbox
                    class="mr-2"
                    name="setDocSplit"
                    v-model="documentAttributes.optionals.split"
                    :binary="true"
                    :disabled="isPayrollDocument || !documentSupports?.split || contentType != 'Arquivo'" />
                <label for="setDocSplit">Dividir páginas do documento</label>
            </div>
            <div class="flex justify-content-start mt-2">
                <Checkbox
                    class="mr-2"
                    name="setRecipientDocument"
                    v-model="documentAttributes.optionals.autoSetRecipient"
                    :binary="true"
                    :disabled="true" />
                <label for="setRecipientDocument">Atribuir automaticamente aos colaboradores</label>
            </div>
        </div>
    </div>
</template>
