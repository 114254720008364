import AxiosInstance from './AxiosInstance';
import emitter from './Emitter';
import Token from './Token';
import AuthRouter from '@/api/routers/Auth';
import router from '@/router';

const AuthApi = new AuthRouter();
const ignoreEndpoints = ['/auth/login', '/auth/refresh'];
let hasRefreshPending = false;
let serverError = false;

/**
 * Resets the `serverError` flag to `false`.
 */
const resetServerError = () => {
    serverError = false;
};

/**
 * Emits a toast message using the event emitter.
 * 
 * @param {Object} toast - Toast configuration.
 * @param {string} toast.type - The type of event (e.g., 'error').
 * @param {Object} toast.event - Event details.
 * @param {string} toast.event.severity - The severity of the event (e.g., 'error', 'warn').
 * @param {string} toast.event.summary - Summary of the toast.
 * @param {string} toast.event.detail - Detailed message of the toast.
 * @param {number} toast.event.life - Lifespan of the toast in milliseconds.
 * @param {string} toast.event.group - Group for grouping the event.
 */
const emitterToast = (toast) => {
    emitter.emit(toast.type, {
        severity: toast.event.severity,
        summary: toast.event.summary,
        detail: toast.event.detail,
        life: toast.event.life,
        group: toast.event.group,
    });
};

/**
 * Handles various error responses by emitting the appropriate toast notification.
 * 
 * @param {Object} error - The error object received from Axios.
 */
const handleErrorResponse = (error) => {
    const status = error.response?.status;
    if (!serverError) {
        const messages = {
            400: 'Falha na operação, os dados enviados já existem, são inválidos, ou a solicitação está malformada.',
            422: 'Não foi possível concluir a operação, por favor verifique se todos os campos foram preenchidos corretamente e tente novamente.',
            401: 'Usuário ou senha inválidos. Verifique suas credenciais e tente novamente.',
            403: 'Você não tem permissão para acessar o conteúdo selecionado. Contate o administrador para obter acesso.',
            404: 'O conteúdo que você tentou acessar não existe ou pode estar indisponível no momento. Verifique o URL ou tente novamente mais tarde.',
            500: 'O servidor encontrou um erro interno e não pode processar esta solicitação no momento. Por favor, acione o suporte.',
            501: 'Esta funcionalidade ainda não está implementada no servidor. Por favor, acione o suporte.',
            502: 'Resposta inválida de um servidor upstream. Por favor, acione o suporte.',
            503: 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde ou acione o suporte.',
            504: 'Tempo limite de espera atingido, o servidor upstream não respondeu a tempo. Por favor, tente novamente mais tarde ou acione o suporte.',
        };

        const message = messages[status] || error.message;
        const severity = status >= 400 && status <= 499 ? 'warn' : 'error';
        const summary = status >= 400 && status <= 499 ? 'Atenção' : 'Erro';

        emitterToast({
            type: 'error',
            event: {
                severity,
                summary,
                detail: message,
                life: 5000,
                group: 'error',
            },
        });

        if (status >= 500) {
            serverError = true;
        }
    }
};

/**
 * Attempts to refresh the token if it has expired and reattempt the original request.
 * 
 * @param {Object} originConfig - The original Axios request configuration.
 * @param {Object} store - Vuex store for managing auth state.
 * @returns {Promise} - Returns the result of the refreshed Axios request or throws an error.
 */
const refreshToken = async (originConfig, store) => {
    hasRefreshPending = true;

    if (originConfig._retry === undefined) {
        originConfig._retry = 0;
    }

    originConfig._retry++;

    if (originConfig._retry <= 3) {
        try {
            const item = await AuthApi.Refresh({ refreshToken: Token.getRefreshToken() });
            if (item?.success) {
                Token.setAuthorizationToken(item.data?.login.accessToken);
                Token.setRefreshToken(item.data?.login.refreshToken);
                store.dispatch('auth/refresh', item.data?.login.refreshToken);
                originConfig._retry = 0;
                hasRefreshPending = false;
                return AxiosInstance(originConfig);
            } else {
                store.dispatch('auth/logout');
                router.push('/auth/login');
                hasRefreshPending = false;
                return Promise.reject(new Error('Refresh token failed'));
            }
        } catch (error) {
            store.dispatch('auth/logout');
            hasRefreshPending = false;
            return Promise.reject(error);
        }
    } else {
        emitterToast({
            type: 'error',
            event: {
                severity: 'error',
                summary: 'Login inválido',
                detail: 'Número máximo de tentativas de refresh excedido.',
                life: 5000,
                group: 'error',
            },
        });
        store.dispatch('auth/logout');
        hasRefreshPending = false;
        return Promise.reject(new Error('Número máximo de tentativas de refresh excedido'));
    }
};

/**
 * Sets up Axios interceptors to handle token injection and error responses.
 * 
 * @param {Object} store - Vuex store for managing auth state.
 */
const setup = (store) => {
    AxiosInstance.interceptors.request.use(
        (config) => {
            const token = Token.getAuthorizationToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => Promise.reject(error)
    );

    AxiosInstance.interceptors.response.use(
        (response) => {
            resetServerError();
            return response;
        },
        async (error) => {
            const originConfig = error?.config;
            const isIgnoredEndpoint = ignoreEndpoints.includes(originConfig.url);

            const { status } = error.response;
            handleErrorResponse(error);

            if (status === 401 && !isIgnoredEndpoint && !hasRefreshPending && Token.getAuthorizationToken()) {
                return refreshToken(originConfig, store);
            }

            return Promise.reject(error);
        }
    );
};

export default setup;
