<script>
    import { FilterMatchMode } from 'primevue/api';
    import { defineComponent } from 'vue';
    import { debounce } from 'lodash-es';

    export default defineComponent({
        emits: ['selection', 'unSelection'],
        props: {
            /**
             * Holds the current selection data.
             * @type {Object}
             */
            currentSelection: Object,

            /**
             * Enables or disables multiselect functionality.
             * @type {boolean}
             */
            multiselect: Boolean,

            /**
             * Additional to implements in search
             * @type {boolean}
             */
            additionalFilters: Object,

            /**
             * Defines the columns to be displayed.
             * @type {Array}
             */
            showColumns: Array,

            /**
             * Object containing API search methods.
             * @type {Object}
             */
            searchAPI: Object,

            /**
             * Property name used by the search API for data retrieval.
             * @type {string}
             */
            apiPropertyName: String,

            /**
             * Determines whether the search input should be visible.
             * @type {boolean}
             */
            showSearch: Boolean
        },
        data() {
            return {
                /**
                 * Indicates if data is being loaded.
                 * @type {boolean}
                 */
                loading: false,

                /**
                 * Number of rows to display per page.
                 * @type {number}
                 */
                pageRows: 10,

                /**
                 * Selection mode, based on whether multiselect is enabled.
                 * @type {string}
                 */
                selectionMode: this.multiselect ? 'multiple' : 'single',

                /**
                 * Debounced function for filtering.
                 * @type {Function}
                 */
                debounceOnFilter: null,

                /**
                 * Object holding records, including data, total count, and selection.
                 * @type {Object}
                 */
                records: {
                    data: [],
                    totalRecords: 0,
                    selection: this.currentSelection || null
                },

                /**
                 * Filters applied to the search query.
                 * @type {Object}
                 */
                filters: {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },

                /**
                 * Pagination settings, including current page and rows per page.
                 * @type {Object}
                 */
                pagination: {
                    page: 1,
                    rows: this.pageRows
                }
            };
        },
        created() {
            this.debounceOnFilter = debounce(() => this.onFilter(), 500);
            this.filterInit();
            this.validateSearchAPIFn();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.lazyLoad();
        },
        methods: {
            /**
             * Validates that the searchAPI contains a Search function.
             * @throws {Error} If the searchAPI does not have a valid Search function.
             */
            validateSearchAPIFn() {
                if (typeof this.searchAPI.Search !== 'function') {
                    throw new Error('Invalid search function implementation');
                }
            },

            /**
             * Loads data from the API based on the current pagination and filters.
             * @returns {Promise<void>}
             */
            async lazyLoad() {
                this.loading = true;
                try {
                    const filterSearch = JSON.stringify(this.pagination);
                    const response = await this.searchAPI.Search(filterSearch);

                    if (!response || typeof response !== 'object') {
                        throw new Error('Invalid API search response data');
                    }

                    this.records.data = response[this.apiPropertyName] || [];
                    this.records.totalRecords = response.totalRecords || 0;
                } catch (err) {
                    console.error('Error during lazyLoad:', err);
                } finally {
                    this.loading = false;
                }
            },

            /**
             * Filters the data based on the current filter settings.
             */
            onFilter() {
                this.pagination.filters = this.filters;
                this.lazyLoad();
            },

            /**
             * Updates pagination and reloads data when the page changes.
             * @param {Object} event - The pagination event data.
             */
            onPage(event) {
                this.pagination = event;
                this.pagination['filters'] = {
                    ...this.pagination?.filters,
                    ...this.additionalFilters
                };
                this.lazyLoad();
            },

            /**
             * Emits the selection event with the selected records.
             */
            emitterSelection() {
                this.$emit('selection', this.records.selection);
            },

            /**
             * Emits the un-selection event with the selected records.
             */
            emitterUnSelection(event) {
                this.$emit('unSelection', event.data);
            },

            /**
             * Receive event and associate data to a records selection
             * and call emitter selection
             * 
             * @param event - The datatable event
             */
            onRowSelectAll(event) {
                this.records.selection = event.data
                this.emitterSelection()
            },
            onRowUnSelectAll(event) {
                console.log(event.data)
                this.records.selection = event.data
                this.emitterUnSelection()
            },         
            /**
             * Checks if a specifics columns should be displayed.
             * @param {Array} columnNames - The name of the columns to check.
             * @returns {boolean} True if the column should be shown, false otherwise.
             */
            hasViewColumns(...columnNames) {
                return columnNames.some((column) => this.showColumns?.includes(column));
            },

            /**
             * Initializes pagination settings.
             */
            filterInit() {
                this.filters = {
                    ...this.additionalFilters,
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };

                this.pagination = {
                    page: 1,
                    rows: this.pageRows,
                    filters: this.filters
                };
            }
        },
        watch: {
            /**
             * Watches for changes in currentSelection and updates records accordingly.
             * @param {Object} newSelection - The new selection data.
             */
            currentSelection(newSelection) {
                this.records.selection = newSelection;
            }
        }
    });
</script>

<template>
    <DataTable
        lazy
        paginator
        showGridlines
        ref="dt"
        dataKey="ID"
        v-model:filters="filters"
        v-model:selection="records.selection"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        class="p-datatable-sm w-full"
        responsiveLayout="scroll"
        :metaKeySelection="false"
        :selectionMode="selectionMode"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        :value="records.data"
        :rows="pageRows"
        :loading="loading"
        :totalRecords="records.totalRecords"
        @page="onPage"
        @filter="onFilter"
        @rowSelect="emitterSelection"
        @rowSelectAll="onRowSelectAll"
        @rowUnselect="emitterUnSelection">
        <template #header v-if="showSearch">
            <div class="flex justify-content-between p-2">
                <div class="flex justify-content-end">
                    <span class="p-input-icon-left">
                        <i class="pi pi-search" />
                        <InputText v-model="filters.global.value" class="p-inputtext-md w-22rem" placeholder="Buscar..." @input="debounceOnFilter" />
                    </span>
                </div>

                <Button class="p-button-rounded p-button-outlined" icon="fas fa-redo-alt" @click="lazyLoad" />
            </div>
        </template>
        <template #empty>
            <div class="flex justify-content-center align-items-center">
                <span class="text-lg">Nenhum resultado encontrado</span>
            </div>
        </template>
        <template #loading>
            <div class="flex justify-content-center align-items-center">
                <span class="text-lg">Carregando informações, por favor aguarde...</span>
            </div>
        </template>

        <!-- Columns -->
        <Column :selectionMode="selectionMode" headerStyle="width:.2%; min-width:1rem;" />
        <Column field="ID" header="ID" :sortable="false" headerStyle="width:.30%; min-width:2rem;" v-if="hasViewColumns('id', 'ID')">
            <template #body="slotProps">
                <span class="p-column-title">ID</span>
                <span>{{ slotProps.data?.id || slotProps.data?.ID }}</span>
            </template>
        </Column>
        <Column header="Nome" :sortable="false" headerStyle="width:3%; min-width:2rem;" v-if="hasViewColumns('name')">
            <template #body="slotProps">
                <span class="p-column-title">Nome</span>
                <span>{{ slotProps.data?.person?.name || slotProps.data?.company?.name || slotProps.data?.name }}</span>
            </template>
        </Column>
        <Column header="Rótulo" :sortable="false" headerStyle="width:3%; min-width:2rem;" v-if="hasViewColumns('label')">
            <template #body="slotProps">
                <span class="p-column-title">Rótulo</span>
                <span>{{ slotProps.data?.label }}</span>
            </template>
        </Column>
        <Column header="Rótulo" :sortable="false" headerStyle="width:3%; min-width:2rem;" v-if="hasViewColumns('title')">
            <template #body="slotProps">
                <span class="p-column-title">Título</span>
                <span>{{ slotProps.data?.title }}</span>
            </template>
        </Column>
        <Column header="Descrição" :sortable="false" headerStyle="width:5%; min-width:2rem;" v-if="hasViewColumns('description')">
            <template #body="slotProps">
                <span class="p-column-title">Descrição</span>
                <span>{{ slotProps.data?.description }}</span>
            </template>
        </Column>
        <Column header="CPF/CNPJ" :sortable="false" headerStyle="width:5%; min-width:2rem;" v-if="hasViewColumns('tx_id')">
            <template #body="slotProps">
                <span class="p-column-title">CPF/CNPJ</span>
                <span>{{ slotProps.data?.person?.txID || slotProps.data?.company?.txID || slotProps.data?.txID }}</span>
            </template>
        </Column>
    </DataTable>
</template>
