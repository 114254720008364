<script>
import { defineComponent } from '@vue/runtime-core';
import PersonRouter from '@/api/routers/Person';
import SendChannelRouter from '@/api/routers/SendChannel';
import DocumentRouter from '@/api/routers/Document';
import EnvelopeRouter from '@/api/routers/Envelope';
import EnvelopeTypeRouter from '@/api/routers/EnvelopeType';
export default defineComponent({
    props: ['document', 'finish'],
    emits: ['finished'],
    data() {
        return {
            people: {
                records: []
            },
            sendChannels: {
                records: []
            },
            envelope: {
                showTweaks: false,
                title: null,
                description: null,
                sendChannels: [],
                envelopeTypeId: null
            },
            envelopeTypes: {
                records: []
            },
            documentOptions: {
                ownerId: null,
                protect: false,
                makeEnvelope: false
            },
            PersonApi: null,
            SendChannelApi: null,
            DocumentApi: null,
            EnvelopeTypesApi: null,
            EnvelopeApi: null
        };
    },
    created() {
        this.EnvelopeApi = new EnvelopeRouter();
        this.EnvelopeTypesApi = new EnvelopeTypeRouter();
        this.PersonApi = new PersonRouter();
        this.SendChannelApi = new SendChannelRouter();
        this.DocumentApi = new DocumentRouter();
    },
    async mounted() {
        await this.LoadPeople();
        this.LoadSendChannels();
        this.LoadEnvelopeTypes();
        this.GetDocumentCurrentOwner();
    },
    methods: {
        async LoadPeople() {
            const { people } = await this.PersonApi.FindAll();
            this.people.records = people;
        },
        async LoadSendChannels() {
            const { sendChannels } = await this.SendChannelApi.FindAll();
            this.sendChannels.records = sendChannels;
        },
        async LoadEnvelopeTypes() {
            const { envelopeTypes } = await this.EnvelopeTypesApi.FindAll();
            this.envelopeTypes.records = envelopeTypes;
        },
        async GetDocumentCurrentOwner() {
            this.documentOptions.ownerId = await this.document.people.map((p) => {
                return p.ID;
            })[0];
        },
        ShowToast(severity, summary, detail, life) {
            this.$toast.add({
                severity: severity,
                summary: summary,
                detail: detail,
                life: life
            });
        },
        UpdateDocument() {
            this.$confirm.require({
                header: 'Confirmar alterações',
                message: 'Confirmar as alterações realizadas?',
                icon: 'fas fa-question-circle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                acceptClass: 'p-button-danger',
                accept: async () => {
                    try {
                        await this.DocumentApi.Update(this.document.ID, this.GetDocumentPayload());
                        if (this.documentOptions.makeEnvelope) {
                            this.NewEnvelope();
                        } else {
                            this.ShowToast('success', 'Sucesso', 'Operação realizada com sucesso', 3000);
                            this.$emit('finished', true);
                        }
                    } catch (error) {
                        this.ShowToast('error', 'Erro', 'Ocorreu um erro ao atualizar o documento', 3000);
                    }
                }
            });
        },
        async NewEnvelope() {
            try {
                await this.EnvelopeApi.Create(this.GetEnvelopePayload());
                this.ShowToast('success', 'Sucesso', 'Operação realizada com sucesso');
            } catch (e) {
                this.ShowToast('error', 'Erro', 'Falha ao criar envelope');
            } finally {
                this.$emit('finished', true);
            }
        },
        GetEnvelopePayload() {
            return {
                title: this.envelope.title,
                description: this.envelope.description,
                documentIds: [this.document.ID],
                personIDs: [this.documentOptions.ownerId],
                envelopeTypeId: this.envelope.envelopeTypeId,
                sendChannelIds: this.envelope.sendChannels,
                expirationDate: null,
                scheduled: false
            };
        },
        GetDocumentPayload() {
            return {
                content: this.document.content,
                documentTypeId: this.document.documentTypeId,
                personIDs: [this.documentOptions.ownerId],
                referenceDate: this.document.referenceDate,
                slug: this.document.slug,
                protect: this.documentOptions.protect,
                anchor: this.document.anchor
            };
        },
        ToUpper(item) {
            return item.type.toUpperCase();
        }
    },
    watch: {
        finish: function () {
            this.UpdateDocument();
        }
    }
});
</script>

<template>
    <div class="field flex flex-column">
        <label for="documentOwner">Proprietário do documento</label>
        <Dropdown id="documentOwner" v-model="documentOptions.ownerId" optionLabel="name" optionValue="ID" placeholder="Selecione o proprietário" filter="true" :options="people.records" />
    </div>
    <div>
        <div class="field flex justify-content-start mt-1">
            <Checkbox class="mr-2" name="documentPassword" v-model="documentOptions.protect" :binary="true" :disabled="document.protected" />
            <label for="documentPassword">Proteger documento com senha</label>
        </div>
        <div class="field flex justify-content-start mt-2">
            <Checkbox class="mr-2" name="documentEnvelope" v-model="documentOptions.makeEnvelope" :binary="true" @input="envelope.showTweaks = $event" />
            <label for="documentEnvelope">Notificar</label>
        </div>
    </div>
    <div v-if="envelope.showTweaks">
        <div class="field flex flex-column">
            <label for="title">Título:</label>
            <InputText id="title" v-model.trim="envelope.title" required="true" @input="valid()" placeholder="Título do envelope" autofocus :class="{ 'p-invalid': !envelope.title }" />
            <small class="p-invalid" v-if="!envelope.title">Obrigatório</small>
        </div>
        <div class="field flex flex-column">
            <label for="description">Descrição:</label>
            <Textarea id="description" v-model="envelope.description" rows="5" cols="30" :autoResize="true" />
        </div>
        <div class="field flex flex-column">
            <label for="sendMethods">Canais de envio:</label>
            <MultiSelect id="sendMethods" v-model="envelope.selectedChannels" :options="sendChannels.records" :optionLabel="ToUpper" optionValue="ID" placeholder="Selecione" required="true" display="chip" @change="valid()" :class="{ 'p-invalid': envelope.sendChannels.length == 0 }" />
            <small class="p-invalid" v-if="envelope.sendChannels.length == 0"> Obrigatório </small>
        </div>
        <div class="field flex flex-column">
            <label for="envelopeType">Tipo de envelope</label>
            <Dropdown id="envelopeType" v-model="envelope.envelopeTypeId" optionLabel="name" optionValue="ID" placeholder="Selecione o tipo de envelope" filter="true" :options="envelopeTypes.records" />
        </div>
    </div>
</template>
