<script>
    import { defineComponent } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import AssessmentMetricOptionsRouter from '../../../api/routers/AssessmentMetricOption';
    import ProgressTemplate from '../../Utils/Progress';
    import FilterTemplate from '../../Utils/Filter.vue';

    /**
     * @module MetricOptionComponent
     * @description Vue component responsible for managing assessment metric option, including fetching, filtering,
     * creating, updating, and deleting metric options.
     */
    export default defineComponent({
        components: {
            ProgressTemplate,
            FilterTemplate
        },
        data() {
            return {
                hasFilters: false,
                pageRows: 10,
                pagination: {},
                filterModal: {
                    display: false,
                    displayFilters: ['general'],
                    selectedFilters: {}
                },
                filters: {},
                apis: {
                    assessmentMetricOptions: new AssessmentMetricOptionsRouter()
                },
                options: {
                    loading: false,
                    records: [],
                    selection: [],
                    totalRecords: 0
                },
                optionMetricModal: {
                    slotData: null,
                    display: false,
                    progress: false,
                    currentAction: 0,
                    actions: {
                        CREATE: 0,
                        UPDATE: 1
                    }
                },
                form: {
                    ID: null,
                    label: '',
                    showOrder: 0,
                    value: 0,
                    description: ''
                }
            };
        },
        created() {
            this.initializeFilters();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadMetricOptions();
        },
        methods: {
            /**
             * @function loadMetricOptions
             * @description Fetches and updates metric option data from the API.
             * @returns {Promise<void>} Resolves once the metric options have been successfully loaded.
             */
            async loadMetricOptions() {
                this.options.loading = true;
                try {
                    const jsonData = JSON.stringify(this.pagination);
                    const { assessmentMetricOptions, totalRecords } = await this.apis.assessmentMetricOptions.Search(jsonData);
                    this.options.records = assessmentMetricOptions || [];
                    this.options.totalRecords = totalRecords || 0;
                } catch (err) {
                    console.error('Error loading metric options:', err);
                } finally {
                    this.options.loading = false;
                }
            },
            /**
             * @function displayOptionMetricModal
             * @description Opens the modal for creating or updating a metric option.
             * @param {number} currentAction - Indicates whether the action is 'create' or 'update'.
             * @param {...Object} [slotData] - Optional data used to populate the form for editing.
             */
            displayOptionMetricModal(currentAction, ...slotData) {
                this.optionMetricModal.currentAction = currentAction;
                if (slotData.length > 0) {
                    this.form = { ...slotData[0] };
                }
                this.optionMetricModal.display = true;
            },
            /**
             * @function confirmDeleteOptionMetrics
             * @description Opens a confirmation dialog to delete the selected metric options.
             */
            confirmDeleteOptionMetrics() {
                if (this.options.selection.length === 0) {
                    this.$toast.add({
                        severity: 'warning',
                        summary: 'Atenção',
                        detail: 'Você precisa selecionar pelo menos 1 item.'
                    });
                    return;
                }

                this.$confirm.require({
                    message: `Você está prestes a excluir ${this.options.selection.length} registros. Deseja continuar?`,
                    header: 'Confirmar exclusão',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: () => {
                        const idsToRemove = this.options.selection.map((option) => option.ID);
                        this.deleteItems(idsToRemove);
                    }
                });
            },
            /**
             * @function deleteItems
             * @description Deletes metric options by their IDs.
             * @param {Array<string>} ids - Array of metric option IDs to delete.
             */
            async deleteItems(ids) {
                if (!ids || ids.length === 0) return;
                try {
                    await this.apis.assessmentMetricOptions.Remove(ids);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'O registro foi removido'
                    });
                } catch (err) {
                    console.error('Error deleting metric options:', err);
                } finally {
                    this.loadMetricOptions();
                }
            },
            /**
             * @function saveForm
             * @description Saves the current metric option data (create or update).
             * @returns {Promise<void>} Resolves once the save operation is completed.
             */
            async saveForm() {
                this.optionMetricModal.progress = true;
                try {
                    const { ID, payload, update } = this.getPayload();
                    const response = update
                        ? await this.apis.assessmentMetricOptions.Update(ID, payload)
                        : await this.apis.assessmentMetricOptions.Create(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Operação realizada com sucesso',
                        life: 3000
                    });

                    return response;
                } catch (err) {
                    console.error('Unable to save metric options:', err);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possível concluir a operação.',
                        life: 5000
                    });
                } finally {
                    this.optionMetricModal.display = false;
                    this.loadMetricOptions();
                }
            },
            /**
             * @function getPayload
             * @description Prepares the payload for creating or updating a metric option.
             * @returns {Object} Contains the form data and update status.
             */
            getPayload() {
                const payload = { ...this.form };
                const ID = this.form.ID;
                const update = this.optionMetricModal.currentAction === this.optionMetricModal.actions.UPDATE;
                return { ID, payload, update };
            },
            /**
             * @function onFilter
             * @description Applies selected filters and reloads the metric option data.
             */
            onFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.loadMetricOptions();
            },
            /**
             * @function onPage
             * @description Handles pagination events.
             * @param {Object} event - Event object containing pagination data.
             */
            onPage(event) {
                this.pagination = event;
                this.loadMetricOptions();
            },
            /**
             * @function initializeFilters
             * @description Sets up the initial filter and pagination configuration.
             */
            initializeFilters() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            },
            clearAllFilters() {
                this.filterModal.selectedFilters = {};
                this.initializeFilters();
                this.hasFilters = false;
                this.onFilter();
            },
            makeFilter(filter) {
                this.filterModal.selectedFilters = {
                    ...this.filterModal.selectedFilters,
                    ...filter
                };
                this.filters.global = {
                    ...this.filters.global,
                    ...filter
                };
            },
            showFilterModal() {
                this.filterModal.display = true;
            },
            filterSearch() {
                this.filterModal.display = false;
                this.onFilter();
            },
            /**
             * @function resetOptionModalState
             * @description Resets the modal state and clears the form data.
             */
            resetOptionModalState() {
                this.optionMetricModal.display = false;
                this.optionMetricModal.progress = false;
                this.form = {
                    label: '',
                    value: '',
                    showOrder: 0,
                    description: ''
                };
            }
        },
        computed: {
            /**
             * @function optionMetricModalTitle
             * @description Computes the title for the metric option modal based on the current action.
             * @returns {string} Modal title based on the action option.
             */
            optionMetricModalTitle() {
                const mainDlgTitle = 'tipo de métrica';
                if (!this.optionMetricModal.progress) {
                    return this.optionMetricModal.currentAction === this.optionMetricModal.actions.CREATE
                        ? `Criar ${mainDlgTitle}`
                        : `Editar ${mainDlgTitle}`;
                }
                return `Aguarde enquanto salvamos o ${mainDlgTitle}`;
            },
            /**
             * @function validateForm
             * @description Computes to validate form data input
             * @returns {bool} True if form data inputs is valid
             */
            validateForm() {
                return (
                    Boolean(this.form.label?.trim().length > 0) &&
                    this.form.value !== null &&
                    !isNaN(this.form.value) &&
                    this.form.showOrder !== null &&
                    !isNaN(this.form.showOrder)
                );
            }
        }
    });
</script>

<template>
    <Card>
        <!-- Title Section -->
        <template #title>
            <span class="font-light text-700 text-xl">Opções de Métricas</span>
        </template>

        <!-- Toolbar Section -->
        <template #content>
            <Toolbar class="h-6rem">
                <!-- Start Buttons (Add, Delete) -->
                <template #start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            title="Adicionar nova opção"
                            @click="displayOptionMetricModal(optionMetricModal.actions.CREATE)"
                            :disabled="loading" />
                        <Button
                            icon="fas fa-trash"
                            title="Excluir um ou mais itens"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="confirmDeleteOptionMetrics"
                            :disabled="!options.selection.length" />
                    </div>
                </template>

                <!-- End Buttons (Refresh, Filter) -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            title="Atualizar lista"
                            @click="loadMetricOptions"
                            :disabled="options.loading" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="filterModal.display = true"
                                :disabled="options.loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearAllFilters"
                                :disabled="options.loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>

        <!-- Data Table Section -->
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros"
                v-model:selection="options.selection"
                v-model:filters="filters"
                @filter="onFilter"
                @page="onPage"
                :value="options.records"
                :paginator="true"
                :loading="options.loading"
                :totalRecords="options.totalRecords"
                :rows="10"
                :rowsPerPageOptions="[10, 25, 50]">
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.40%; min-width:2rem;" />
                <Column field="label" header="Nome" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="value" header="Pontuação/Valor" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="showOrder" header="Ordem de exibição" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="description" header="Descrição" :sortable="false" headerStyle="width:10%; min-width:2rem;" />
                <Column headerStyle="width: 5%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-outlined p-button-rounded p-button-sm p-button-warning p-button-raised action-button"
                            title="Editar pessoa"
                            @click="displayOptionMetricModal(optionMetricModal.actions.UPDATE, slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog
        v-model:visible="filterModal.display"
        header="Filtro global"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false">
        <FilterTemplate
            @filterSelection="makeFilter($event)"
            :showFilters="filterModal.displayFilters"
            :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="filterSearch()" class="w-10rem" />
                <Button
                    label="Cancelar"
                    iconPos="left"
                    icon="pi pi-times"
                    autofocus
                    @click="filterModal.display = false"
                    class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="optionMetricModal.display"
        :header="optionMetricModalTitle"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="!optionMetricModal.progress"
        @hide="resetOptionModalState">
        <ProgressTemplate v-if="optionMetricModal.progress" />
        <div class="p-input-filled p-button-filled" v-if="!optionMetricModal.progress">
            <div class="flex flex-column field">
                <label for="label">Rótulo:</label>
                <InputText id="label" v-model="form.label" placeholder="Rótulo da opção (Obrigatório)" class="w-full border-transparent" />
            </div>
            <div class="flex flex-column field">
                <label for="value">Pontuação valor:</label>
                <InputNumber
                    showButtons
                    id="value"
                    v-model="form.value"
                    mode="decimal"
                    placeholder="Pontuação/valor da opção (Obrigatório)"
                    class="w-full border-transparent"
                    :min="0"
                    :max="500" />
            </div>
            <div class="flex flex-column field">
                <label for="value">Ordem de exibição:</label>
                <InputNumber
                    showButtons
                    mode="decimal"
                    id="value"
                    v-model="form.showOrder"
                    placeholder="Ordem de exibição opção (Obrigatório)"
                    class="w-full border-transparent"
                    :min="0"
                    :max="500" />
            </div>
            <div class="flex flex-column field">
                <label for="description">Descrição:</label>
                <InputText
                    id="description"
                    v-model="form.description"
                    placeholder="Descrição do tipo de métricas"
                    class="w-full border-transparent" />
            </div>
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!optionMetricModal.progress">
                <Button label="Salvar" iconPos="left" icon="fas fa-save" autofocus :disabled="!validateForm" @click="saveForm()" class="w-10rem" />
                <Button
                    autofocus
                    label="Cancelar"
                    iconPos="left"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger w-10rem"
                    @click="this.optionMetricModal.display = false" />
            </div>
        </template>
    </Dialog>
</template>
