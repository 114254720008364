<script>
import { defineComponent } from 'vue';
import AccountRouter from '@/api/routers/Account';
import PersonTypesRouter from '@/api/routers/PersonTypes';
import TOTPRouter from '@/api/routers/TOTP';
import moment from 'moment';

export default defineComponent({
    props: ['loggedInUsername', 'person'],
    data() {
        return {
            editProfile: false,
            passwordRequirementsRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#()\-/\\+<>&^~:;=%*?&])[A-Za-z\d@$!#()\-/\\+<>&^~:;=%*?&]{12,}$/,
            atLeastOneLowercase: /.*[a-z].*/,
            atLeastOneUppercase: /.*[A-Z].*/,
            atLeastOneDigit: /.*[\d].*/,
            atLeastOneSpecialChar: /.*[@$!#()\-/\\+<>&^~:;=%*?&].*/,
            minLength: /^(?=.*).{12,}$/,
            userChangePassword: {
                show: false,
                validPassword: false,
                currentPassword: '',
                newPassword: '',
                passwordConfirmation: '',
                showPassword: false,
                suggestedPassword: '',
                changeProgress: false,
                logoutAllSessions: true,
                changeProgressLabel: 'Alterando senha...'
            },
            totp: {
                show: false,
                steps: ['verifyingState', 'totpValidation', 'totpInfo'],
                currentStep: 'verifyingState',
                message: 'Obtendo informações, aguarde...',
                qrCode: null,
                lastUse: null,
                expired: false,
                expireIn: null,
                lastConfiguration: null,
                key: null,
                inputCode: null,
                invalidInput: false,
                copied: false
            },
            items: [
                {
                    label: 'Perfil',
                    icon: 'fas fa-user',
                    command: () => {
                        this.EditProfile();
                    }
                },
                {
                    label: 'Alterar senha',
                    icon: 'fas fa-key',
                    command: () => this.ViewChangePassword()
                },
                {
                    label: 'Verificação TOTP',
                    icon: 'fas fa-lock',
                    command: () => this.TOTPInfo()
                },
                {
                    label: 'Alterar tema',
                    icon: 'fas fa-adjust',
                    command: () => this.ChangeTheme()
                },
                {
                    label: 'Sair',
                    icon: 'fas fa-sign-out-alt',
                    command: () => this.Logout()
                }
            ],
            profile: {
                name: null,
                email: null,
                phone: null,
                birthDate: null,
                personTypeId: null,
                txID: null,
                action: 'update'
            },
            personTypes: {
                selectedPersonType: null,
                records: [],
                loading: false
            },
            AccountApi: null,
            PersonTypesApi: null,
            TOTPApi: null
        };
    },
    created() {
        this.AccountApi = new AccountRouter();
        this.PersonTypesApi = new PersonTypesRouter();
        this.TOTPApi = new TOTPRouter();
    },
    computed: {
        GetProfileAvatar() {
            let name = '';
            let nameAvatar = '';
            let splitChar = '';
            let splitStr = '';
            if (this.person.name?.length > 0) {
                splitChar = ' ';
                splitStr = this.person.name;
            } else {
                splitChar = '.';
                splitStr = this.loggedInUsername;
            }

            nameAvatar = splitStr?.split(splitChar);
            name = `${nameAvatar[0][0]}${nameAvatar[nameAvatar.length - 1][0]}`;

            return name.toUpperCase();
        },
        GetPersonTypeLabel() {
            let personTypes = this.personTypes.records.find((p) => p.value == this.personTypes.selectedPersonType);
            return personTypes.label;
        }
    },
    methods: {
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
        onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
        topbarImage() {
            return this.$appState.darkTheme ? 'images/logo-dark.svg' : 'images/logo.svg';
        },
        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        ChangeTheme() {
            this.$emit('change-theme');
        },
        ViewChangePassword() {
            this.userChangePassword.show = true;
        },
        GetPayload() {
            let payload = {
                name: this.profile.name,
                email: this.profile.email,
                phone: this.profile.phone?.replace(/\D/g, '')
            };
            if (this.profile.birthDate != null) {
                let date = moment(this.profile.birthDate).format('YYYY-MM-DD');
                if (date.toLowerCase() != 'invalid date') {
                    payload.birthDate = date;
                }
            }
            if (this.profile.action == 'create') {
                payload.personTypeId = this.personTypes.selectedPersonType;
                payload.txID = this.profile.txID;
            }
            return payload;
        },
        Valid() {
            let phone = this.profile.phone?.replace(/\D/g, '');
            let valid = false;
            const regexPattern = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');
            this.profile.name?.length > 4 && phone?.length == 11 && regexPattern.test(this.profile.email) ? (valid = true) : (valid = false);

            if (this.profile.action == 'create') {
                this.personTypes.selectedPersonType != null && this.profile.txID?.length > 0 ? (valid = true) : (valid = false);
            }
            return valid;
        },
        async SaveProfile() {
            if (this.profile.action == 'create') {
                this.AccountApi.Create(this.GetPayload()).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Informações processadas com sucesso, as alterações terão efeito no proximo login',
                        life: 3000
                    });
                    this.editProfile = false;
                });
            } else {
                this.AccountApi.Update(this.GetPayload()).then(() => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Informações processadas com sucesso, as alterações terão efeito no proximo login',
                        life: 3000
                    });
                    this.editProfile = false;
                });
            }
        },
        EditProfile() {
            this.profile = { ...this.person };

            if (Object.keys(this.person).length == 0) {
                this.profile.action = 'create';
                this.LoadPersonTypes();
            }
            if (this.profile?.birthDate?.length) {
                this.profile.birthDate = moment(this.profile.birthDate).format('DD/MM/YYYY');
            }
            this.editProfile = true;
        },
        TOTPResetState() {
            this.totp = {
                show: false,
                steps: ['verifyingState', 'totpValidation', 'totpInfo'],
                currentStep: 'verifyingState',
                message: 'Obtendo informações, aguarde...',
                qrCode: null,
                lastUse: null,
                lastConfiguration: null,
                key: null,
                inputCode: null,
                invalidInput: false,
                copied: false,
                expired: false,
                expireIn: null
            };
        },
        async TOTPInfo() {
            this.totp.message = 'Obtendo informações, aguarde...';
            this.totp.show = true;
            setTimeout(async () => {
                try {
                    const resp = await this.TOTPApi.Enabled();

                    const { totpEnabled } = resp;

                    if (totpEnabled) {
                        const { totpLastUse, totpLastConfiguration, totpExpired, totpExpireIn } = resp;
                        this.totp.lastUse = moment(totpLastUse).format('DD/MM/YYYY  HH:mm:ss');
                        this.totp.lastConfiguration = moment(totpLastConfiguration).format('DD/MM/YYYY  HH:mm:ss');
                        this.totp.expired = totpExpired;
                        this.totp.expireIn = moment(totpExpireIn).format('DD/MM/YYYY  HH:mm:ss');
                        this.totp.currentStep = this.totp.steps[2];
                    } else {
                        this.TOTPConfigure(false);
                    }
                } catch (err) {
                    console.error(err)
                    this.totp.show = false;
                }
            }, 1500);
        },
        async TOTPConfigure(confirm) {
            if (confirm) {
                this.$confirm.require({
                    message: 'Será necessário configurar um nova chave secreta, continuar?',
                    header: 'Renovar chave de segurança',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: () => {
                        this.TOTPConfigurator();
                    }
                });
            } else {
                this.TOTPConfigurator();
            }
        },
        async TOTPConfigurator() {
            try {
                const resp = await this.TOTPApi.Configure();
                const { qrCode, key } = resp?.totpRegistration;

                this.totp.qrCode = `data:image/png;base64,${qrCode}`;
                this.totp.key = key;
                this.totp.currentStep = this.totp.steps[1];
            } catch {
                this.totp.show = false;
            }
        },
        async TOTPKeyCopy() {
            try {
                await navigator.clipboard.writeText(this.totp.key);
                this.totp.copied = true;
                setTimeout(() => {
                    this.totp.copied = false;
                }, 5000);

                this.$toast.add({
                    severity: 'success',
                    summary: 'Sucesso',
                    detail: 'Código copiado com sucesso, cole no app Google Authenticator',
                    life: 5000
                });
            } catch {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: 'Ocorreu um erro ao copiar o token',
                    life: 5000
                });
            }
        },
        ChangePassword() {
            this.ValidateChangePassword();
            if (!this.userChangePassword.validPassword) {
                this.showWarningToast('Por favor verifique os campos', 'Um ou mais campos foram preenchidos incorretamente, por favor verifique');
                return;
            }

            this.userChangePassword.changeProgress = true;
            setTimeout(async () => {
                try {
                    let payload = {
                        oldPassword: this.userChangePassword.currentPassword,
                        password: this.userChangePassword.passwordConfirmation,
                        logoutAllSessions: this.userChangePassword.logoutAllSessions
                    };

                    await this.AccountApi.ChangePassword(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Sua senha foi alterada',
                        life: 5000
                    });

                    if (this.userChangePassword.logoutAllSessions) {
                        this.userChangePassword.changeProgressLabel = 'Desconectando...';
                        setTimeout(() => {
                            this.$emit('user-logout');
                        }, 2000);
                    } else {
                        this.userChangePassword.changeProgress = false;
                        this.userChangePassword = {
                            show: false,
                            validPassword: false,
                            currentPassword: '',
                            newPassword: '',
                            passwordConfirmation: '',
                            showPassword: false,
                            suggestedPassword: '',
                            changeProgress: false,
                            logoutAllSessions: true,
                            changeProgressLabel: 'Alterando senha...'
                        };
                    }
                } catch (error) {
                    if (error?.response?.status == 422) {
                        this.showWarningToast('Sua atual esta incorreta ou a nova senha não atende aos requisitos mínimos, por favor verifique e tente novamente');
                    } else {
                        this.showErrorToast('Ocorreu um erro ao alterar a sua senha, consulte o log para mais informações');
                    }
                    this.userChangePassword.changeProgress = false;
                }
            }, 1500);
        },
        showWarningToast(summary, detail) {
            this.$toast.add({
                severity: 'warn',
                summary: summary,
                detail: detail,
                life: 5000
            });
        },
        showErrorToast(detail) {
            this.$toast.add({
                severity: 'error',
                summary: 'Erro',
                detail: detail,
                life: 5000
            });
        },
        ValidateChangePassword() {
            let valid = this.IsValidPassword(this.userChangePassword.newPassword) && this.userChangePassword.currentPassword?.length > 0 && this.userChangePassword.newPassword == this.userChangePassword.passwordConfirmation;
            valid ? (this.userChangePassword.validPassword = true) : (this.userChangePassword.validPassword = false);
        },
        async TOTPValidate() {
            if (this.totp.inputCode?.length == 0 || this.totp.inputCode === null) {
                this.totp.invalidInput = true;
                return;
            }

            this.totp.message = 'Ativando verificação TOTP...';
            this.totp.currentStep = this.totp.steps[0];

            const payload = {
                code: this.totp.inputCode
            };

            const resp = await this.TOTPApi.Validate(payload);

            setTimeout(async () => {
                if (resp.totpValidated) {
                    this.totp.show = false;
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Verificação TOTP ativada com sucesso',
                        life: 5000
                    });
                } else {
                    this.totp.inputCode = '';
                    this.totp.invalidInput = true;
                    this.totp.currentStep = this.totp.steps[1];
                }
            }, 1500);
        },
        GeneratePassword() {
            const length = 12;
            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+';
            let password = '';

            while (!this.IsValidPassword(password)) {
                password = '';
                for (let i = 0; i < length; ++i) {
                    const randomIndex = Math.floor(Math.random() * charset.length);
                    password += charset[randomIndex];
                }
            }

            this.userChangePassword.suggestedPassword = password;
            this.userChangePassword.newPassword = password;

            this.ValidateChangePassword();
        },
        IsValidPassword(password) {
            return this.passwordRequirementsRegex.test(password);
        },
        async LoadPersonTypes() {
            this.personTypes.loading = true;
            this.personTypes.records = await this.PersonTypesApi.FindAll()
                .then((resp) => {
                    return resp?.personTypes.map((p) => {
                        return {
                            label: p.name,
                            value: p.ID
                        };
                    });
                })
                .finally((this.personTypes.loading = false));
        },
        Logout() {
            this.$confirm.require({
                message: 'Tem certeza que deseja sair?',
                header: 'Sair',
                icon: 'far fa-question-circle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: () => {
                    this.$emit('user-logout');
                }
            });
        }
    }
});
</script>
<template>
    <ConfirmDialog :breakpoints="{ '960px': '75vw', '640px': '100vw' }" :style="{ width: '30vw' }"></ConfirmDialog>
    <Dialog v-model:visible="totp.show" modal :header="totp.currentStep !== totp.steps[2] ? 'Verificação TOTP' : 'Informações TOTP'" :style="{ width: '45rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" @hide="TOTPResetState()">
        <div class="flex flex-column col-12 w-auto" style="gap: 1rem">
            <div class="flex flex-column justify-content-between align-items-center" v-if="totp.currentStep === totp.steps[0]">
                <span class="text-md w-full mb-3">{{ totp.message }}</span>
                <ProgressBar mode="indeterminate" style="height: 3px" class="w-full text-red-500" />
            </div>

            <div class="flex flex-column w-full align-items-center" v-if="totp.currentStep === totp.steps[1]">
                <label class="font-bold text-md w-full mb-2">Passo 1 - Baixe o aplicativo</label>
                <label class="font-medium text-sm w-full mb-2 text-justify">Visite a loja de aplicativos em seu dispositivo móvel e busque por 'Google Authenticator'. Alternativamente, clique em uma das opções abaixo, de acordo com o sistema operacional do seu dispositivo.</label>
                <div class="flex flex-row justify-content-center w-full" style="gap: 1rem">
                    <div class="p-fluid align-items-center">
                        <a href="https://apps.apple.com/br/app/google-authenticator/id388497605" target="_blank">
                            <img style="width: 120px" src="images/app-store.webp" />
                        </a>
                    </div>
                    <div class="p-fluid align-items-center">
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">
                            <img style="width: 120px" src="images/google-play.webp" />
                        </a>
                    </div>
                </div>

                <Divider />
                <label class="font-bold text-md w-full mb-2">Passo 2 - Escaneie ou copie o código de verificação TOTP</label>
                <label class="font-medium text-sm w-full mb-3 text-justify">
                    Abra o Google Authenticator, clique no ícone "<i class="fas fa-plus" />" e escolha a opção "Ler código QR". Escaneie o código QR fornecido. Caso não consiga escanear o código, é possível copiá-lo e inseri-lo manualmente no Google Authenticator. Selecione a opção "Inserir chave de
                    configuração" e escolha o tipo de chave baseada no horário.
                </label>
                <div class="flex flex-column">
                    <div class="flex card p-1 shadow-8 border-round-xs">
                        <img style="width: 190px; height: 200px" :src="totp.qrCode" />
                    </div>
                    <Button class="p-button-info p-button-raised" icon="fas fa-copy" :disabled="totp.copied" :label="totp.copied ? 'Copiado' : 'Copiar código QR'" title="Copiar chave secreta para inserção manual" @click="TOTPKeyCopy()" />
                </div>

                <Divider />
                <label class="font-bold text-md w-full mb-2">Passo 3 - Insira a chave de verificação</label>
                <label class="font-medium text-sm w-full mb-1 text-justify">Após a conclusão do passo 2, será gerada no app "Google Authenticator" uma chave de 6 dígitos.</label>
                <label class="font-normal text-sm w-full text-justify">Insira a chave abaixo para concluir a validação:</label>
                <InputMask id="otp_code" class="p-inputtext-lg mt-1 w-full" :class="{ 'p-invalid': totp.invalidInput }" v-model="totp.inputCode" placeholder="Chave de verificação" @input="this.totp.invalidInput = false" unmask="true" mask="999-999" />
                <small class="p-error mt-1" v-if="totp.invalidInput">Chave inválida</small>
            </div>

            <div class="flex flex-wrap w-full text-center justify-content-around" v-if="totp.currentStep === totp.steps[2]">
                <div class="flex flex-column text-center">
                    <label class="font-bold text-md mb-2">Último uso</label>
                    <label class="font-normal text-sm">{{ totp.lastUse }}</label>
                </div>
                <div class="flex flex-column text-center">
                    <label class="font-bold text-md mb-2">Última renovação</label>
                    <label class="font-normal text-sm">{{ totp.lastConfiguration }}</label>
                </div>
                <div class="flex flex-column text-center">
                    <label class="font-bold text-md mb-2">Expira em</label>
                    <label class="font-normal text-sm">{{ totp.expireIn }}</label>
                </div>
                <div class="flex flex-column text-center">
                    <label class="font-bold text-md mb-2">Status</label>
                    <label class="font-normal text-sm">{{ totp.expired ? 'Expirado' : 'Validado' }}</label>
                </div>
            </div>
        </div>
        <template #footer>
            <Button class="p-button-success p-button-raised w-full" label="Concluir validação" title="Finalizar" @click="TOTPValidate()" v-if="totp.currentStep === totp.steps[1]" />
            <Button class="p-button-danger p-button-raised w-full" label="Revogar chave secreta" @click="TOTPConfigure(true)" v-if="totp.currentStep === totp.steps[2]" />
        </template>
    </Dialog>

    <Dialog v-model:visible="userChangePassword.show" modal header="Alterar minha senha" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="flex flex-column p-fluid col-12 w-full" style="gap: 1rem">
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="current_password">Senha atual:</label>
                <Password id="current_password" class="p-inputtext-lg mt-1" v-model="userChangePassword.currentPassword" :feedback="false" placeholder="Insira sua senha atual" @input="ValidateChangePassword" toggleMask />
            </div>
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="new_password">Nova senha:</label>
                <Password id="new_password" class="p-inputtext-lg mt-1" v-model="userChangePassword.newPassword" :feedback="true" promptLabel=" " :strongRegex="passwordRequirementsRegex" placeholder="Insira a nova senha" @input="ValidateChangePassword" toggleMask>
                    <template #header>
                        <h6>Siga os padrões abaixo para criar uma senha segura</h6>
                    </template>
                    <template #footer>
                        <Divider />
                        <p class="mt-2">Requisitos de senha</p>
                        <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                            <li :class="{ 'text-green-600': atLeastOneLowercase.test(userChangePassword.newPassword), 'text-500': !atLeastOneLowercase.test(userChangePassword.newPassword) }">Pelo menos uma letra minúscula</li>
                            <li :class="{ 'text-green-600': atLeastOneUppercase.test(userChangePassword.newPassword), 'text-500': !atLeastOneUppercase.test(userChangePassword.newPassword) }">Pelo menos uma letra maiúscula</li>
                            <li :class="{ 'text-green-600': atLeastOneSpecialChar.test(userChangePassword.newPassword), 'text-500': !atLeastOneSpecialChar.test(userChangePassword.newPassword) }">Pelo menos um caractere especial</li>
                            <li :class="{ 'text-green-600': atLeastOneDigit.test(userChangePassword.newPassword), 'text-500': !atLeastOneDigit.test(userChangePassword.newPassword) }">Pelo menos um numérico</li>
                            <li :class="{ 'text-green-600': minLength.test(userChangePassword.newPassword), 'text-500': !minLength.test(userChangePassword.newPassword) }">Ao menos 12 caracteres</li>
                        </ul>
                        <h6 class="text-orange-500">Não é possível reutilizar senhas cadastradas anteriormente</h6>
                    </template>
                </Password>
            </div>
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="confirm_new_password">Confirme a nova senha:</label>
                <Password id="confirm_new_password" class="p-inputtext-lg mt-1" v-model="userChangePassword.passwordConfirmation" :feedback="false" placeholder="Reinsira a nova senha" @input="ValidateChangePassword" toggleMask />
            </div>
            <div class="flex flex-row" style="gap: 5px">
                <Checkbox id="logout_sessions" v-model="this.userChangePassword.logoutAllSessions" :binary="true" />
                <label class="text-lg" for="logout_sessions">Desconectar todas as sessões ativas</label>
            </div>
        </div>
        <template #footer>
            <div class="flex flex-column justify-content-start align-items-start pl-2 pr-2 mb-3" v-if="userChangePassword.changeProgress">
                <span class="text-md text-start mb-1">{{ userChangePassword.changeProgressLabel }}</span>
                <ProgressBar mode="indeterminate" style="height: 3px" class="w-full text-info" />
            </div>
            <div class="flex justify-content-between" v-if="!userChangePassword.changeProgress">
                <Button icon="fas fa-asterisk" label="Sugerir senha" class="p-button-info p-button-raised p-button-sm ml-2" @click="GeneratePassword()" :disabled="userChangePassword.changeProgress" />
                <Button icon="fas fa-key" label="Alterar senha" class="p-button-success p-button-raised p-button-sm" @click="ChangePassword" title="Prosseguir" :disabled="!userChangePassword.validPassword || userChangePassword.changeProgress" />
            </div>
        </template>
    </Dialog>

    <Dialog v-model:visible="editProfile" modal header="Meu perfil" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="flex flex-column p-fluid col-12 w-full" style="gap: 1rem">
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="name">Nome:</label>
                <InputText id="name" class="p-inputtext-lg mt-1" v-model="profile.name" placeholder="Seu nome" @input="Valid()" />
            </div>
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="email">E-mail:</label>
                <InputText id="email" class="p-inputtext-lg mt-1" v-model="profile.email" placeholder="Seu email" @input="Valid()" />
            </div>
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="phone">Telefone:</label>
                <InputMask id="phone" class="p-inputtext-lg mt-1" mask="(99) 99999-9999" v-model="profile.phone" placeholder="Seu telefone" @input="Valid()" />
            </div>
            <div v-if="profile.action == 'create'">
                <div class="flex flex-column mb-2">
                    <label class="text-lg" for="phone">Tipo de documento:</label>
                    <Dropdown
                        id="personTypes"
                        v-model="personTypes.selectedPersonType"
                        optionLabel="label"
                        optionValue="value"
                        placeholder="Selecione um tipo de pessoa"
                        emptyFilterMessage="Sem resultados"
                        emptyMessage="Sem resultados"
                        class="p-inputtext-lg mt-1"
                        :filter="true"
                        :options="personTypes.records"
                        :loading="personTypes.loading"
                    />
                </div>
                <div class="flex flex-column mb-2" style="gap: 0.5rem" v-if="personTypes.selectedPersonType != null">
                    <label class="text-lg" for="txID">{{ GetPersonTypeLabel + ':' }}</label>
                    <InputText id="txID" class="p-inputtext-lg mt-1" v-model="profile.txID" :placeholder="'Seu ' + GetPersonTypeLabel" @input="Valid()" />
                </div>
            </div>
            <div class="flex flex-column mb-2">
                <label class="text-lg" for="birthDate">Data de nascimento:</label>
                <Calendar id="birthDate" inputId="single" v-model="profile.birthDate" selectionMode="single" dateFormat="dd/mm/yy" class="p-inputtext-lg mt-1" :showButtonBar="true" :showTime="false" :manualInput="true" :showIcon="true" />
            </div>
        </div>
        <template #footer>
            <div class="flex justify-content-center">
                <Button icon="fas fa-save" label="Salvar alterações" class="p-button-success p-button-raised" @click="SaveProfile" title="Concluir alterações" :disabled="!Valid()" />
            </div>
        </template>
    </Dialog>

    <div class="layout-topbar">
        <router-link to="/" class="layout-topbar-logo">
            <img alt="Logo" :src="topbarImage()" />
        </router-link>
        <Button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
            <i class="pi pi-bars"></i>
        </Button>

        <Button
            class="p-link layout-topbar-menu-button layout-topbar-button"
            v-styleclass="{
                selector: '@next',
                enterClass: 'hidden',
                enterActiveClass: 'scalein',
                leaveToClass: 'hidden',
                leaveActiveClass: 'fadeout',
                hideOnOutsideClick: true
            }"
        >
            <i class="pi pi-ellipsis-v"></i>
        </Button>
        <ul class="layout-topbar-menu hidden lg:flex origin-top">
            <li>
                <Button type="button" @click="toggleMenu" class="p-button-info p-button-md p-button-outlined p-button-rounded p-button-raised" aria-haspopup="true" aria-controls="overlay_menu">
                    <div class="flex flex-row" style="gap: 0.5rem">
                        <i class="fa-solid fa-user fa-lg" />
                        <label class="font-bold">{{ loggedInUsername }}</label>
                    </div>
                </Button>
            </li>
        </ul>
    </div>

    <Menu ref="menu" :model="items" :popup="true" />

    <!-- <OverlayPanel ref="op" appendTo="body" :showCloseIcon="false" id="overlay_panel" style="width: 500px" :breakpoints="{ '960px': '75vw' }" @hide="editProfile = false">
        <Card class="col-12" style="box-shadow: none">
            <template #title>
                <div class="flex flex-column align-items-center" style="gap: 0.5rem">
                    <Avatar v-if="GetProfileAvatar?.length" :label="GetProfileAvatar" size="xlarge" style="background-color: #2196f3; color: #ffffff" />
                    <span class="font-bold text-900 text-xxl ml-2">{{ person.name?.length > 0 ? person.name : loggedInUsername }}</span>
                </div>
            </template>
            <template #content>
                <div class="flex flex-column p-fluid col-12 w-full" style="gap: 1rem" v-if="editProfile">
                    <ScrollPanel style="width: 100%; height: 300px" class="scroll">
                        <div class="flex flex-column mb-2">
                            <label class="text-lg" for="name">Nome:</label>
                            <InputText id="name" class="p-inputtext-lg mt-1" v-model="profile.name" placeholder="Seu nome" @input="Valid()" />
                        </div>
                        <div class="flex flex-column mb-2">
                            <label class="text-lg" for="email">E-mail:</label>
                            <InputText id="email" class="p-inputtext-lg mt-1" v-model="profile.email" placeholder="Seu email" @input="Valid()" />
                        </div>
                        <div class="flex flex-column mb-2">
                            <label class="text-lg" for="phone">Telefone:</label>
                            <InputMask id="phone" class="p-inputtext-lg mt-1" mask="(99) 99999-9999" v-model="profile.phone" placeholder="Seu telefone" @input="Valid()" />
                        </div>
                        <div v-if="profile.action == 'create'">
                            <div class="flex flex-column mb-2">
                                <label class="text-lg" for="phone">Tipo de documento:</label>
                                <Dropdown
                                    id="personTypes"
                                    v-model="personTypes.selectedPersonType"
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="Selecione um tipo de pessoa"
                                    emptyFilterMessage="Sem resultados"
                                    emptyMessage="Sem resultados"
                                    class="p-inputtext-lg mt-1"
                                    :filter="true"
                                    :options="personTypes.records"
                                    :loading="personTypes.loading"
                                />
                            </div>
                            <div class="flex flex-column mb-2" style="gap: 0.5rem" v-if="personTypes.selectedPersonType != null">
                                <label class="text-lg" for="txID">{{ GetPersonTypeLabel + ':' }}</label>
                                <InputText id="txID" class="p-inputtext-lg mt-1" v-model="profile.txID" :placeholder="'Seu ' + GetPersonTypeLabel" @input="Valid()" />
                            </div>
                        </div>
                        <div class="flex flex-column mb-2">
                            <label class="text-lg" for="birthDate">Data de nascimento:</label>
                            <Calendar id="birthDate" inputId="single" v-model="profile.birthDate" selectionMode="single" dateFormat="dd/mm/yy" class="p-inputtext-lg mt-1" :showButtonBar="true" :showTime="false" :manualInput="true" :showIcon="true" />
                        </div>
                    </ScrollPanel>
                </div>
            </template>
            <template #footer>
                <div class="flex justify-content-center md:flex flex-wrap" style="gap: 5rem" v-if="!editProfile">
                    <Button icon="fas fa-pencil" class="p-button-rounded p-button-warning p-button-raised" title="Atualizar minhas informações" @click="EditProfile" />
                    <Button icon="fas fa-user-lock" class="p-button-rounded p-button-success p-button-raised" title="Ativar/Verificar validação rápida" @click="TOTPView" />
                    <Button icon="fas fa-adjust" class="p-button-rounded p-button-info p-button-raised" title="Alterar tema" @click="ChangeTheme" />
                    <Button icon="fas fa-sign-out-alt" class="p-button-rounded p-button-danger p-button-raised" title="Sair" @click="Logout" />
                </div>
                <div class="flex justify-content-center" style="gap: 5rem" v-if="editProfile">
                    <Button icon="fas fa-times" class="p-button-rounded p-button-danger p-button-raised" @click="editProfile = false" title="Cancelar alterações" />
                    <Button icon="fas fa-check" class="p-button-rounded p-button-success p-button-raised" @click="SaveProfile" title="Concluir alterações" :disabled="!Valid()" />
                </div>
            </template>
        </Card>
    </OverlayPanel> -->
</template>

<style scoped>
.scroll .p-scrollpanel-wrapper {
    border-right: 9px solid #f4f4f4;
}

.scroll .p-scrollpanel-bar {
    background-color: #1976d2;
    opacity: 1;
    transition: background-color 0.3s;
}

.scroll .p-scrollpanel-bar:hover {
    background-color: #135ba1;
}
</style>
