<script>
import { defineComponent } from 'vue';
import AppEventBus from '@/layout/composables/AppEventBus';

import AuthRouter from '@/api/routers/Auth';

export default defineComponent({
    name: 'ForgotPassword',
    inject: ['AxiosInstance'],
    data() {
        return {
            loading: false,
            user: {
                txID: null
            },
            recoverMethods: {
                options: [
                    {
                        label: 'E-mail',
                        value: 'email'
                    },
                    {
                        label: 'SMS',
                        value: 'sms'
                    }
                ],
                selectedOption: null
            },
            AuthApi: null
        };
    },
    created() {
        this.AuthApi = new AuthRouter();
        if (this.loggedIn) {
            this.$router.push('/');
        }
    },
    mounted() {
        this.$emitter.on('error', this.EmitterError);
        this.SetLoginTheme();
    },
    unmounted() {
        this.$emitter.off('error', this.EmitterError);
    },
    computed: {
        logoUrl() {
            return `images/logo.svg`;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    methods: {
        SetLoginTheme() {
            AppEventBus.emit('theme-change', {
                theme: 'lara-light-blue',
                dark: false
            });
        },
        EmitterError(event) {
            this.$toast.add(event);
        },
        ForgotPassword() {
            this.loading = true;
            setTimeout(async () => {
                this.loading = true;
                try {
                    let payload = {
                        txID: this.user.txID,
                        method: this.recoverMethods.selectedOption
                    };

                    await this.AuthApi.Forgot(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: `Em breve você receberá um ${this.recoverMethods.selectedOption} com as instruções para redefinir sua senha`,
                        life: 5000
                    });

                    setTimeout(() => {
                        this.$router.push('/auth/login');
                    }, 1000);
                } catch (error) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Ocorreu um erro ao tentar recuperar a senha. Tente novamente mais tarde.',
                        life: 5000
                    });
                } finally {
                    this.loading = false;
                }
            }, 1500);
        }
    }
});
</script>

<template>
    <div class="no-scroll-container">
        <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background px-3">
            <div class="flex flex-column card py-6 px-4 shadow-3 justify-content-between responsive">
                <div class="surface-card flex flex-column px-2 justify-content-between">
                    <div class="flex flex-column justify-content-between mb-2">
                        <div class="text-left">
                            <img :src="logoUrl" alt="logo" height="40" />
                        </div>
                        <label class="font-medium text-bluegray-700 text-sm mt-1">Gestão de pessoas | Recuperar senha</label>
                    </div>
                    <Divider />
                    <div>
                        <!-- form-recover-password -->
                        <div>
                            <div class="mb-3">
                                <div class="flex flex-column p-fluid w-full" style="gap: 5px">
                                    <label for="txID" class="block text-700">Informe seu CPF:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-id-card" />
                                        <InputMask id="txID" mask="999.999.999-99" class="w-full p-inputtext-md h-3rem" autocomplete="off" placeholder="Insira seu CPF" v-model="user.txID" :disabled="loading" />
                                    </span>
                                </div>
                            </div>
                            <div class="mb-3">
                                <div class="flex flex-column p-fluid w-full" style="gap: 5px">
                                    <label for="recover_method" class="block text-700">Método de envio:</label>
                                    <span class="p-input-icon-left w-full">
                                        <i class="pi pi-id-card" />
                                        <Dropdown id="recover_method" v-model="recoverMethods.selectedOption" optionLabel="label" optionValue="value" placeholder="Escolha um" :options="recoverMethods.options" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- -->

                        <div class="flex flex-row" v-if="!loading">
                            <Button label="Solicitar recuperação de senha" type="submit" class="w-full p-3 w-full text-base p-button-raised p-button-outlined p-button-rounded" @click="ForgotPassword()"></Button>
                        </div>
                        <div class="font-medium text-lg text-bluegray-800 mt-5" v-else>
                            <div class="flex flex-row justify-content-center text-center" style="gap: 5px">
                                <i class="pi pi-spin pi-spinner text-2xl" />
                                <span>Enviando solicitação...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer justify-content-center align-items-center">
            <span class="text-lg text-900 font-light mb-1">Copyright © 2024 | IR Tecnologia - Todos os direitos reservados</span>
            <span class="text-sm text-900 font-light mb-2">(S.O.G.E) - Sistemas Operacionais, Gerências e Estratégicos</span>
        </div>

        <Toast position="bottom-right" />
    </div>
</template>

<style scoped>
/* default */
.responsive {
    width: 32rem;
    border-radius: 20px !important;
}

/* mobile small */
@media screen and (max-width: 320px) {
    .responsive {
        width: 20rem;
    }
}

/* mobile medium */
@media screen and (max-width: 480px) {
    .responsive {
        width: 30rem;
    }
}

.no-scroll-container {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: transparent;
    text-align: center;
    padding: 10px 0;
}

.footer span {
    display: block;
    text-align: center;
}
</style>
