import AuthRouter from '../api/routers/Auth';
import Token from './Token';

const AuthApi = new AuthRouter();

/**
 * @class Auth
 * Handles user authentication operations such as login, logout, and token refresh.
 */
class Auth {
    /**
     * Logs in a user by sending credentials to the API and storing the access/refresh tokens.
     * 
     * @async
     * @function login
     * @param {Object} user - User credentials.
     * @param {string} user.username - The username of the user.
     * @param {string} user.password - The password of the user.
     * @throws Will throw an error if the login fails.
     */
    async login(user) {
        const payload = { username: user.username, password: user.password };
        try {
            const response = await AuthApi.Login(payload);
            const { accessToken, refreshToken } = response?.data.login || {};

            if (accessToken) {
                Token.setAuthorizationToken(accessToken);
            }

            if (refreshToken) {
                Token.setRefreshToken(refreshToken);
            }

        } catch (error) {
            throw new Error('Failed to log in. Please check your credentials.');
        }
    }

    /**
     * Logs out the user by calling the API and clearing tokens from local storage.
     * 
     * @async
     * @function logout
     * @returns {Promise<void>}
     */
    async logout() {
        try {
            await AuthApi.Logout();
        } catch (error) {
            console.error('Logout failed:', error.message);
        } finally {
            Token.clearLocalStorage();
        }
    }

    /**
     * Refreshes the access token using the stored refresh token.
     * 
     * @async
     * @function refresh
     * @returns {Promise<void>}
     */
    async refresh() {
        const refreshTkn = Token.getRefreshToken();
        if (!refreshTkn) {
            console.warn('No refresh token found.');
            return;
        }

        const payload = { refreshToken: refreshTkn };
        try {
            const response = await AuthApi.Refresh(payload);
            const { accessToken } = response?.data.refresh || {};

            if (accessToken) {
                Token.setAuthorizationToken(accessToken);
            } else {
                console.error('No access token returned in the refresh response.');
            }

        } catch (error) {
            console.error('Token refresh failed:', error.message);
        }
    }
}

export default new Auth();
