<script>
    import { defineComponent } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import AssessmentMetricRouter from '../../../api/routers/AssessmentMetric';
    import AssessmentMetricTypeRouter from '../../../api/routers/AssessmentMetricType';
    import AssessmentMetricGroupRouter from '../../../api/routers/AssessmentMetricGroup';
    import AssessmentMetricOptionsRouter from '../../../api/routers/AssessmentMetricOption';
    import ProgressTemplate from '../../Utils/Progress';
    import FilterTemplate from '../../Utils/Filter';
    import InputOptions from '../../Utils/InputOptions';

    /**
     * @module AssessmentMetricTemplateComponent
     * @description Vue component responsible for managing assessment metric templates, including fetching, filtering,
     * creating, updating, and deleting assessment metric templates.
     */
    export default defineComponent({
        components: {
            ProgressTemplate,
            InputOptions,
            FilterTemplate
        },
        data() {
            return {
                hasFilters: false,
                pageRows: 10,
                pagination: {},
                filters: {},
                filterModal: {
                    display: false,
                    displayFilters: ['general'],
                    selectedFilters: {}
                },
                apis: {
                    assessmentMetricTemplates: new AssessmentMetricRouter(),
                    assessmentMetricType: new AssessmentMetricTypeRouter(),
                    assessmentMetricGroup: new AssessmentMetricGroupRouter(),
                    assessmentMetricOptions: new AssessmentMetricOptionsRouter()
                },
                templates: {
                    loading: false,
                    records: [],
                    selection: [],
                    totalRecords: 0
                },
                templateMetricModal: {
                    slotData: null,
                    display: false,
                    progress: false,
                    currentAction: 0,
                    actions: {
                        CREATE: 0,
                        UPDATE: 1
                    },
                    assessmentMetricTypeCodes: {
                        QUANTITATIVE: 'quantitative',
                        QUALITATIVE: 'qualitative'
                    }
                },
                attributesModal: {
                    display: false
                },
                form: this.initForm()
            };
        },
        created() {
            this.initializeFilters();
        },
        async mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadAssessmentMetricTemplates();
        },
        methods: {
            initForm() {
                return {
                    assessmentMetricType: {},
                    assessmentMetricGroup: {},
                    assessmentMetricOptions: null,
                    settings: this.formSettingsState(),
                    title: '',
                    description: '',
                    weight: 0,
                    estimatedValue: 0.0,
                    active: true
                };
            },
            /**
             * @function loadAssessmentMetricTemplates
             * @description Fetches and updates metric template data from the API.
             * @returns {Promise<void>} Resolves once the metric templates have been successfully loaded.
             */
            async loadAssessmentMetricTemplates() {
                this.templates.loading = true;
                try {
                    const jsonData = JSON.stringify(this.pagination);
                    const { assessmentMetrics, totalRecords } = await this.apis.assessmentMetricTemplates.Search(jsonData);
                    this.templates.records = assessmentMetrics || [];
                    this.templates.totalRecords = totalRecords || 0;
                } catch (err) {
                    console.error('Error loading metric templates:', err);
                } finally {
                    this.templates.loading = false;
                }
            },
            /**
             * @function displayTemplateMetricModal
             * @description Opens the modal for creating or updating a metric template.
             * @param {number} currentAction - Indicates whether the action is 'create' or 'update'.
             * @param {Object} [slotData = null] - Optional data used to populate the form for editing.
             */
            displayTemplateMetricModal(currentAction, slotData = null) {
                this.templateMetricModal.currentAction = currentAction;

                this.form = this.initForm();

                if (currentAction === this.templateMetricModal.actions.UPDATE && slotData !== null) {
                    this.form = { ...slotData };
                }

                this.form['settings'] = this.form.settings ?? this.formSettingsState();
                this.form['assessmentMetricOptions'] = this.form.assessmentMetricOptions ?? null;

                this.templateMetricModal.display = true;
            },
            formSettingsState() {
                return {
                    minimalIsStrict: false,
                    maximumIsStrict: false,
                    min: null,
                    max: null,
                    required: false
                };
            },
            /**
             * @function confirmDeleteTemplateMetrics
             * @description Opens a confirmation dialog to delete the selected metric templates.
             */
            confirmDeleteTemplateMetrics() {
                if (this.templates.selection.length === 0) {
                    this.$toast.add({
                        severity: 'warning',
                        summary: 'Atenção',
                        detail: 'Você precisa selecionar ao menos 1 item para remoção'
                    });
                    return;
                }

                this.$confirm.require({
                    message: `Você está prestes a excluir ${this.templates.selection.length} registros. Deseja continuar?`,
                    header: 'Confirmar exclusão',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: async () => {
                        const idsToRemove = this.templates.selection.map((template) => template.ID);
                        this.deleteItems(idsToRemove);
                    }
                });
            },
            /**
             * @function deleteItems
             * @description Deletes metric templates by their IDs.
             * @param {Array<number>} ids - Array of metric template IDs to delete.
             */
            async deleteItems(ids) {
                if (!ids || ids.length === 0) return;
                try {
                    await this.apis.assessmentMetricTemplates.Remove(ids);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Item(s) removido(s) com sucesso'
                    });
                } catch (err) {
                    console.error('Error deleting metric templates:', err);
                } finally {
                    this.loadAssessmentMetricTemplates();
                }
            },
            /**
             * @function saveForm
             * @description Saves the current metric template data (create or update).
             * @returns {Promise<void>} Resolves once the save operation is completed.
             */
            async saveForm() {
                this.templateMetricModal.progress = true;
                try {
                    const { ID, payload, update } = this.getPayload();
                    const response = update ? await this.apis.assessmentMetricTemplates.UpdateTemplate(ID, payload) : await this.apis.assessmentMetricTemplates.CreateTemplate(payload);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Operação realizada com sucesso',
                        life: 3000
                    });

                    return Promise.resolve(response);
                } catch (err) {
                    console.error('Unable to save metric templates:', err);
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Não foi possível concluir a operação.',
                        life: 5000
                    });
                    return Promise.reject(err);
                } finally {
                    this.templateMetricModal.display = false;
                    this.loadAssessmentMetricTemplates();
                }
            },
            /**
             * @function getPayload
             * @description Prepares the payload for creating or updating a metric template.
             * @returns {Object} Contains the form data and update status.
             */
            getPayload() {
                // eslint-disable-next-line no-unused-vars
                const { ID, assessmentMetricGroup, assessmentMetricType, assessmentMetricOptions, createdAt, updatedAt, isTemplate, ...rest } = this.form;

                const payload = {
                    ...rest,
                    assessmentMetricGroupID: assessmentMetricGroup?.ID,
                    assessmentMetricTypeID: assessmentMetricType?.ID,
                    assessmentMetricOptionsIDs: assessmentMetricOptions?.map((item) => item.ID)
                };

                const update = this.templateMetricModal.currentAction === this.templateMetricModal.actions.UPDATE;

                return { ID, payload, update };
            },
            /**
             * @function onFilter
             * @description Applies selected filters and reloads the metric template data.
             */
            onFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.loadAssessmentMetricTemplates();
            },
            /**
             * @function onPage
             * @description Handles pagination events.
             * @param {Object} event - Event object containing pagination data.
             */
            onPage(event) {
                this.pagination = event;
                this.pagination['filters']['templateOnly'] = true;
                this.loadAssessmentMetricTemplates();
            },
            /**
             * @function initializeFilters
             * @description Sets up the initial filter and pagination configuration.
             */
            initializeFilters() {
                this.filters = {
                    templateOnly: true,
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
                this.pagination = {
                    page: 1,
                    rows: this.pageRows,
                    filters: this.filters
                };
            },
            clearAllFilters() {
                this.filterModal.selectedFilters = {};
                this.initializeFilters();
                this.hasFilters = false;
                this.onFilter();
            },
            makeFilter(filter) {
                this.filterModal.selectedFilters = {
                    ...this.filterModal.selectedFilters,
                    ...filter
                };
                this.filters.global = {
                    ...this.filters.global,
                    ...filter
                };
            },
            showFilterModal() {
                this.filterModal.display = true;
            },
            filterSearch() {
                this.filterModal.display = false;
                this.onFilter();
            },
            /**
             * @function resetTemplateModalState
             * @description Resets the modal state and clears the form data.
             */
            resetTemplateModalState() {
                this.templateMetricModal.display = false;
                this.templateMetricModal.progress = false;
                this.form = this.initForm();
            },
            /**
             * Controls input actions for the form (set, clear, or remove).
             * @param {string} entity - The form entity to modify.
             * @param {string} action - The action to perform (set, clear, or remove).
             * @param {object|null} [event=null] - The event data to apply to the form entity.
             */
            inputController(entity, action, event = null) {
                if (!this.form[entity]) {
                    this.form[entity] = Array.isArray(event) ? [] : {};
                }

                switch (action) {
                    case 'set': {
                        if (!event || typeof event !== 'object') {
                            console.error(`Invalid event data for setting entity ${entity}.`);
                            return;
                        }

                        this.form[entity] = event;

                        break;
                    }
                    case 'clear': {
                        if (typeof this.form[entity] === 'object') {
                            this.form[entity] = null;
                        } else {
                            console.error(`Cannot clear entity ${entity} with type ${typeof this.form[entity]}.`);
                        }
                        break;
                    }
                    case 'remove': {
                        if (!Array.isArray(this.form[entity])) {
                            console.error(`Cannot remove item from non-array entity ${entity}.`);
                            return;
                        }
                        if (event && event.ID) {
                            this.form[entity] = this.form[entity].filter((item) => item.ID !== event.ID);
                        } else {
                            console.error('Event must contain an ID for removal.');
                        }
                        break;
                    }
                    default: {
                        console.error(`Invalid action: ${action}`);
                    }
                }
            },
            closeAttrModal() {
                this.attributesModal.display = false;
                this.$toast.add({
                    severity: 'info',
                    summary: 'Informação',
                    detail: 'Atributos definidos com sucesso. Para salvar as alterações, pressione o botão "Salvar" na caixa de diálogo atual.',
                    life: 5000
                });
            }
        },
        computed: {
            /**
             * @function templateMetricModalTitle
             * @description Computes the title for the metric template modal based on the current action.
             * @returns {string} Modal title based on the action template.
             */
            templateMetricModalTitle() {
                const mainDlgTitle = 'modelo de métrica avaliativa';
                if (!this.templateMetricModal.progress) {
                    return this.templateMetricModal.currentAction === this.templateMetricModal.actions.CREATE ? `Criar ${mainDlgTitle}` : `Editar ${mainDlgTitle}`;
                }
                return `Salvando informações...`;
            },
            /**
             * @function validateForm
             * @description Computes to validate form data input
             * @returns {bool} True if form data inputs is valid
             */
            validateForm() {
                const isValidField = (fieldValue) => (this.computeScoreOnFinish ? fieldValue && fieldValue > 0 : true);

                const weightIsValid = isValidField(this.form.weight);
                const estimatedValueIsValid = isValidField(this.form.estimatedValue);

                const assessmentMetricTypeIsValid = Boolean(this.form.assessmentMetricType?.ID > 0);
                const assessmentMetricGroupIsValid = Boolean(this.form.assessmentMetricGroup?.ID > 0);
                const titleIsValid = Boolean(this.form.title?.trim().length > 0);

                return titleIsValid && weightIsValid && estimatedValueIsValid && assessmentMetricTypeIsValid && assessmentMetricGroupIsValid;
            },
            /**
             * @function isQuantitativeMetricType
             * @description Computes if the metric type in form is quantitative
             * @returns {bool} True if metric type is quantitative
             */
            isQuantitativeMetricType() {
                return this.form?.assessmentMetricType?.code === this.templateMetricModal.assessmentMetricTypeCodes.QUANTITATIVE;
            },
            /**
             * @function isQualitativeMetricType
             * @description Computes if the metric type in form is qualitative
             * @returns {bool} True if metric type is qualitative
             */
            isQualitativeMetricType() {
                return this.form?.assessmentMetricType?.code === this.templateMetricModal.assessmentMetricTypeCodes.QUALITATIVE;
            },
            /**
             * @function computedScoreOnFinish
             * @description Computes if the metric type in form calculates the score on finish
             * @returns {bool} True if metric type calculates the score on finish
             */
            computeScoreOnFinish() {
                return this.form?.assessmentMetricType?.calculateScoreOnFinish;
            }
        }
    });
</script>

<template>
    <Card>
        <!-- Title Section -->
        <template #title>
            <span class="font-light text-700 text-xl">Modelos de Métricas Avaliativas</span>
        </template>

        <!-- Toolbar Section -->
        <template #content>
            <Toolbar class="h-6rem">
                <!-- Start Buttons (Add, Delete) -->
                <template #start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            title="Adicionar novo modelo de métricas"
                            @click="displayTemplateMetricModal(templateMetricModal.actions.CREATE)"
                            :disabled="loading" />
                        <Button
                            icon="fas fa-trash"
                            title="Excluir um ou mais itens"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="confirmDeleteTemplateMetrics"
                            :disabled="!templates.selection.length" />
                    </div>
                </template>

                <!-- End Buttons (Refresh, Filter) -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            title="Atualizar lista"
                            @click="loadAssessmentMetricTemplates"
                            :disabled="templates.loading" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="filterModal.display = true"
                                :disabled="templates.loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearAllFilters"
                                :disabled="templates.loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>

        <!-- Data Table Section -->
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros"
                v-model:selection="templates.selection"
                v-model:filters="filters"
                @filter="onFilter"
                @page="onPage"
                :value="templates.records"
                :paginator="true"
                :loading="templates.loading"
                :totalRecords="templates.totalRecords"
                :rows="10"
                :rowsPerPageOptions="[10, 25, 50]">
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:.40%; min-width:2rem;" />
                <Column field="title" header="Titulo" :sortable="false" headerStyle="width:4%; min-width:2rem;" />
                <Column field="assessmentMetricType.name" header="Tipo" :sortable="false" headerStyle="width:3%; min-width:1rem;" />
                <Column field="assessmentMetricGroup.name" header="Grupo" :sortable="false" headerStyle="width:3%; min-width:1rem;" />
                <Column field="description" header="Descrição" :sortable="false" headerStyle="width:6%; min-width:2rem;" />
                <Column field="weight" header="Peso" :sortable="false" headerStyle="width:1%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-center">
                            <span class="text-lg font-bold">{{ slotProps.data?.weight || 'N/A' }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="estimatedValue" header="V. estimado" :sortable="false" headerStyle="width:1%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-center">
                            <span class="text-lg font-bold">{{ slotProps.data?.estimatedValue || 'N/A' }}</span>
                        </div>
                    </template>
                </Column>
                <Column header="Opções associadas" :sortable="false" headerStyle="width:4%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-center">
                            <span class="text-lg font-bold">{{ slotProps.data?.assessmentMetricOptions?.length || 'N/A' }}</span>
                        </div>
                    </template>
                </Column>
                <Column field="active" header="Ativo" :sortable="false" headerStyle="width:4%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-start">
                            <InputSwitch v-model="slotProps.data.active" disabled="true" />
                        </div>
                    </template>
                </Column>
                <Column headerStyle="width: 5%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-outlined p-button-rounded p-button-sm p-button-warning p-button-raised action-button"
                            title="Editar modelo"
                            @click="displayTemplateMetricModal(templateMetricModal.actions.UPDATE, slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog v-model:visible="filterModal.display" header="Filtro global" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <FilterTemplate @filterSelection="makeFilter($event)" :showFilters="filterModal.displayFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="filterSearch()" class="w-10rem" />
                <Button label="Cancelar" iconPos="left" icon="pi pi-times" autofocus @click="filterModal.display = false" class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="templateMetricModal.display"
        :header="templateMetricModalTitle"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '35vw' }"
        :maximizable="true"
        :closable="!templateMetricModal.progress"
        @hide="resetTemplateModalState">
        <ProgressTemplate v-if="templateMetricModal.progress" />
        <div class="p-input-filled p-button-filled" v-if="!templateMetricModal.progress">
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="metricGroup">Grupo:</label>
                </div>
                <input-options
                    name="metricGroup"
                    @selection="inputController('assessmentMetricGroup', 'set', $event)"
                    @cleared="inputController('assessmentMetricGroup', 'clear')"
                    :inputCurrentSelectionValue="form.assessmentMetricGroup?.name"
                    :currentSelection="form.assessmentMetricGroup"
                    :multiselect="false"
                    :showColumns="['id', 'name', 'description']"
                    :searchAPI="apis.assessmentMetricGroup"
                    :apiPropertyName="'assessmentMetricGroups'"
                    :showSearch="true"
                    :inputPlaceholder="'Selecione o grupo de métrica (Obrigatório)'"
                    :showClear="form.assessmentMetricGroup?.ID > 0"
                    :enableConfirmationButton="form.assessmentMetricGroup?.ID > 0"
                    :lazyLoadDialogMaximizable="true"
                    :lazyLoadTextHeader="'Selecione o grupo de métrica'" />
            </div>
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="metricType">Tipo:</label>
                </div>
                <input-options
                    name="metricType"
                    @selection="inputController('assessmentMetricType', 'set', $event)"
                    @cleared="inputController('assessmentMetricType', 'clear')"
                    :inputCurrentSelectionValue="form.assessmentMetricType?.name"
                    :currentSelection="form.assessmentMetricType"
                    :multiselect="false"
                    :showColumns="['id', 'name', 'description']"
                    :searchAPI="apis.assessmentMetricType"
                    :apiPropertyName="'assessmentMetricTypes'"
                    :showSearch="true"
                    :inputPlaceholder="'Selecione o tipo de métrica (Obrigatório)'"
                    :showClear="form.assessmentMetricType?.ID > 0"
                    :enableConfirmationButton="form.assessmentMetricType?.ID > 0"
                    :lazyLoadDialogMaximizable="true"
                    :lazyLoadTextHeader="'Selecione o tipo de métrica'" />
            </div>
            <div v-if="computeScoreOnFinish && isQualitativeMetricType">
                <div class="flex flex-column field">
                    <div class="flex flex-row mb-2" style="gap: 5px">
                        <span class="text-bold text-orange-500">*</span>
                        <label for="weight">Peso:</label>
                    </div>
                    <InputNumber
                        showButtons
                        mode="decimal"
                        name="weight"
                        v-model="form.weight"
                        placeholder="Peso (Obrigatório se o tipo da métrica calcula o score)"
                        class="w-full border-transparent"
                        :min="0"
                        :max="1000" />
                </div>
                <div class="flex flex-column field">
                    <div class="flex flex-row mb-2" style="gap: 5px">
                        <span class="text-bold text-orange-500">*</span>
                        <label for="estimatedValue">Valor estimado:</label>
                    </div>
                    <InputNumber
                        showButtons
                        name="estimatedValue"
                        :minFractionDigits="1"
                        :maxFractionDigits="2"
                        v-model="form.estimatedValue"
                        placeholder="Valor estimado (Obrigatório se o tipo da métrica calcula o score)"
                        class="w-full border-transparent"
                        :min="0"
                        :max="1000" />
                </div>
                <div class="flex flex-column field">
                    <div class="flex flex-row mb-2" style="gap: 5px">
                        <span class="text-bold text-orange-500">*</span>
                        <label for="metricOptions">Opções:</label>
                    </div>
                    <input-options
                        name="metricOptions"
                        @selection="inputController('assessmentMetricOptions', 'set', $event)"
                        @unSelection="inputController('assessmentMetricOptions', 'remove', $event)"
                        @cleared="inputController('assessmentMetricOptions', 'clear')"
                        :currentSelection="form.assessmentMetricOptions"
                        :multiselect="true"
                        :multiselectDisplayFieldName="'label'"
                        :showColumns="['id', 'label', 'description']"
                        :searchAPI="apis.assessmentMetricOptions"
                        :apiPropertyName="'assessmentMetricOptions'"
                        :inputPlaceholder="'Selecione uma ou mais opções de métricas'"
                        :showSearch="true"
                        :showClear="form.assessmentMetricOptions != null"
                        :enableConfirmationButton="form.assessmentMetricOptions != null"
                        :lazyLoadDialogMaximizable="true"
                        :lazyLoadTextHeader="'Selecione uma ou mais opções de métricas'" />
                </div>
            </div>
            <!-- <div v-else-if="!form.assessmentMetricType?.calculateScoreOnFinish && form.assessmentMetricType?.ID > 0">
                <div class="flex flex-column field">
                    <label for="description">Titulo do campo descritivo:</label>
                    <InputText
                        id="description"
                        v-model="form.settings.descriptiveFieldTitle"
                        placeholder="Descrição do modelo de métrica"
                        class="w-full border-transparent" />
                </div>
            </div> -->
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="title">Título:</label>
                </div>
                <InputText id="title" v-model="form.title" placeholder="Titulo do modelo (Obrigatório)" class="w-full border-transparent" @input="validateForm" />
            </div>
            <div class="flex flex-column field">
                <label for="description">Descrição:</label>
                <InputText id="description" v-model="form.description" placeholder="Descrição do modelo de métrica" class="w-full border-transparent" />
            </div>
            <div class="flex flex-column field" v-if="isQualitativeMetricType">
                <label for="description">Atributos:</label>
                <Button autofocus name="description" label="Definir atributos" iconPos="left" icon="fas fa-sliders-h" class="w-full" @click="attributesModal.display = true" />
            </div>
            <div class="text-lg w-full">
                <div class="flex flex-row justify-content-between align-items-center field">
                    <label for="metricFillRequired">Preenchimento obrigatório:</label>
                    <InputSwitch name="metricFillRequired" v-model="form['settings']['required']" />
                </div>
                <div class="flex flex-row justify-content-between align-items-start field">
                    <label for="metricStatus">Ativo/Inativo:</label>
                    <InputSwitch name="metricStatus" v-model="form.active" />
                </div>
            </div>
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!templateMetricModal.progress">
                <Button label="Salvar" iconPos="left" icon="fas fa-save" autofocus class="w-10rem" :disabled="!validateForm" @click="saveForm()" />
                <Button autofocus label="Cancelar" iconPos="left" icon="pi pi-times" class="p-button-outlined p-button-danger w-10rem" @click="this.templateMetricModal.display = false" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="attributesModal.display"
        :header="'Definir atributos do modelo'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="true">
        <div class="p-input-filled p-button-filled">
            <div class="flex flex-column field" style="gap: 15px">
                <div class="flex flex-column w-full" style="gap: 5px">
                    <label for="minimalValue">Valor mínimo:</label>
                    <InputNumber
                        name="minimalValue"
                        showButtons
                        mode="decimal"
                        v-model="form['settings']['min']"
                        placeholder="Valor mínimo"
                        class="border-transparent"
                        :min="0"
                        :max="1000"
                        style="min-width: 80px" />
                </div>
                <div class="flex flex-column w-full" style="gap: 5px">
                    <label for="minimalValue">Valor máximo:</label>
                    <InputNumber
                        id="maximumRequired"
                        showButtons
                        mode="decimal"
                        v-model="form['settings']['max']"
                        placeholder="Valor máximo"
                        class="border-transparent"
                        :min="0"
                        :max="1000"
                        style="min-width: 80px" />
                </div>
            </div>
            <div class="text-lg w-full">
                <div class="flex flex-row justify-content-between align-items-start field">
                    <label for="minimalRequired">Mínimo é rigoroso</label>
                    <InputSwitch name="minimalRequired" v-model="form['settings']['minimalIsStrict']" />
                </div>
                <div class="flex flex-row justify-content-between align-items-start field">
                    <label for="minimalRequired">Máximo é rigoroso</label>
                    <InputSwitch name="minimalRequired" v-model="form['settings']['maximumIsStrict']" />
                </div>
            </div>
        </div>
        <!---->
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Concluir" iconPos="left" icon="fas fa-check" autofocus @click="closeAttrModal()" class="w-10rem" />
            </div>
        </template>
    </Dialog>
</template>
