<script>
    import { FilterMatchMode } from 'primevue/api';
    import { defineComponent } from 'vue';
    import FilterModal from '../../Utils/Filter.vue';
    import ProgressTemplate from '../../Utils/Progress.vue';
    import DepartmentModal from './DepartmentModal.vue';
    import DepartmentRouter from '@/api/routers/Department';
    export default defineComponent({
        components: {
            FilterModal,
            ProgressTemplate,
            DepartmentModal
        },
        data() {
            return {
                loading: false,
                pagination: {},
                pageRows: 10,
                filterModal: {
                    show: false,
                    showFilters: ['people', 'general'],
                    selectedFilters: {}
                },
                departments: {
                    records: [],
                    totalRecords: 0,
                    selection: []
                },
                hasFilters: false,
                departmentModal: {
                    show: false,
                    save: false,
                    valid: false,
                    progress: false,
                    action: 'create',
                    title: null,
                    department: {}
                },
                filters: {
                    name: {
                        value: '',
                        matchMode: 'contains'
                    }
                },
                DepartmentApi: null
            };
        },
        created() {
            this.DepartmentApi = new DepartmentRouter();
            this.FilterInit();
        },
        mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.LoadDepartments();
        },
        methods: {
            ShowDepartmentModal(action, slot) {
                this.departmentModal.action = action;
                this.departmentModal.department = action == 'update' ? { ...slot } : null;
                this.departmentModal.title = `${action == 'create' ? 'Cadastrar' : 'Atualizar'} departamento`;
                this.departmentModal.show = true;
            },
            Valid(event) {
                event ? (this.departmentModal.valid = true) : (this.departmentModal.valid = false);
            },
            DepartmentModalResetState() {
                this.departmentModal.show = false;
                this.departmentModal.valid = false;
                this.departmentModal.save = false;
                this.departmentModal.department = {};
                this.LoadDepartments();
            },
            DepartmentsRemove() {
                this.$confirm.require({
                    header: 'Excluir',
                    message: 'Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        this.DepartmentApi.Remove(
                            this.departments.selection.map((c) => {
                                return c.ID;
                            })
                        )
                            .then(() => {
                                this.$toast.add({
                                    severity: 'success',
                                    summary: 'Sucesso',
                                    detail: 'Operação realizada com sucesso',
                                    life: 3000
                                });
                            })
                            .finally(this.LoadDepartments());
                    }
                });
            },
            LoadDepartments() {
                this.loading = true;
                setTimeout(() => {
                    this.DepartmentApi.Search(JSON.stringify(this.pagination))
                        .then((resp) => {
                            this.departments.records = resp.departments;
                            this.departments.totalRecords = resp.totalRecords;
                        })
                        .finally((this.loading = false));
                }, Math.random() * 1000 + 250);
            },
            CloseFilterModal() {
                this.filterModal.show = false;
            },
            ShowFilterModal() {
                this.filterModal.show = true;
            },
            MakeFilter(filter) {
                this.filterModal.selectedFilters = Object.assign({}, this.filterModal.selectedFilters, filter);
                this.filters.global = Object.assign({}, this.filters.global, filter);
            },
            ClearFilters() {
                this.filterModal.selectedFilters = [];
                this.FilterInit();
                this.hasFilters = false;
                this.OnFilter();
            },
            FilterSearch() {
                this.filterModal.show = false;
                this.OnFilter();
            },
            OnPage(event) {
                this.pagination = event;
                this.LoadDepartments();
            },
            OnFilter() {
                if (Object.keys(this.filterModal.selectedFilters).length > 0) {
                    this.hasFilters = true;
                }
                this.pagination.filters = this.filters;
                this.LoadDepartments();
            },
            FilterInit() {
                this.pagination = {
                    page: 1,
                    rows: this.pageRows
                };
                this.filters = {
                    global: {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };
            }
        }
    });
</script>

<template>
    <Card>
        <template #title>
            <span class="font-light text-700 text-xl">Departamentos</span>
        </template>
        <template #content>
            <Toolbar class="h-6rem">
                <template v-slot:start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            @click="ShowDepartmentModal('create')"
                            :disabled="loading"
                            title="Adicionar nova departamento" />
                        <Button
                            icon="fas fa-trash"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="DepartmentsRemove()"
                            :disabled="departments.selection.length == 0"
                            title="Excluir departamento(s) selecionada(s)" />
                    </div>
                </template>
                <template v-slot:end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            @click="LoadDepartments()"
                            :disabled="loading"
                            title="Atualizar lista" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="ShowFilterModal()"
                                :disabled="loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="ClearFilters()"
                                :disabled="loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>
        <template #footer>
            <DataTable
                ref="dt"
                :value="departments.records"
                dataKey="ID"
                :paginator="true"
                :rows="10"
                v-model:filters="filters"
                :loading="loading"
                :totalRecords="departments.totalRecords"
                @page="OnPage($event)"
                @filter="OnFilter($event)"
                @sort="onSort($event)"
                lazy="true"
                v-model:selection="departments.selection"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                :rowsPerPageOptions="[10, 20, 50, 100, 300]"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                responsiveLayout="scroll"
                class="p-datatable-sm">
                <template #header />
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" :sortable="false" headerStyle="width:2%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">ID</span>
                        <span>{{ slotProps.data?.ID }}</span>
                    </template>
                </Column>
                <Column field="name" header="Nome" :sortable="false" headerStyle="width:14%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Nome</span>
                        <span>{{ slotProps.data?.name }}</span>
                    </template>
                </Column>
                <Column field="manager" header="Gerente/Supervisor" :sortable="false" headerStyle="width:20%; min-width:2rem;">
                    <template #body="slotProps">
                        <span class="p-column-title">Gerente/Supervisor</span>
                        <span>{{ slotProps.data?.manager?.person?.name }}</span>
                    </template>
                </Column>
                <Column headerStyle="width: 5%; min-width:5rem;">
                    <template #body="slotProps">
                        <Button
                            icon="fas fa-pencil"
                            class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
                            title="Editar pessoa"
                            @click="ShowDepartmentModal('update', slotProps.data)" />
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>
    <Dialog
        v-model:visible="departmentModal.show"
        :header="!departmentModal.progress ? departmentModal.title : 'Aguarde...'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false"
        :closable="!departmentModal.progress"
        @hide="DepartmentModalResetState()">
        <DepartmentModal
            @valid="Valid($event)"
            @disablePersisteButton="this.departmentModal.valid = false"
            @close="departmentModal.show = false"
            @progress="departmentModal.progress = $event"
            :department="departmentModal.department"
            :action="departmentModal.action"
            :save="departmentModal.save"
            v-if="!departmentModal.progress" />
        <ProgressTemplate v-if="departmentModal.progress" />
        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="!departmentModal.progress">
                <Button
                    label="Salvar"
                    iconPos="left"
                    icon="fas fa-save"
                    autofocus
                    :disabled="!departmentModal.valid"
                    @click="departmentModal.save = true"
                    class="w-10rem" />
                <Button
                    autofocus
                    label="Cancelar"
                    iconPos="left"
                    icon="pi pi-times"
                    class="p-button-outlined p-button-danger w-10rem"
                    @click="departmentModal.show = false" />
            </div>
        </template>
    </Dialog>
    <Dialog
        v-model:visible="filterModal.show"
        header="Filtro avançado"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '30vw' }"
        :maximizable="false">
        <FilterModal @filterSelection="MakeFilter($event)" :showFilters="filterModal.showFilters" :currentFilters="filterModal.selectedFilters" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="FilterSearch()" class="w-10rem" />
                <Button
                    label="Cancelar"
                    iconPos="left"
                    icon="pi pi-times"
                    autofocus
                    @click="CloseFilterModal()"
                    class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>
</template>
