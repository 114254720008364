<script>
import { defineComponent } from "vue";
import PersonRouter from "@/api/routers/Person";
import SendChannelRouter from "@/api/routers/SendChannel";
import EnvelopeRouter from "@/api/routers/Envelope";
import EnvelopeTypeRouter from "@/api/routers/EnvelopeType";
import moment from "moment";

export default defineComponent({
  props: ["sendRecipients", "formData", "send"],
  emits: ["sent", "sending", "valid", "invalid"],
  data() {
    return {
      envelope: {
        title: "",
        recipients: [],
        sendChannels: [],
        scheduled: false,
        scheduledTo: null,
        expiration: null,
      },
      invalidScheduledTime: false,
      people: [],
      sendChannels: [],
      envelopeTypes: [],
      PersonApi: null,
      SendChannelApi: null,
      EnvelopeApi: null,
      EnvelopeTypesApi: null,
    };
  },
  created() {
    this.PersonApi = new PersonRouter();
    this.SendChannelApi = new SendChannelRouter();
    this.EnvelopeApi = new EnvelopeRouter();
    this.EnvelopeTypesApi = new EnvelopeTypeRouter();
  },
  async mounted() {
    await this.getPeople();
    await this.getSendChannels();
    await this.getEnvelopeTypes();
    this.selectedRecipients();
    this.setEnvelopeType();
  },
  methods: {
    async onPrepareSend() {
      this.$emit("sending");
      await this.sendRecipients.map((recipient) => {
        this.EnvelopeApi.Create(this.getEnvelopePayload(recipient));
      });
      setTimeout(() => this.$emit("sent"), 2000);
    },
    setEnvelopeType() {
      if (this.isPayrollDocument)
        this.envelope.envelopeType = this.envelopeTypes.find((obj) => {
          return obj.slug === "payroll";
        });
    },
    digestToSelect(data, l, v, toUpper) {
      return data?.map((item) => {
        return {
          label: toUpper ? item[l].toUpperCase() : item[l],
          value: item[v],
          slug: item?.slug,
        };
      });
    },
    async getPeople() {
      await this.PersonApi.FindAll()
        .then(
          (resp) =>
            (this.people = this.digestToSelect(
              resp.people,
              "name",
              "ID",
              false
            ))
        )
        .catch((err) => {
          console.error(err)
          this.$router.push("/pages/error");
        });
    },
    async getSendChannels() {
      await this.SendChannelApi.FindAll()
        .then(
          (resp) =>
            (this.sendChannels = this.digestToSelect(
              resp.sendChannels,
              "type",
              "ID",
              true
            ))
        )
        .catch((err) => {
          console.error(err)
          this.$router.push("/pages/error");
        });
    },
    async getEnvelopeTypes() {
      await this.EnvelopeTypesApi.FindAll().then(
        (resp) =>
          (this.envelopeTypes = this.digestToSelect(
            resp?.envelopeTypes,
            "name",
            "ID",
            true
          ))
      );
    },
    selectedRecipients() {
      this.envelope.recipients = this.sendRecipients?.map(
        (val) => val.people[0].ID
      );
    },
    validTimeScheduled() {
      let now = new Date();
      if (this.envelope.scheduled) {
        let scheduledTime = new Date(this.envelope.scheduledTo);
        if (scheduledTime.getTime() <= now.getTime()) {
          this.invalidScheduledTime = true;
          return false;
        }
        this.invalidScheduledTime = false;
        return true;
      }
      return true;
    },
    valid() {
      this.envelope.title?.length > 0 &&
      this.envelope.sendChannels?.length > 0 &&
      this.envelope.recipients?.length > 0 &&
      this.validTimeScheduled()
        ? this.$emit("valid")
        : this.$emit("invalid");
    },
    getEnvelopePayload(recipient) {
      return {
        title: this.envelope.title,
        description: this.envelope?.description,
        documentIds: [recipient.ID],
        personIDs:
          this.sendRecipients.length == 1 && this.envelope.recipients.length > 1
            ? this.envelope.recipients
            : recipient.people?.map((person) => {
                return person.ID;
              }),
        envelopeTypeId: this.envelope.envelopeType.value,
        sendChannelIds: this.envelope.sendChannels.map((item) => {
          return item.value;
        }),
        expirationDate: this.envelope?.expiration
          ? moment(this.envelope?.expiration).format("YYYY-MM-DD")
          : null,
        scheduled: this.envelope?.scheduled,
        scheduledTo: this.envelope?.scheduledTo
          ? moment(this.envelope?.scheduledTo).format("YYYY-MM-DDTHH:mm:00Z")
          : null,
      };
    },
  },
  watch: {
    send: function (e) {
      if (e) this.onPrepareSend();
    },
  },
  computed: {
    isPayrollDocument() {
      return this.formData?.documentType?.value == "payroll";
    },
    getCurrentDate() {
      return new Date();
    },
  },
});
</script>

<template>
  <div class="field">
    <label for="title">Título:</label>
    <InputText
      id="title"
      v-model.trim="envelope.title"
      required="true"
      @input="valid()"
      placeholder="Título do envelope"
      autofocus
      :class="{ 'p-invalid': !envelope.title }"
    />
    <small class="p-invalid" v-if="!envelope.title">Obrigatório</small>
  </div>
  <div class="field">
    <label for="description">Descrição:</label>
    <Textarea
      id="description"
      v-model="envelope.description"
      rows="5"
      cols="30"
      :autoResize="true"
    />
  </div>
  <div class="field">
    <label for="envelopeType">Tipo de envelope:</label>
    <Dropdown
      id="envelopeType"
      v-model="envelope.envelopeType"
      :options="envelopeTypes"
      optionLabel="label"
      :disabled="isPayrollDocument"
      placeholder="Selecione"
      required="true"
      @change="valid()"
    />
  </div>
  <div class="field">
    <label for="peoples_collaborators">Colaborador / Pessoa:</label>
    <MultiSelect
      id="peoples_collaborators"
      v-model="envelope.recipients"
      :options="people"
      optionLabel="label"
      emptyMessage="Sem resultados"
      optionValue="value"
      placeholder="Selecione"
      display="chip"
      required="true"
      @change="valid()"
      :class="{ 'p-invalid': envelope.recipients.length == 0 }"
      :disabled="sendRecipients.length > 1"
    >
    </MultiSelect>
    <small class="p-invalid" v-if="envelope.recipients.length == 0">
      Obrigatório
    </small>
  </div>
  <div class="field">
    <label for="sendMethods">Canais de envio:</label>
    <MultiSelect
      id="sendMethods"
      v-model="envelope.sendChannels"
      :options="sendChannels"
      optionLabel="label"
      placeholder="Selecione"
      required="true"
      display="chip"
      @change="valid()"
      :class="{ 'p-invalid': envelope.sendChannels.length == 0 }"
    />
    <small class="p-invalid" v-if="envelope.sendChannels.length == 0">
      Obrigatório
    </small>
  </div>
  <div class="field">
    <label for="expiration">Expiração:</label>
    <Calendar
      class="mt-2"
      :showButtonBar="true"
      id="expiration"
      inputId="single"
      view="date"
      :minDate="getCurrentDate"
      v-model="envelope.expiration"
      selectionMode="single"
      :manualInput="false"
      :showIcon="true"
    />
  </div>
  <div class="field flex">
    <Checkbox
      inputId="schedulelbl"
      :binary="true"
      v-model="envelope.scheduled"
      class="mr-2"
      @change="valid()"
    />
    <span id="schedulelbl" for="scheduled">Agendar envio para:</span>
  </div>
  <div class="field" v-if="envelope.scheduled">
    <label for="expiration">Data de envio:</label>
    <Calendar
      class="mt-2"
      :showButtonBar="true"
      id="scheduled"
      inputId="single"
      :showTime="true"
      view="date"
      @date-select="valid()"
      v-model="envelope.scheduledTo"
      selectionMode="single"
      :manualInput="false"
      :showIcon="true"
      :minDate="getCurrentDate"
      :class="{ 'p-invalid': invalidScheduledTime }"
    />
    <small class="p-invalid" v-if="invalidScheduledTime">
      A data escolhida é igual ou inferior a data atual
    </small>
  </div>
</template>
