<script>
import { defineComponent } from "vue";
import PersonRouter from "@/api/routers/Person";
import CompanyRouter from "@/api/routers/Company";
export default defineComponent({
  props: ["action", "company", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      companyForm: {
        name: null,
        txID: null,
      },
      people: {
        records: [],
        selectedPerson: null,
        loading: false,
      },
      PersonApi: null,
      CompanyApi: null,
    };
  },
  created() {
    this.PersonApi = new PersonRouter();
    this.CompanyApi = new CompanyRouter();
  },
  async mounted() {
    await this.LoadPeople();
    this.GetAction();
  },
  methods: {
    async LoadPeople() {
      this.people.loading = true;
      this.people.records = await this.PersonApi.FindAll()
        .then((resp) => {
          return resp?.people.map((p) => {
            return {
              label: p.name,
              value: p.ID,
            };
          });
        })
        .finally((this.people.loading = false));
    },
    GetAction() {
      if (this.action == "update") {
        this.companyForm = { ...this.company };
        this.people.selectedPerson = this.company?.personID;
      }
    },
    Valid() {
      if (
        this.companyForm.name?.length > 0 &&
        this.companyForm.txID?.length > 0 &&
        this.people.selectedPerson != null
      ) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreateCompany();
          break;
        case "update":
          this.UpdateCompany();
          break;
      }
    },
    async UpdateCompany() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.CompanyApi.Update(this.GetPayload(), this.companyForm.ID)
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreateCompany() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.CompanyApi.Create(this.GetPayload())
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Operação realizada com sucesso`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    GetPayload() {
      let payload = {};
      try {
        payload = {
          name: this.companyForm.name,
          txID: this.companyForm.txID,
          personID: this.people.selectedPerson,
        };
        return payload;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Erro critico",
          detail: "Ocorreu um erro ao obter as informações necessárias",
          life: 3000,
        });
      }
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="name">Nome:</label>
    <InputText
      id="name"
      v-model="companyForm.name"
      placeholder="Nome da empresa"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="txID">CNPJ:</label>
    <InputText
      id="txID"
      v-model="companyForm.txID"
      placeholder="CNPJ da empresa"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="ceo">Diretor:</label>
    <Dropdown
      id="ceo"
      v-model="people.selectedPerson"
      optionLabel="label"
      optionValue="value"
      placeholder="Selecione a pessoa"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="people.records"
      :loading="people.loading"
      @change="Valid()"
    />
  </div>

</template>
