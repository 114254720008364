<script>
import { defineComponent } from "vue";
import PermissionRouter from "@/api/routers/Permission";
export default defineComponent({
  props: ["action", "permission", "save"],
  emits: ["valid", "close", "progress"],
  data() {
    return {
      permissionForm: {
        name: null,
        path: null,
      },
      methods: {
        records: [
          { label: "*", value: "*" },
          { label: "GET", value: "GET" },
          { label: "POST", value: "POST" },
          { label: "PUT", value: "PUT" },
          { label: "PATCH", value: "PATCH" },
          { label: "DELETE", value: "DELETE" },
        ],
        selectedMethod: null,
        loading: false,
      },
      PermissionApi: null,
    };
  },
  created() {
    this.PermissionApi = new PermissionRouter();
  },
  async mounted() {
    this.GetAction();
  },
  methods: {
    GetAction() {
      if (this.action == "update") {
        this.permissionForm = { ...this.permission };
        this.methods.selectedMethod = this.permission.method;
      }
    },
    Valid() {
      if (
        this.permissionForm.name?.length > 0 &&
        this.permissionForm.path?.length > 0 &&
        this.methods.selectedMethod?.length > 0
      ) {
        this.$emit("valid", true);
      } else {
        this.$emit("valid", false);
      }
    },
    Save() {
      switch (this.action) {
        case "create":
          this.CreatePermission();
          break;
        case "update":
          this.UpdatePermission();
          break;
      }
    },
    async UpdatePermission() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.PermissionApi.Update(
          this.GetPayload(),
          this.permissionForm.ID
        )
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: "Operação realizada com sucesso",
              life: 3000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    async CreatePermission() {
      this.$emit("progress", true);
      setTimeout(async () => {
        await this.PermissionApi.Create(this.GetPayload())
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Sucesso",
              detail: `Operação realizada com sucesso`,
              life: 2000,
            });
          })
          .finally(() => {
            this.$emit("progress", false);
            this.$emit("close");
          });
      }, 2000);
    },
    GetPayload() {
      let payload = {};
      try {
        payload = {
          name: this.permissionForm.name,
          method: this.methods.selectedMethod,
          path: this.permissionForm.path,
        };
        return payload;
      } catch {
        this.$toast.add({
          severity: "error",
          summary: "Erro critico",
          detail: "Ocorreu um erro ao obter as informações necessárias",
          life: 3000,
        });
      }
    },
  },
  watch: {
    save: function () {
      this.Save();
    },
  },
});
</script>

<template>
  <div class="flex flex-column field">
    <label for="name">Nome:</label>
    <InputText
      id="name"
      v-model="permissionForm.name"
      placeholder="Nome da permissão"
      @input="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="txID">Método</label>
    <Dropdown
      id="method"
      v-model="methods.selectedMethod"
      placeholder="Selecione um método"
      optionLabel="label"
      optionValue="value"
      emptyFilterMessage="Sem resultados"
      emptyMessage="Sem resultados"
      :filter="true"
      :options="methods.records"
      :loading="methods.loading"
      @change="Valid()"
    />
  </div>
  <div class="flex flex-column field">
    <label for="endpoint">Endpoint:</label>
    <InputText
      id="endpoint"
      v-model="permissionForm.path"
      placeholder="Endpoint de acesso"
      @input="Valid()"
    />
  </div>

</template>
