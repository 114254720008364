import AxiosInstance from '@/services/AxiosInstance';

const assessmentEndpoint = 'assessments';
const assessmentTemplateEndpoint = `${assessmentEndpoint}/templates`;

/**
 * Class to interact with the Assessment API
 */
export default class AssessmentRouter {
    /**
     * Searches for assessments based on the provided payload
     *
     * @async
     * @param {Object} payload - The search criteria for assessments
     * @returns {Promise<Object>} - A promise that resolves to the search results
     * @throws {Error} - Throws an error if the search fails
     */
    async Search(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentEndpoint}/search`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to search assessments: ${error.message}`);
        }
    }

    /**
     * Create a new assessment from payload data
     *
     * @async
     * @param {Object} payload - The payload data to create the assessment
     * @returns {Promise<Object>} - A promise that resolves to the assessment data
     * @throws {Error} - Throws an error if retrieval fails
     */
    async New(payload) {
        try {
            const response = await AxiosInstance.post(`${assessmentEndpoint}/new`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to create a new assessment: ${error.message}`);
        }
    }

    /**
     * Retrieves the first assessments by its ID
     *
     * @async
     * @param {number} ID - The identifier of the assessment
     * @returns {Promise<Object>} - A promise that resolves to the assessment data
     * @throws {Error} - Throws an error if retrieval fails
     */
    async FirstByID(ID) {
        try {
            const response = await AxiosInstance.get(`${assessmentEndpoint}/${ID}`);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to retrieve assessment by ID: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment by ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment.
     * @param {Object} payload - The updated data for the assessment.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async Update(ID, payload) {
        try {
            return await AxiosInstance.put(`${assessmentEndpoint}/${ID}`, payload);
        } catch (error) {
            throw new Error(`Failed to update assessment: ${error.message}`);
        }
    }

    /**
     * Cancel assessment by ID
     * @async
     * @param {number} ID - The identifier of assessment resource
     * @returns {Promise<void>} - A promise that resolves when cancel is complete
     * @throws {Error} - Throws an error if the cancel fails
     */
    async Cancel(ID) {
        try {
            return await AxiosInstance.post(`${assessmentEndpoint}/cancel/${ID}`);
        } catch (error) {
            throw new Error(`Failed to cancel assessment: ${error.message}`);
        }
    }

    /**
     * Pause assessment by ID
     * @async
     * @param {number} ID - The identifier of assessment resource
     * @returns {Promise<void>} - A Promise that resolves when pause is complete
     * @throws {Error} - Throws an error if the pause fails
     */
    async Pause(ID) {
        try {
            return await AxiosInstance.post(`${assessmentEndpoint}/pause/${ID}`);
        } catch (error) {
            throw new Error(`Failed to pause assessment: ${error.message}`);
        }
    }

    /**
     * Resume paused assessment by ID
     * @async
     * @param {number} ID - The identifier of assessment resource
     * @returns {Promise<Object>} - A Promise that resolves when resume is complete
     * @throws {Error} - Throws an error if the resume fails
     */
    async Resume(ID) {
        try {
            const response = await AxiosInstance.post(`${assessmentEndpoint}/resume/${ID}`);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to pause assessment: ${error.message}`);
        }
    }

    /**
     * Create assessment template.
     *
     * @async
     * @param {Object} payload - The updated data for the assessment template.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async CreateTemplate(payload) {
        try {
            return await AxiosInstance.post(`${assessmentTemplateEndpoint}`, payload);
        } catch (error) {
            throw new Error(`Failed to create assessment template: ${error.message}`);
        }
    }

    /**
     * Updates an existing assessment template by ID.
     *
     * @async
     * @param {number} ID - The unique identifier of the assessment template.
     * @param {Object} payload - The updated data for the assessment template.
     * @returns {Promise<Object>} - A promise that resolves when the update is complete.
     * @throws {Error} - Throws an error if the update fails.
     */
    async UpdateTemplate(ID, payload) {
        try {
            return await AxiosInstance.put(`${assessmentTemplateEndpoint}/${ID}`, payload);
        } catch (error) {
            throw new Error(`Failed to update assessment template: ${error.message}`);
        }
    }

    /**
     * Removes one or more assessments based on their IDs.
     *
     * @async
     * @param {Array<string>} IDs - The list of unique identifiers for the assessment to be removed.
     * @returns {Promise<void>} - A promise that resolves when the deletion is complete.
     * @throws {Error} - Throws an error if the deletion fails.
     */
    async Remove(IDs) {
        try {
            await AxiosInstance.delete(assessmentEndpoint, { data: IDs });
        } catch (error) {
            throw new Error(`Failed to remove assessments: ${error.message}`);
        }
    }

    /**
     * Assigns assessment metrics to a specific assessment template.
     *
     * @async
     * @param {string} assessmentID - The unique identifier of the assessment.
     * @param {Array<string>} assessmentMetricIDs - The list of metrics IDs to assign.
     * @returns {Promise<Object>} - A promise that resolves to the updated template data.
     * @throws {Error} - Throws an error if the assignment fails.
     */
    async AssignMetricsToTemplate(assessmentID, assessmentMetricTemplateIDs) {
        const payload = {
            assessmentID: assessmentID,
            assessmentMetricTemplateIDs: assessmentMetricTemplateIDs
        };

        try {
            const response = await AxiosInstance.post(`${assessmentTemplateEndpoint}/assign-options`, payload);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to assign options to assessment template: ${error.message}`);
        }
    }

    /**
     * Get a score calculation result from assessment
     *
     * @async
     * @param {number} ID - The identifier of the assessment
     * @returns {Promise<Object>} - The data of score calculation
     * @throws {Error} - Throws and error if score calculation fails
     */
    async ScoreCalculation(ID) {
        try {
            const response = await AxiosInstance.get(`${assessmentEndpoint}/score-calculation/${ID}`);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed on score calculation: ${error.message}`);
        }
    }

    /**
     * Marks an assessment as finished.
     *
     * @async
     * @param {number} ID - The identifier of the assessment.
     * @param {Object} payload - The data to complete the assessment.
     * @returns {Promise<Object>} - A promise that resolves to the finished assessment data.
     * @throws {Error} - Throws an error if marking as finished fails.
     */
    async Finish(ID) {
        try {
            const response = await AxiosInstance.post(`${assessmentEndpoint}/finish/${ID}`);
            return response.data?.data;
        } catch (error) {
            throw new Error(`Failed to finish assessment: ${error.message}`);
        }
    }
}
