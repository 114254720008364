<script>
import { defineComponent } from 'vue';
import Token from '@/services/Token';
import QuickValidationRouter from '@/api/routers/QuickValidation';
import AppEventBus from '@/layout/composables/AppEventBus';
import moment from 'moment';
import PDFViewer from '../Utils/PDFViewer.vue';
import PasswordModal from '../Utils/PasswordModal.vue';
import EnvelopeComments from '../Utils/EnvelopeComments.vue';
import Lottie from '@/components/Utils/Lottie.vue';
import EmptyBoxLottieJson from '@/layout/lotties/empty_box.json';

export default defineComponent({
    name: 'Login',
    inject: ['AxiosInstance'],
    components: {
        PDFViewer,
        PasswordModal,
        EnvelopeComments,
        Lottie
    },
    data() {
        return {
            loading: true,
            dataViewMsg: 'Please wait...',
            pageRows: 3,
            displayEmptyLottie: false,
            documentModal: {
                show: false,
                valid: false,
                action: null,
                password: null,
                rotate: 1,
                zoom: 500,
                base64: null,
                content: null,
                wrongPassword: false,
                envelopeId: null,
                visualizedEvent: false,
                observations: null,
                event: null
            },
            lottie: {
                animation: EmptyBoxLottieJson,
                height: 100,
                width: 100,
                speed: 0.6,
                loop: true
            },
            documents: {
                layout: 'list',
                records: [],
                totalRecords: 0
            },
            sort: {
                sortKey: null,
                sortOrder: null,
                sortField: null,
                sortOptions: [
                    { label: 'Aguardando assinatura', value: 'pending' },
                    { label: 'Assinados', value: 'signed' },
                    { label: 'Contestados', value: 'refused' }
                ]
            },
            QuickValidationApi: null
        };
    },
    computed: {
        logoUrl() {
            return `images/logo.svg`;
        },
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        DocumentModalTitle() {
            return this.documentModal.action === 'password' ? 'Documento protegido' : this.documentModal.action === 'view' ? 'Visualizar documento' : 'Observações';
        }
    },
    created() {
        this.QuickValidationApi = new QuickValidationRouter();
    },
    async mounted() {
        this.setLoginTheme();
        this.pageRows = this.$refs.dt.rows;
        this.pendingDocuments();
    },
    methods: {
        ClearLocalStorage() {
            Token.clearLocalStorage();
        },
        GetDocumentFormat(slot) {
            let fileExt = slot[0].content.split('.').pop();
            return fileExt;
        },
        FormatDate(date) {
            return moment(date).format('DD/MM/YYYY');
        },
        ValidPassword(event) {
            event ? (this.documentModal.valid = true) : (this.documentModal.valid = false);
        },
        ResetDocumentModalState() {
            this.documentModal = {
                show: false,
                valid: false,
                action: null,
                password: null,
                rotate: 1,
                zoom: 500,
                base64: null,
                content: null,
                wrongPassword: false,
                observations: null,
                envelopeId: null,
                visualizedEvent: false,
                event: null
            };
        },
        async DocumentView(slot) {
            try {
                const documentId = slot.documents[0].ID;
                const resp = await this.QuickValidationApi.DocumentView(documentId);
                const base64Pdf = resp.base64Pdf;

                this.documentModal.base64 = base64Pdf;
                this.documentModal.envelopeId = slot.ID;

                let hasPassword = slot?.documents[0].protected;

                if (hasPassword) {
                    this.documentModal.action = 'password';
                    this.documentModal.show = true;
                } else {
                    this.documentModal.action = 'view';
                    this.documentModal.show = true;
                }
            } catch (err) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Falha',
                    detail: 'Erro desconhecido ao tentar recuperar informações do documento',
                    life: 5000
                });
            }
        },
        OnWrongPassword() {
            this.documentModal.action = 'password';
            this.documentModal.wrongPassword = true;
            this.documentModal.valid = false;
        },
        async OnPasswordConfirmPressed() {
            this.documentModal.action = 'view';
        },
        SetZoom(type) {
            let minZoom = 100;
            let maxZoom = 1200;
            if (type == 'IN' && this.documentModal.zoom != maxZoom) {
                return (this.documentModal.zoom += 100);
            } else if (type == 'OUT' && this.documentModal.zoom != minZoom) {
                return (this.documentModal.zoom -= 100);
            }
        },
        async OnDocumentRendered() {
            if (!this.documentModal.visualizedEvent) {
                let payload = {
                    envelopeId: this.documentModal.envelopeId,
                    event: 'visualized',
                    observations: 'Quick Validation'
                };
                this.QuickValidationApi.AddEnvelopeEvent(payload);
                this.documentModal.visualizedEvent = true;
            }
        },
        OnDownload() {
            window.open(this.documentModal.base64, '_blank');
        },
        OnGetEnvelopeObservations(event) {
            this.documentModal.action = 'observations';
            this.documentModal.event = event;
        },
        Logout() {
            this.$confirm.require({
                header: 'Encerrar sessão',
                message: 'Confirmar encerramento da sessão?',
                icon: 'far fa-question-circle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: () => {
                    this.$store.dispatch('auth/logout');
                    this.$router.push('/auth/login');
                    return;
                }
            });
        },
        CreateEnvelopeEvent() {
            let payload = {
                envelopeId: this.documentModal.envelopeId,
                event: this.documentModal.event,
                observations: this.documentModal.observations
            };

            this.$confirm.require({
                header: 'Confirmação',
                message: 'Confirmar operação ?',
                icon: 'far fa-question-circle',
                acceptLabel: 'Sim',
                rejectLabel: 'Não',
                accept: () => {
                    this.QuickValidationApi.AddEnvelopeEvent(payload)
                        .then(() => {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Sucesso',
                                detail: 'Sua solicitação foi realizada com sucesso',
                                life: 3000
                            });
                        })
                        .finally(async () => {
                            this.documentModal.show = false;
                            this.pendingDocuments();
                        });
                },
                reject: () => {
                    this.documentModal.show = false;
                }
            });
        },
        async pendingDocuments() {
            this.documents.records = [];
            this.loading = true;
            this.dataViewMsg = 'Obtendo informações, aguarde...';
            setTimeout(async () => {
                const resp = await this.QuickValidationApi.PendingEnvelopes();
                if (resp.pendingEnvelopes.length == 0) {
                    this.loading = false;
                    this.dataViewMsg = 'Nenhum documento pendente disponível';
                    this.displayEmptyLottie = true;
                    return;
                }

                this.documents.records = resp.pendingEnvelopes;
                this.loading = false;
            }, Math.random() * 1000 + 250);
        },
        setLoginTheme() {
            AppEventBus.emit('theme-change', {
                theme: 'lara-light-blue',
                dark: false
            });
        }
    }
});
</script>

<template>
    <ConfirmDialog />
    <Dialog v-model:visible="documentModal.show" :header="DocumentModalTitle" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :maximizable="documentModal.action == 'password' ? false : true" @hide="ResetDocumentModalState()">
        <PasswordModal v-if="documentModal.action == 'password'" @password="documentModal.password = $event" @valid="ValidPassword($event)" :wrongPassword="documentModal.wrongPassword" />

        <PDFViewer v-if="documentModal.action == 'view'" :pdf="documentModal.base64" :password="documentModal.password" :zoom="documentModal.zoom" :rotate="documentModal.rotate" @rendered="OnDocumentRendered()" @wrongPassword="OnWrongPassword()" />

        <EnvelopeComments v-if="documentModal.action == 'observations'" @observations="documentModal.observations = $event" />

        <template #footer>
            <div class="flex flex-row justify-content-end" v-if="documentModal.action == 'password'">
                <Button label="Cancelar" iconPos="right" icon="fas fa-times" autofocus @click="documentModal.show = false" class="w-auto" />
                <Button :disabled="!documentModal.valid" label="Continuar" iconPos="right" icon="fas fa-arrow-right" autofocus @click="OnPasswordConfirmPressed()" class="w-auto" />
            </div>

            <div class="flex mt-4 justify-content-between w-full" v-if="documentModal.action == 'view'">
                <div class="flex">
                    <Button icon="fa-solid fa-magnifying-glass-plus ml-2 mr-2" @click="SetZoom('IN')" title="Aumentar o zoom" autofocus />
                    <Button icon="fa-solid fa-magnifying-glass-minus ml-2 mr-2" @click="SetZoom('OUT')" title="Reduzir o zoom" autofocus />
                    <Button icon="fa-solid fa-rotate-right ml-2 mr-2" @click="documentModal.rotate++" title="Girar 90º" autofocus />
                    <Button icon="fa-solid fa-download ml-2 mr-2" title="Baixar documento" @click="OnDownload()" autofocus />
                </div>
                <div class="flex flex-row sm:flex flex-wrap justify-content-end" style="gap: 0.5rem">
                    <Button autofocus label="Recusar" icon="fa-solid fa-times" class="p-button-danger w-8rem" :disabled="EnvelopeActionsButtons" @click="OnGetEnvelopeObservations('refused')" />
                    <Button autofocus label="Aceitar" icon="fa-solid fa-check" class="p-button-success w-8rem" :disabled="EnvelopeActionsButtons" @click="OnGetEnvelopeObservations('signed')" />
                </div>
            </div>
            <div class="flex flex-row justify-content-end" v-if="documentModal.action == 'observations'">
                <Button label="Cancelar" iconPos="right" icon="fas fa-times" autofocus @click="documentModal.show = false" class="w-auto" />
                <Button :disabled="!documentModal.valid" label="Continuar" iconPos="right" icon="fas fa-arrow-right" autofocus @click="CreateEnvelopeEvent()" class="w-auto" />
            </div>
        </template>
    </Dialog>
    <div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden pg-background px-3">
        <div class="flex flex-column card py-6 shadow-3 justify-content-between responsive">
            <div class="surface-card flex flex-column justify-content-between">
                <div class="flex flex-column justify-content-between mb-2">
                    <div class="text-left">
                        <img :src="logoUrl" alt="logo" height="40" />
                    </div>
                    <label class="font-light text-700 text-sm">Validação rápida de documentos</label>
                </div>
                <div class="flex mt-2 h-auto w-full">
                    <DataView
                        ref="dt"
                        dataKey="ID"
                        class="w-full"
                        lazy="true"
                        v-model:filters="filters"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
                        responsiveLayout="scroll"
                        :rowsPerPageOptions="[3]"
                        :value="documents.records"
                        :layout="documents.layout"
                        :paginator="true"
                        :rows="3"
                        :loading="loading"
                        :totalRecords="documents.totalRecords"
                        :sortOrder="sort.sortOrder"
                        :sortField="sort.sortField"
                        @page="OnPage($event)"
                        @filter="OnFilter($event)"
                        @sort="onSort($event)"
                    >
                        <template #header>
                            <div class="grid">
                                <div class="col-12" style="text-align: right">
                                    <div class="flex flex-row justify-content-end">
                                        <Button icon="fas fa-redo-alt" class="p-button-sm p-button-rounded p-button-raised p-button-info mr-2" autofocus @click="pendingDocuments()" :disabled="this.loading" />
                                        <Button icon="fas fa-sign-out-alt" class="p-button-sm p-button-rounded p-button-raised p-button-warning" autofocus @click="Logout()" />
                                    </div>
                                </div>
                            </div>
                        </template>

                        <template #list="slotProps">
                            <div class="col-12 w-full">
                                <div class="shadow-1 border-round item-list-item flex justify-content-between">
                                    <div class="flex flex-row align-items-center">
                                        <div class="relative">
                                            <span class="badge-notification-list">
                                                <i title="Envelope aguardando assinatura" class="fa-solid fa-circle-exclamation text-yellow-500 fa-xl" />
                                            </span>
                                            <img src="images/envelopes/pdf.png" style="width: 64px; height: 64px" />
                                        </div>
                                        <div class="item-list-detail">
                                            <div class="item-name">
                                                {{ slotProps.data?.title || slotProps.data?.description }}
                                            </div>
                                            <div class="item-description" v-if="slotProps.data?.expirationDate">Expira em: {{ FormatDate(slotProps.data?.expirationDate) }}</div>
                                            <div title="Data de referência do documento" v-if="slotProps.data?.documents[0]?.referenceDate">
                                                <i class="fas fa-calendar mr-1" />
                                                <span class="item-category">{{ slotProps.data?.documents[0].referenceDate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="flex flex-row" style="gap: 0.5rem">
                                        <Button class="p-button-info p-button-outlined p-button-raised p-button-rounded" icon="fas fa-file-contract fa-lg" @click="DocumentView(slotProps.data)" />
                                    </div>
                                </div>
                            </div>
                        </template>
                        <template #empty>
                            <div class="empty-dataview h-22rem" style="gap: 1rem">
                                <div class="flex flex-column">
                                    <Lottie :lottieProp="this.lottie" v-if="!this.loading && this.displayEmptyLottie" />

                                    <div class="flex flex-row" style="gap: 0.7rem">
                                        <div v-if="loading">
                                            <i class="pi pi-spin fas fa-spinner fa-xl" />
                                        </div>
                                        <span class="text-900 font-normal text-lg text-center">
                                            {{ dataViewMsg }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </DataView>
                </div>
            </div>
        </div>
    </div>
    <Toast position="bottom-right" />
</template>

<style lang="scss" scoped>
/* card default */
.responsive {
    width: 50rem;
}

/* card mobile small */
@media screen and (max-width: 320px) {
    .responsive {
        width: 20rem;
    }
}

/* card mobile medium */
@media screen and (max-width: 480px) {
    .responsive {
        width: 30rem;
    }
}

.p-dropdown {
    width: 14rem;
    font-weight: normal;
}

.item-name {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 2px;
}

.item-description {
    margin: 0 0 0.5rem 0;
}

.item-category-icon {
    vertical-align: middle;
    margin-right: 0.5rem;
}

.item-category {
    font-weight: 600;
    vertical-align: middle;
}

::v-deep(.item-list-item) {
    display: flex;
    align-items: center;
    margin-top: 10px;
    padding: 1rem;
    padding-left: 1%;
    width: 100%;

    img {
        width: 50px;
        margin-right: 1rem;
    }

    .item-list-detail {
        flex: 1 1 0;
    }

    .item-list-action {
        display: flex;
    }

    .p-button {
        margin-bottom: 0.5rem;
    }
}

::v-deep(.item-grid-item) {
    margin: 0.5rem;
    border: 1px solid var(--surface-border);

    .item-grid-item-top,
    .item-grid-item-bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    img {
        margin: 2rem 0;
    }

    .item-grid-item-content {
        text-align: center;
    }
}

@media screen and (max-width: 576px) {
    .item-list-item {
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        img {
            margin: 0rem 0;
        }

        .item-list-detail {
            margin-left: 12px;
            text-align: start;
        }

        .item-list-action {
            display: flex;
            flex-direction: column;
        }

        .item-list-action {
            margin-top: 2rem;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
        }
    }
}

@media screen and (max-width: 1080px) {
    .badge-notification-grid {
        left: 420px !important;
        bottom: 135px !important;
        position: absolute !important;
        color: white;
    }
}
.badge-notification-list {
    left: 0px !important;
    bottom: 50px !important;
    position: absolute !important;
    color: white;
}
.badge-notification-grid {
    left: 280px !important;
    bottom: 135px !important;
    position: absolute !important;
    color: white;
}
</style>
