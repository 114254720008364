z
<script>
    import { defineComponent } from 'vue';
    import { FilterMatchMode } from 'primevue/api';
    import AssessmentRouter from '../../api/routers/Assessment';
    import AssessmentMetricRouter from '../../api/routers/AssessmentMetric';
    import AdvancedFilter from '../Utils/AdvancedFilter.vue';
    import Lottie from '../Utils/Lottie.vue';
    import ErrorLottie from '@/layout/lotties/error.json';
    import CongratulationsLottie from '@/layout/lotties/congratulations3.json';
    import CollaboratorRouter from '../../api/routers/Collaborator';
    import InputOptions from '../Utils/InputOptions.vue';
    import moment from 'moment';
    import AssessmentMetricTypeRouter from '../../api/routers/AssessmentMetricType';
    import AssessmentMetricGroupRouter from '../../api/routers/AssessmentMetricGroup';
    import ProgressTemplate from '../Utils/Progress.vue';

    /**
     * @module AssessmentComponent
     * @description Vuw component responsible for managing assessments, including fetching, filtering
     * creating, updating and deleting assessments
     */
    export default defineComponent({
        components: {
            Lottie,
            InputOptions,
            AdvancedFilter,
            ProgressTemplate
        },
        data() {
            return {
                hasFilters: false,
                pageRows: 10,
                zoomLevel: 1,
                pagination: {},
                filters: {},
                apis: {
                    assessment: new AssessmentRouter(),
                    assessmentMetric: new AssessmentMetricRouter(),
                    collaborator: new CollaboratorRouter()
                },
                assessments: {
                    loading: false,
                    records: [],
                    selection: [],
                    totalRecords: 0
                },
                assessmentDetailsSidebar: {
                    display: false,
                    loading: false,
                    assessment: null,
                    assessmentMetrics: null,
                    scoreByAssessmentMetricGroup: null,
                    totalScore: 0,
                    error: null
                },
                assessmentMetricTypeCodes: {
                    QUANTITATIVE: 'quantitative',
                    QUALITATIVE: 'qualitative'
                },
                assessmentStatusCodes: {
                    FILLING: 'filling',
                    FINISHED: 'finished',
                    PAUSED: 'paused',
                    CANCELLED: 'cancelled'
                },
                assessment: {
                    loading: false,
                    formSavingProgress: false,
                    preRegistrationForm: this.initForm(),
                    data: null,
                    error: null,
                    groupedScoreResult: null,
                    sidebarMetrics: {
                        display: false,
                        scoreResultsStep: false
                    },
                    preRegistrationModal: {
                        display: false
                    }
                },
                lottieError: {
                    animation: ErrorLottie,
                    height: 200,
                    width: 200,
                    speed: 0.8,
                    loop: false
                },
                lottieCongratulations: {
                    animation: CongratulationsLottie,
                    height: 350,
                    width: 1000,
                    speed: 1,
                    loop: false
                },
                assessmentTemplateAdditionalFilters: {
                    templateOnly: true
                },
                filter: {
                    display: false,
                    selection: {},
                    toDisplay: [
                        {
                            name: 'global',
                            label: 'Geral:',
                            type: 'global',
                            matchMode: FilterMatchMode.CONTAINS,
                            inputPlaceholder: 'Localizar por coincidência'
                        },
                        {
                            name: 'collaborators',
                            label: 'Colaborador:',
                            type: 'multi-select-dynamic-api',
                            multiSelectShowColumns: ['name', 'tx_id'],
                            multiselectDisplayFieldName: 'person.name',
                            searchApi: new CollaboratorRouter(),
                            searchApiPropertyName: 'collaborators',
                            inputPlaceholder: 'Selecione um ou mais colaboradores',
                            lazyLoadTextHeader: 'Selecione um ou mais colaboradores',
                            dataKey: 'ID',
                            showSearch: true
                        },
                        {
                            name: 'assessmentMetricTypes',
                            label: 'Tipo de métrica:',
                            type: 'multi-select-dynamic-api',
                            multiSelectShowColumns: ['name', 'description'],
                            multiselectDisplayFieldName: 'name',
                            searchApi: new AssessmentMetricTypeRouter(),
                            searchApiPropertyName: 'assessmentMetricTypes',
                            inputPlaceholder: 'Selecione um ou mais tipos de métricas',
                            lazyLoadTextHeader: 'Selecione um ou mais tipos de métricas',
                            dataKey: 'ID',
                            showSearch: true
                        },
                        {
                            name: 'assessmentMetricGroups',
                            label: 'Grupo de métrica:',
                            type: 'multi-select-dynamic-api',
                            multiSelectShowColumns: ['name', 'description'],
                            multiselectDisplayFieldName: 'name',
                            searchApi: new AssessmentMetricGroupRouter(),
                            searchApiPropertyName: 'assessmentMetricGroups',
                            inputPlaceholder: 'Selecione um ou mais grupos de métricas',
                            lazyLoadTextHeader: 'Selecione um ou mais grupos de métricas',
                            dataKey: 'ID',
                            showSearch: true
                        },
                        {
                            name: 'assessmentStatuses',
                            label: 'Status',
                            type: 'multi-select-static',
                            inputPlaceholder: 'Selecione um ou mais status',
                            dataKey: 'value',
                            options: [
                                { label: 'Em preenchimento', value: 'filling' },
                                { label: 'Finalizado', value: 'finished' },
                                { label: 'Pausado', value: 'paused' },
                                { label: 'Cancelado', value: 'cancelled' }
                            ]
                        },
                        {
                            name: 'dateRange',
                            label: 'Referência (Inicio - Fim)',
                            type: 'calendar',
                            selectionMode: 'range',
                            inputPlaceholder: 'Selecione uma data de referência inicial'
                        }
                    ]
                }
            };
        },
        created() {
            this.initializeFilters();
        },
        async mounted() {
            this.pageRows = this.$refs.dt.rows;
            this.loadAssessments();
        },
        methods: {
            /**
             * @function initForm
             * @description Initialize default assessment.preRegistrationForm data
             * @returns {Object} - The assessment.preRegistrationForm data initialized
             */
            initForm() {
                return {
                    assessmentTemplateID: null,
                    evaluatedCollaboratorID: null,
                    title: null,
                    description: null,
                    referenceBeginningDate: null,
                    referenceEndDate: null
                };
            },
            /**
             * @function loadAssessments
             * @description Fetched and updates assessments  data from the API
             * @returns {Promise<void>} Resolves onces the assessments have been successfully loaded
             */
            async loadAssessments() {
                this.assessments.loading = true;
                try {
                    this.pagination['filters']['exceptTemplates'] = true; //Inject on all filter pagination
                    const jsonData = JSON.stringify(this.pagination);
                    const { assessments, totalRecords } = await this.apis.assessment.Search(jsonData);
                    this.assessments.records = assessments;
                    this.assessments.totalRecords = totalRecords;
                } catch (err) {
                    console.error('Error loading assessments: ', err);
                } finally {
                    this.assessments.loading = false;
                }
            },
            /**
             * @function displayAssessmentDetailsSidebar
             * @description Opens the sidebar for display the assessment details
             * @param {Object} slotData - The assessment object to display the data
             */
            async displayAssessmentDetailsSidebar(slotData) {
                if (!slotData || slotData == null) return;
                this.assessmentDetailsSidebar.display = true;
                this.assessmentDetailsSidebar.loading = true;
                try {
                    const { assessment } = await this.getByID(slotData.ID);
                    const { assessmentMetrics } = await this.getAllAssessmentMetrics(slotData.ID);
                    this.assessmentDetailsSidebar.assessment = { ...assessment };
                    this.assessmentDetailsSidebar.assessmentMetrics = [...assessmentMetrics];
                    this.scoreCalculation(slotData.ID);
                } catch (err) {
                    this.assessmentDetailsSidebar.error = err;
                } finally {
                    this.assessmentDetailsSidebar.loading = false;
                }

                this.handleEscKey(this.assessmentDetailsCloseSidebar);
            },
            /**
             * @function displayNewAssessment
             * @description Opens the sidebar metrics or pre registration modal for fill new assessment details
             * @param {Boolean} [prefillModal = false] - If true show only pre registration modal
             */
            displayNewAssessment(preRegistrationModal = false) {
                if (preRegistrationModal) {
                    this.assessment.preRegistrationModal.display = true;
                    this.assessment.sidebarMetrics.display = false;
                    return;
                }

                this.assessment.preRegistrationModal.display = false;
                this.assessment.sidebarMetrics.display = true;
            },
            /**
             * @function resetNewAssessment
             * @description Reset new assessment object
             */
            resetNewAssessment() {
                this.assessment.preRegistrationForm = this.initForm();
                return;
            },
            /**
             * @function handleEscKey
             * @description Handle escape key down and run function
             * @param {Function} fnCallback - The function callback on espace key pressed
             */
            handleEscKey(fnCallback) {
                document.addEventListener('keydown', function (event) {
                    if (event.key == 'Escape' || event.code == 27) {
                        fnCallback();
                    }
                });
            },
            /**
             * @async
             * @function getAllAssessmentMetrics
             * @description Load all assessment metrics from assessment ID
             * @param {number} id - The ID of resource to get data
             */
            async getAllAssessmentMetrics(id) {
                if (!id || id == null) return;
                return await this.apis.assessmentMetric.FindAllByAssessmentID(id);
            },

            /**
             * @async
             * @function getByID
             * @description Load full assessment data from ID
             * @param {number} id - The ID of resource to get data
             */
            async getByID(id) {
                if (!id || id == null) return;
                return await this.apis.assessment.FirstByID(id);
            },
            /**
             * @function confirmDeleteAssessments
             * @description Opens a confirmation dialog to delete the selected assessments
             */
            confirmDeleteAssessments() {
                if (this.assessments.selection?.length == 0) {
                    this.$toast.add({
                        severity: 'warning',
                        summary: 'Atenção',
                        detail: 'Você precisa selecionar ao menos 1 item para exclusão',
                        life: 3000
                    });
                    return;
                }

                this.$confirm.require({
                    message: `Você esta prestes a excluir ${this.assessments.selection?.length} registros. Deseja continuar?`,
                    header: 'Confirmar exclusão',
                    icon: 'far fa-question-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    accept: async () => {
                        const idsToRemove = this.assessments.selection?.map((assessment) => assessment.ID);
                        this.deleteItems(idsToRemove);
                    }
                });
            },
            /**
             * @async
             * @function deleteItems
             * @description Delete assessments bu their IDs
             * @param {Array<number>} ids - Array of assessments IDs to delete
             */
            async deleteItems(ids) {
                if (!ids || ids?.length == 0) return;
                try {
                    await this.apis.assessment.Remove(ids);
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Sucesso',
                        detail: 'Item(s) removido(s) com sucesso',
                        life: 3000
                    });
                } catch (err) {
                    console.error(err);
                } finally {
                    this.loadAssessments();
                }
            },
            /**
             * @function onFilter
             * @description Applies selected filters and reloads the assessments data
             */
            onFilter() {
                const { selection } = this.filter || {};
                if (selection && Object.keys(selection).length > 0) {
                    this.hasFilters = true;
                    this.filters.global = { ...selection };

                    if (selection.global) {
                        this.filters.global = selection.global;
                    }
                } else {
                    this.hasFilters = false;
                    this.filters.global = {};
                }

                this.pagination.filters = this.filters;
                this.loadAssessments();
            },
            /**
             * @function onPage
             * @description Handles pagination events
             * @param {Object} event - Event object containing pagination data
             */
            onPage(event) {
                this.pagination = event;
                this.loadAssessments();
            },
            /**
             * @function initializeFilters
             * @description Sets up the initial filter and pagination configuration
             */
            initializeFilters() {
                this.filters = {
                    global: {
                        value: '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                };

                this.pagination = {
                    page: 1,
                    rows: this.pageRows,
                    filters: this.filters
                };
            },
            /**
             * @function mountFilter
             * @description Mount the filter from filter object
             * @param {Any} ftl - The filter object to mount
             */
            mountFilter(ftl) {
                const { dataKey, name, value } = ftl;

                if (Array.isArray(value)) {
                    this.filter.selection[name] = value.map((item) => item?.[dataKey] || item);
                    return;
                }

                if (value && typeof value === 'object' && Object.keys(value).length > 0) {
                    this.filter.selection[name] = value?.[dataKey] || value;
                    return;
                }

                if (value === null || value === undefined || value === '') {
                    delete this.filter.selection[name];
                    return;
                }

                this.filter.selection[name] = value;
            },

            /**
             * @function showFilterModal
             * @description Display the filter modal
             */
            showFilterModal() {
                this.filter.display = false;
            },
            /**
             * @function clearAllFilters
             * @description Clear all filters
             */
            clearAllFilters() {
                this.filter.selection = {};
                this.initializeFilters();
                this.hasFilters = false;
                this.onFilter();
            },
            /**
             * @function filterSearch
             * @description Closes the filter modal and call onFilter function
             */
            filterSearch() {
                this.filter.display = false;
                this.onFilter();
            },
            /**
             * @function incrementZoom
             * @description Increment zoom scale
             */
            incrementZoom() {
                this.zoomLevel += 0.01;
                this.transformScale();
            },
            /**
             * @function decrementZoom
             * @description Decrement zoom scale
             */
            decrementZoom() {
                this.zoomLevel -= 0.01;
                this.transformScale();
            },
            /**
             * @function resetZoom
             * @description Reset zoom scale
             */
            resetZoom() {
                this.zoomLevel = 1;
                this.transformScale();
            },
            /**
             * @function transformScale
             * @description update zoom scale
             */
            transformScale() {
                document.body.style.transform = `scale(${this.zoomLevel})`;
                document.body.style.transformOrigin = '0 0';
            },
            /**
             * @function assessmentDetailsCloseSidebar
             * @description Closes the sidebar of assessment details
             */
            assessmentDetailsCloseSidebar() {
                this.assessmentDetailsSidebar.display = false;
                this.resetZoom();
                document.removeEventListener('keydown');
            },
            /**
             * @function exportAssessmentToCSV
             * @description Exports the assessment details to CSV from datatable
             */
            exportAssessmentToCSV() {
                this.$refs.assessmentDt.exportCSV();
            },
            /**
             * Controls input actions for the assessment.preRegistrationForm(set, clear, or remove).
             * @param {string} entity - The assessment.preRegistrationForm entity to modify.
             * @param {string} action - The action to perform (set, clear, or remove).
             * @param {object|null} [event=null] - The event data to apply to the assessment.preRegistrationForm entity.
             */
            inputController(entity, action, event = null) {
                if (!this.assessment.preRegistrationForm[entity]) {
                    this.assessment.preRegistrationForm[entity] = Array.isArray(event) ? [] : {};
                }

                switch (action) {
                    case 'set': {
                        if (!event || typeof event !== 'object') {
                            console.error(`Invalid event data for setting entity ${entity}.`);
                            return;
                        }

                        this.assessment.preRegistrationForm[entity] = event;

                        break;
                    }
                    case 'clear': {
                        if (typeof this.assessment.preRegistrationForm[entity] === 'object') {
                            this.assessment.preRegistrationForm[entity] = null;
                        } else {
                            console.error(`Cannot clear entity ${entity} with type ${typeof this.assessment.preRegistrationForm[entity]}.`);
                        }
                        break;
                    }
                    case 'remove': {
                        if (!Array.isArray(this.assessment.preRegistrationForm[entity])) {
                            console.error(`Cannot remove item from non-array entity ${entity}.`);
                            return;
                        }
                        if (event && event.ID) {
                            this.assessment.preRegistrationForm[entity] = this.assessment.preRegistrationForm[entity].filter((item) => item.ID !== event.ID);
                        } else {
                            console.error('Event must contain an ID for removal.');
                        }
                        break;
                    }
                    default: {
                        console.error(`Invalid action: ${action}`);
                    }
                }
            },
            /**
             * @async
             * @function pauseAssessmentFilling
             * @description Pause new assessment fill progress and close the sidebar
             */
            async pauseAssessmentFilling() {
                this.assessment.loading = true;

                try {
                    const assessmentID = this.assessment.metricsFillingForm?.ID;

                    if (!assessmentID) throw new Error('Unable to get the identifier of assessment object');

                    await this.apis.assessment.Pause(assessmentID);

                    this.$toast.add({
                        severity: 'success',
                        summary: 'Avaliação pausada',
                        detail: 'A avaliação foi pausada com sucesso. Você pode retomá-la no menu "Avaliações".',
                        life: 3000
                    });
                } catch (error) {
                    const errorMessage = error.response?.data?.message || error.message || 'Erro desconhecido';

                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro ao pausar avaliação',
                        detail: `Ocorreu um erro ao tentar pausar a avaliação: ${errorMessage}. Consulte o console para mais informações.`,
                        life: 3000
                    });

                    console.error('Error while pausing the assessment:', error);
                } finally {
                    this.assessment.loading = false;
                    this.assessment.sidebarMetrics.display = false;
                    this.loadAssessments();
                }
            },
            /**
             * @async
             * @function resumeAssessmentFilling
             * @description Resume the assessment filling
             */
            async resumeAssessmentFilling(slot) {
                this.$confirm.require({
                    message: 'O status desta avaliação mudará para "Em preenchimento". Deseja continuar?',
                    header: `Continuar preenchimento da avaliação`,
                    accept: async () => {
                        this.displayNewAssessment();
                        this.assessment.sidebarMetrics.scoreResultsStep = false;
                        this.assessment.loading = true;
                        this.assessment.error = null;
                        try {
                            const { assessment } = await this.apis.assessment.Resume(slot.ID);
                            this.assessment.metricsFillingForm = { ...assessment };
                        } catch (error) {
                            const errorMessage = error.response?.data?.message || error.message || 'Erro desconhecido';

                            this.$toast.add({
                                severity: 'error',
                                summary: 'Erro ao resumir avaliação',
                                detail: `Ocorreu um erro ao tentar resumir a avaliação: ${errorMessage}. Consulte o console para mais informações.`,
                                life: 3000
                            });

                            console.error('Error while resume the assessment:', error);
                            this.assessment.error = error;
                        } finally {
                            this.assessment.loading = false;
                        }
                    }
                });
            },

            /**
             * @async
             * @function formInputSave
             * @description Saves the input or comment for a metric, with optional debouncing.
             * @param {number} metricID - Unique identifier of the metric.
             * @param {any} responseValue - The response value to be saved.
             * @param {any} comments - The comments of the metric (optional).
             */
            async formInputSave(metricID, responseValue, comments = '') {
                this.assessment.formSavingProgress = true;

                try {
                    await this.apis.assessmentMetric.FormSave(metricID, responseValue, comments);
                } catch (error) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro',
                        detail: 'Erro ao salvar a métrica. Verifique o console para mais detalhes.',
                        life: 3000
                    });
                    console.error(`Unable to save metric form data (Metric ID: ${metricID}):`, error);
                } finally {
                    setTimeout(() => {
                        this.assessment.formSavingProgress = false;
                    }, 500);
                }
            },
            /**
             * @async
             * @function finishAssessmentFilling
             * @description Finish the assessment filling and show score result
             */
            async finishAssessmentFilling() {
                this.assessment.sidebarMetrics.display = false;

                this.$confirm.require({
                    header: `Finalizar ${this.assessment?.metricsFillingForm?.title}`,
                    message: `Ao finalizar o preenchimento das métricas, não será possível editá-las novamente. Deseja continuar?`,
                    icon: 'fas fa-info-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Voltar',
                    reject: () => {
                        this.displayNewAssessment();
                    },
                    accept: async () => {
                        this.assessment.loading = true;
                        this.displayNewAssessment();

                        try {
                            const assessmentID = this.assessment.metricsFillingForm?.ID;

                            if (!assessmentID) {
                                throw new Error('Assessment ID not found.');
                            }

                            const { groupedScoreResult } = await this.apis.assessment.Finish(assessmentID);
                            this.assessment.groupedScoreResult = groupedScoreResult;

                            this.assessment.sidebarMetrics.scoreResultsStep = true;

                            setTimeout(() => {
                                const sidebar = document.querySelector('.p-sidebar-content');
                                sidebar.scrollTo(0, 0);
                                setTimeout(() => {
                                    sidebar.scrollTo({
                                        top: sidebar.scrollHeight,
                                        behavior: 'smooth'
                                    });
                                }, 2500);
                            }, 50);
                        } catch (error) {
                            const errorMessage = error.response?.data?.message || error.message || 'Erro desconhecido';

                            let message = `Ocorreu um erro ao tentar finalizar a avaliação: ${errorMessage}. Consulte o console para mais informações.`;
                            let header = 'Erro ao finalizar avaliação';
                            let severity = 'error';

                            if (errorMessage.includes('422')) {
                                severity = 'warn';
                                header = 'Métricas obrigatórias não preenchidas';
                                message = 'Há uma ou mais métricas obrigatórias que não foram preenchidas corretamente. Verifique as métricas do formulário e tente novamente.';
                            }

                            this.$toast.add({
                                severity: severity,
                                summary: header,
                                detail: message,
                                life: 5000
                            });
                        } finally {
                            this.assessment.loading = false;
                            this.loadAssessments();
                        }
                    }
                });
            },

            /**
             * @async
             * @function cancelAndDiscardAssessment
             * @description Cancel new assessment fill and close the sidebar
             */
            cancelAndDiscardAssessment() {
                this.assessment.sidebarMetrics.display = false;

                this.$confirm.require({
                    message: `Ao confirmar esta ação ela não poderá ser desfeita, deseja continuar?`,
                    header: `Cancelar ${this.assessment?.metricsFillingForm?.title}`,
                    icon: 'fas fa-info-circle',
                    acceptLabel: 'Sim',
                    rejectLabel: 'Não',
                    reject: () => this.displayNewAssessment(),
                    accept: async () => {
                        this.assessment.loading = true;

                        try {
                            const assessmentID = this.assessment.metricsFillingForm?.ID;
                            if (!assessmentID) throw new Error('Unable to get the identifier of assessment object');

                            await this.apis.assessment.Cancel(assessmentID);

                            this.$toast.add({
                                severity: 'success',
                                summary: 'Avaliação cancelada',
                                detail: 'A avaliação foi cancelada com sucesso. Não será mas possível retomar o preenchimento da mesma',
                                life: 3000
                            });
                        } catch (error) {
                            const errorMessage = error.response?.data?.message || error.message || 'Erro desconhecido';

                            this.$toast.add({
                                severity: 'error',
                                summary: 'Erro ao cancelar avaliação',
                                detail: `Ocorreu um erro ao tentar cancelar a avaliação: ${errorMessage}. Consulte o console para mais informações.`,
                                life: 3000
                            });

                            console.error('Erro while cancel the assessment:', error);
                        } finally {
                            this.assessment.loading = false;
                            this.assessment.sidebarMetrics.display = false;
                            this.loadAssessments();
                        }
                    }
                });
            },
            /**
             * @async
             * @function saveNewAssessment
             * @description Save new assessment
             * @returns {Object} - The new assessment complete data received from server
             */
            async saveNewAssessment() {
                this.assessment.loading = true;
                this.assessment.sidebarMetrics.scoreResultsStep = false;
                this.displayNewAssessment();
                try {
                    const payload = this.getNewAssessmentPayload();
                    const jsonPayload = JSON.stringify(payload);
                    const { assessment } = await this.apis.assessment.New(jsonPayload);
                    this.assessment.metricsFillingForm = { ...assessment };
                } catch (err) {
                    console.error('Error sending new assessment: ', err);
                } finally {
                    this.assessment.loading = false;
                    this.assessment.preRegistrationModal.display = false;
                }
            },
            /**
             * @function getNewAssessmentPayload
             * @description Format the assessment payload data
             * @returns {Object} - The assessment payload data
             */
            getNewAssessmentPayload() {
                const {
                    assessmentTemplate: { ID: assessmentTemplateID },
                    collaborator: { ID: collaboratorID },
                    title,
                    description,
                    referenceDate
                } = this.assessment.preRegistrationForm;

                return {
                    assessmentTemplateID: assessmentTemplateID,
                    evaluatedCollaboratorID: collaboratorID,
                    title: title,
                    description: description,
                    referenceBeginningDate: referenceDate?.length == 2 ? moment(referenceDate[0]).format('YYYY-MM-DD') : null,
                    referenceEndDate: referenceDate?.length == 2 ? moment(referenceDate[1]).format('YYYY-MM-DD') : null
                };
            },
            /**
             * @function dtAssessmentRowClass
             * @description Returns a class to row if the assessment is in filling state
             */
            dtAssessmentRowClass(data) {
                return this.isAssessmentInFilling(data) ? 'row-assessment-filling' : null;
            },
            /**
             * @async
             * @function scoreCalculation
             * @description Returns a grouped score calculation
             * @param {number} assessmentID - The assessment resource identification
             */
            async scoreCalculation(assessmentID) {
                try {
                    const {
                        groupedScoreResult: { scoreByAssessmentMetricGroup }
                    } = await this.apis.assessment.ScoreCalculation(assessmentID);

                    this.assessmentDetailsSidebar.scoreByAssessmentMetricGroup = [...scoreByAssessmentMetricGroup];
                    this.assessmentDetailsSidebar.totalScore = scoreByAssessmentMetricGroup?.reduce((total, item) => total + item.totalScore, 0);
                } catch (error) {
                    console.error(`Erro ao calcular score para a avaliação ID ${assessmentID}:`, error);

                    this.$toast.add({
                        severity: 'error',
                        summary: 'Falha ao calcular score',
                        detail: 'Ocorreu um erro ao tentar calcular o score da avaliação. Verifique o console para mais detalhes.',
                        life: 3000
                    });
                }
            }
        },
        computed: {
            assessmentDetailHeaderLogo() {
                return this.$appState.darkTheme ? 'images/logo-dark.svg' : 'images/logo.svg';
            },
            validateNewAssessmentForm() {
                return (
                    Boolean(this.assessment.preRegistrationForm?.title?.trim()?.length > 0) &&
                    Boolean(this.assessment.preRegistrationForm?.collaborator?.ID > 0) &&
                    Boolean(this.assessment.preRegistrationForm?.assessmentTemplate?.ID > 0)
                );
            },
            groupedMetrics() {
                const groups = {};

                this.assessment?.metricsFillingForm?.assessmentMetrics?.forEach((item) => {
                    const name = `${item.assessmentMetricType.name}s`;
                    if (!groups[name]) {
                        groups[name] = [];
                    }
                    groups[name].push(item);
                });

                return groups;
            },
            groupByMetricGroup() {
                return (metrics) => {
                    const groupMap = {};
                    metrics.forEach((metric) => {
                        const groupName = metric.assessmentMetricGroup.name;
                        if (!groupMap[groupName]) {
                            groupMap[groupName] = [];
                        }
                        groupMap[groupName].push(metric);
                    });
                    return groupMap;
                };
            },
            isAssessmentCancelled() {
                return (assessment) => assessment.status === this.assessmentStatusCodes.CANCELLED;
            },
            isAssessmentInFilling() {
                return (assessment) => assessment.status === this.assessmentStatusCodes.FILLING;
            },
            isAssessmentFinished() {
                return (assessment) => assessment.status === this.assessmentStatusCodes.FINISHED;
            },
            isAssessmentPaused() {
                return (assessment) => assessment.status === this.assessmentStatusCodes.PAUSED;
            }
        }
    });
</script>

<template>
    <Card>
        <!-- Title Section -->
        <template #title>
            <span class="font-light text-700 text-xl">Avaliações</span>
        </template>

        <!-- Toolbar Section -->
        <template #content>
            <Toolbar class="h-6rem">
                <!-- Start Buttons (Add, Delete) -->
                <template #start>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-plus"
                            class="p-button-rounded p-button-success p-button-outlined p-button-raised"
                            title="Nova avaliação"
                            @click="displayNewAssessment"
                            :disabled="loading" />
                        <Button
                            icon="fas fa-trash"
                            title="Excluir um ou mais itens"
                            class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
                            @click="confirmDeleteAssessments"
                            :disabled="!assessments.selection.length" />
                    </div>
                </template>

                <!-- End Buttons (Refresh, Filter) -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button
                            icon="fas fa-redo-alt"
                            class="p-button-rounded p-button-info p-button-outlined p-button-raised"
                            title="Atualizar lista"
                            @click="loadAssessments"
                            :disabled="assessments.loading" />
                        <div class="relative">
                            <Button
                                icon="fas fa-filter"
                                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                                @click="filter.display = true"
                                :disabled="assessments.loading"
                                title="Escolher filtros" />
                            <Button
                                v-if="hasFilters"
                                icon="fas fa-broom"
                                class="p-button-rounded p-button-raised clear-filter"
                                title="Limpar filtros"
                                @click="clearAllFilters"
                                :disabled="assessments.loading" />
                        </div>
                    </div>
                </template>
            </Toolbar>
        </template>

        <!-- Data Table Section -->
        <template #footer>
            <DataTable
                lazy
                ref="dt"
                dataKey="ID"
                class="p-datatable-sm"
                responsiveLayout="scroll"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} registros"
                v-model:selection="assessments.selection"
                v-model:filters="filters"
                @filter="onFilter"
                @page="onPage"
                :rowClass="dtAssessmentRowClass"
                :value="assessments.records"
                :paginator="true"
                :loading="assessments.loading"
                :totalRecords="assessments.totalRecords"
                :rows="10"
                :rowsPerPageOptions="[10, 25, 50]">
                <!-- Columns -->
                <Column selectionMode="multiple" headerStyle="width:.2%; min-width:1rem;" />
                <Column field="ID" header="ID" headerStyle="width:.40%; min-width:2rem;" />
                <Column field="evaluatedCollaborator.person.name" header="Colaborador" headerStyle="width:6%; min-width:2rem;" />
                <Column field="startedByPerson.name" header="Iniciado por" headerStyle="width:6%; min-width:2rem;" />
                <Column header="Data de referência" headerStyle="width:6%; min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-start">
                            <span v-if="slotProps.data?.referenceBeginningDate && slotProps.data?.referenceBeginningDate"
                                >{{ slotProps.data?.referenceBeginningDate }} - {{ slotProps.data?.referenceEndDate }}</span
                            >
                        </div>
                    </template>
                </Column>
                <Column field="startedAt" header="Iniciado em" headerStyle="width:4%; min-width:2rem;" />
                <Column field="finishedAt" header="Finalizado em" headerStyle="width:4%; min-width:2rem;" />
                <Column header="Status" headerStyle="width:3%; min-width:1rem;">
                    <template #body="slotProps">
                        <div class="flex justify-content-start">
                            <span class="font-bold" style="color: red" v-if="isAssessmentCancelled(slotProps.data)">Cancelada</span>
                            <span class="font-bold text-orange-500" v-else-if="isAssessmentPaused(slotProps.data)">Pausada</span>
                            <span class="font-bold text-primary" v-else-if="isAssessmentInFilling(slotProps.data)">Em preenchimento</span>
                            <span class="font-bold text-green-500" v-else-if="isAssessmentFinished(slotProps.data)">Finalizado</span>
                            <span class="font-bold" style="color: red" v-else>Desconhecido</span>
                        </div>
                    </template>
                </Column>
                <Column field="title" header="Título" headerStyle="width:6%; min-width:2rem;" />
                <!-- <Column field="description" header="Descrição" headerStyle="width:6%; min-width:2rem;" /> -->
                <Column headerStyle="width: 2%; min-width:2rem;">
                    <template #body="slotProps">
                        <div class="flex" style="gap: 5px">
                            <Button
                                icon="fa-solid fa-file-lines"
                                class="p-button-outlined p-button-rounded p-button-sm p-button-info p-button-raised action-button"
                                title="Relatório de performance"
                                @click="displayAssessmentDetailsSidebar(slotProps.data)"
                                :disabled="slotProps.data?.status !== assessmentStatusCodes.FINISHED" />
                            <Button
                                icon="fa-solid fa-play"
                                class="p-button-outlined p-button-rounded p-button-sm p-button-success p-button-raised action-button"
                                title="Retomar preenchimento da avaliação"
                                @click="resumeAssessmentFilling(slotProps.data)"
                                :disabled="slotProps.data?.status !== assessmentStatusCodes.PAUSED && slotProps.data?.status !== assessmentStatusCodes.FILLING" />
                        </div>
                    </template>
                </Column>
            </DataTable>
        </template>
    </Card>

    <!-- Dialog for filter -->
    <Dialog v-model:visible="filter.display" header="Filtro avançado" :modal="true" :breakpoints="{ '960px': '75vw', '640px': '90vw' }" :style="{ width: '30vw' }" :maximizable="false">
        <advanced-filter @applied-filters="mountFilter($event)" :filters-to-display="filter.toDisplay" :selected-filters="filter.selection" />
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <Button label="Pesquisar" iconPos="left" icon="pi pi-search" autofocus @click="filterSearch" class="w-10rem" />
                <Button label="Cancelar" iconPos="left" icon="pi pi-times" autofocus @click="filter.display = false" class="p-button-outlined p-button-danger w-10rem" />
            </div>
        </template>
    </Dialog>

    <!-- Dialog for New Assessment -->
    <Dialog
        v-model:visible="assessment.preRegistrationModal.display"
        :header="!assessment.loading ? 'Nova avaliação' : 'Salvando informações...'"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '35vw' }"
        :maximizable="true"
        @hide="resetNewAssessment(true)">
        <progress-template v-if="assessment.loading" />
        <div class="p-input-filled p-button-filled" v-else>
            <!-- Collaborator Selection -->
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="evaluatedCollaborator">Colaborador avaliado:</label>
                </div>
                <input-options
                    name="evaluatedCollaborator"
                    @selection="inputController('collaborator', 'set', $event)"
                    @cleared="inputController('collaborator', 'clear')"
                    :inputCurrentSelectionValue="assessment.preRegistrationForm.collaborator?.person?.name"
                    :currentSelection="assessment.preRegistrationForm.collaborator"
                    :multiselect="false"
                    :showColumns="['id', 'name', 'tx_id']"
                    :searchAPI="apis.collaborator"
                    :apiPropertyName="'collaborators'"
                    :showSearch="true"
                    :inputPlaceholder="'Selecione o colaborador a ser avaliado (Obrigatório)'"
                    :showClear="assessment.preRegistrationForm.collaborator?.ID > 0"
                    :enableConfirmationButton="assessment.preRegistrationForm.collaborator?.ID > 0"
                    :lazyLoadDialogMaximizable="true"
                    :lazyLoadTextHeader="'Selecione o colaborador'" />
            </div>

            <!-- Assessment Template Selection -->
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="assessmentTemplateModel">Modelo avaliativo:</label>
                </div>
                <input-options
                    name="assessmentTemplateModel"
                    @selection="inputController('assessmentTemplate', 'set', $event)"
                    @cleared="inputController('assessmentTemplate', 'clear')"
                    :inputCurrentSelectionValue="assessment.preRegistrationForm.assessmentTemplate?.title"
                    :currentSelection="assessment.preRegistrationForm.assessmentTemplate"
                    :multiselect="false"
                    :showColumns="['id', 'title', 'description']"
                    :searchAPI="apis.assessment"
                    :apiPropertyName="'assessments'"
                    :showSearch="true"
                    :inputPlaceholder="'Selecione o modelo avaliativo (Obrigatório)'"
                    :showClear="assessment.preRegistrationForm.assessmentTemplate?.ID > 0"
                    :enableConfirmationButton="assessment.preRegistrationForm.assessmentTemplate?.ID > 0"
                    :lazyLoadDialogMaximizable="true"
                    :lazyLoadTextHeader="'Selecione o modelo avaliativo'"
                    :additionalFilters="this.assessmentTemplateAdditionalFilters" />
            </div>

            <!-- Title Input -->
            <div class="flex flex-column field">
                <div class="flex flex-row mb-2" style="gap: 5px">
                    <span class="text-bold text-orange-500">*</span>
                    <label for="title">Título:</label>
                </div>
                <InputText
                    id="title"
                    autocomplete="off"
                    v-model="assessment.preRegistrationForm.title"
                    placeholder="Titulo da avaliação (Obrigatório)"
                    class="w-full border-transparent"
                    @input="validateNewAssessmentForm" />
            </div>

            <!-- Description Input -->
            <div class="flex flex-column field">
                <label for="description">Descrição:</label>
                <InputText id="description" autocomplete="off" v-model="assessment.preRegistrationForm.description" placeholder="Descrição da avaliação" class="w-full border-transparent" />
            </div>

            <!-- Reference Date Selection -->
            <div class="flex flex-column field">
                <label for="referenceDate">Data referência:</label>
                <Calendar
                    name="referenceDate"
                    dateFormat="dd/mm/yy"
                    v-model="assessment.preRegistrationForm.referenceDate"
                    selectionMode="range"
                    :manualInput="false"
                    :showButtonBar="true"
                    :showIcon="true" />
            </div>
        </div>

        <!-- Dialog Footer -->
        <template #footer v-if="!assessment.loading">
            <div class="flex flex-row justify-content-end">
                <Button label="Iniciar" iconPos="left" icon="fas fa-play" autofocus :disabled="!validateNewAssessmentForm" class="w-10rem p-button-success" @click="saveNewAssessment" />
                <Button autofocus label="Cancelar" iconPos="left" icon="pi pi-times" class="p-button-outlined p-button-danger w-10rem" @click="this.assessment.preRegistrationModal.display = false" />
            </div>
        </template>
    </Dialog>
    <!-- Sidebar for New Assessment -->
    <Sidebar v-model:visible="assessment.sidebarMetrics.display" :baseZIndex="1000" position="full" :showCloseIcon="false">
        <!-- Loading and Error States -->
        <div v-if="assessment.loading && !assessment.error" class="flex flex-column justify-content-center align-items-center w-full h-screen" style="gap: 10px">
            <progress-template />
            <span class="text-2xl font-bold">Aguarde, estamos carregando algumas informações...</span>
        </div>
        <div class="flex flex-column justify-content-center align-items-center w-full h-screen" style="gap: 10px" v-else-if="!assessment.loading && assessment.error">
            <lottie :lottieProp="this.lottieError" />
            <span class="text-3xl font-bold">Algumas coisas não saíram como esperado, estamos com dificuldade para carregar as informações</span>
            <span class="text-2xl font-bold">Por favor acione o S.O.G.E para verificação</span>
            <span class="text-xl font-bold"
                >Informações Técnicas: <span class="text-orange-600">{{ assessment.error }}</span></span
            >
            <Button class="p-button-lg" @click="assessment.sidebarMetrics.display = false" label="Voltar" />
        </div>
        <div class="flex flex-column fadein animation-duration-1000" style="gap: 20px" v-else-if="!assessment.sidebarMetrics.scoreResultsStep">
            <Toolbar class="h-8rem">
                <!-- New Assessment Sidebar Start-->
                <template #start>
                    <div class="flex flex-column" style="gap: 5px">
                        <span class="font-bold text-xl">{{ assessment.metricsFillingForm?.title ?? 'Nova avaliação' }}</span>
                        <div class="flex" style="gap: 5px">
                            <span class="font-bold text-lg">Modelo avaliativo:</span>
                            <span class="text-lg">{{ assessment.metricsFillingForm?.assessmentTemplate?.title }}</span>
                        </div>
                        <div class="flex flex-row justify-content-start align-items-center text-blue-700" style="gap: 5px" v-if="assessment.formSavingProgress">
                            <i class="pi pi-spin pi-spinner text-2xl" />
                            <span>Salvando informações...</span>
                        </div>
                    </div>
                </template>
                <!-- New Assessment Sidebar End -->
                <template #end>
                    <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 5px">
                        <Button icon="fas fa-times" class="p-button-rounded p-button-danger p-button-outlined p-button-raised" title="Cancelar avaliação" @click="cancelAndDiscardAssessment" />
                        <Button icon="fas fa-pause" class="p-button-rounded p-button-warning p-button-outlined p-button-raised" title="Pausar avaliação" @click="pauseAssessmentFilling" />
                        <Button icon="fas fa-check" class="p-button-rounded p-button-success p-button-outlined p-button-raised" title="Finalizar avaliação" @click="finishAssessmentFilling" />
                    </div>
                </template>
            </Toolbar>

            <!-- New Assessment Sidebar Content-->
            <Card>
                <template #title>
                    <div class="flex flex-column" style="gap: 5px">
                        <span class="font-bold text-800 text-xl">Formulário de métricas avaliativas</span>
                        <span class="font-light text-600 text-lg">Preencha cada métrica de acordo com os pilares das competências do método CHA</span>
                    </div>
                </template>
                <template #content>
                    <TabView>
                        <TabPanel v-for="(metrics, code) in groupedMetrics" v-bind:key="code" :header="code">
                            <Accordion v-for="(groupMetrics, groupName) in groupByMetricGroup(metrics)" v-bind:key="groupName">
                                <AccordionTab :header="groupName">
                                    <div class="flex flex-column">
                                        <div v-for="(metric, index) in groupMetrics" v-bind:key="index">
                                            <div class="mt-2 min-h-12rem">
                                                <Card style="min-height: 250px">
                                                    <template #title>
                                                        <span class="text-800">{{ metric.title }}</span>
                                                    </template>
                                                    <template #subtitle>
                                                        <div class="flex flex-column" style="gap: 10px">
                                                            <span class="text-600">{{ metric.description }}</span>
                                                            <div v-if="metric?.settings?.required" class="flex flex-row align-items-center justify-content-start p-error" style="gap: 5px">
                                                                <i class="fas fa-info-circle" />
                                                                <span>O preenchimento da métrica é obrigatório</span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <template #content>
                                                        <div class="flex p-fluid" style="gap: 15px">
                                                            <div class="flex flex-column w-full" style="gap: 5px">
                                                                <label>Resposta:</label>
                                                                <div v-if="metric.assessmentMetricType.code === assessmentMetricTypeCodes.QUANTITATIVE">
                                                                    <InputText
                                                                        id="responseValueText"
                                                                        v-model="metric.responseValue.text"
                                                                        autocomplete="off"
                                                                        @blur="formInputSave(metric.ID, metric.responseValue, metric.evaluatorComments)" />
                                                                    <small v-show="metric?.settings?.required && metric.responseValue?.text?.trim()?.length == 0" class="p-error"
                                                                        >Campo obrigatório.</small
                                                                    >
                                                                </div>
                                                                <div v-if="metric.assessmentMetricType.code === assessmentMetricTypeCodes.QUALITATIVE">
                                                                    <Dropdown
                                                                        id="responseValueNumber"
                                                                        v-model="metric.responseValue.number"
                                                                        optionLabel="label"
                                                                        optionValue="value"
                                                                        :options="metric.assessmentMetric.assessmentMetricOptions"
                                                                        @change="formInputSave(metric.ID, metric.responseValue, metric.evaluatorComments)" />
                                                                    <small v-show="metric?.settings?.required && metric.responseValue?.number == null" class="p-error">Campo obrigatório.</small>
                                                                </div>
                                                            </div>
                                                            <div class="flex flex-column w-full" style="gap: 5px">
                                                                <label for="evaluatorComments">Comentários:</label>
                                                                <InputText
                                                                    id="evaluatorComments"
                                                                    autocomplete="off"
                                                                    v-model="metric.evaluatorComments"
                                                                    :autoResize="true"
                                                                    rows="3"
                                                                    cols="30"
                                                                    @blur="formInputSave(metric.ID, metric.responseValue, metric.evaluatorComments)" />
                                                            </div>
                                                        </div>
                                                    </template>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </TabPanel>
                    </TabView>
                </template>
            </Card>
        </div>
        <div v-else-if="assessment.sidebarMetrics.scoreResultsStep">
            <Card class="w-full flex flex-column justify-content-between p-4 shadow-8 mt-4">
                <template #header>
                    <div class="flex justify-content-center mb-4">
                        <lottie :lottie-prop="lottieCongratulations" />
                    </div>
                </template>

                <template #title>
                    <div class="text-center mb-6">
                        <h1 class="font-bold text-5xl mb-2">Você finalizou a avaliação</h1>
                        <div class="flex flex-column" style="gap: 2px">
                            <span class="text-600 text-xl">Esta é uma análise preliminar dos resultados, focada exclusivamente nas métricas qualitativas.</span>
                            <span class="font-light text-600 text-lg">Você pode visualizar mais informações sobre esta avaliação no menu de avaliações</span>
                        </div>
                    </div>
                </template>

                <template #content>
                    <div class="grid justify-content-center" style="gap: 30px">
                        <Card class="p-8 shadow-8" style="min-width: 440px; max-width: 440px" v-for="result in assessment.groupedScoreResult.scoreByAssessmentMetricGroup" :key="result">
                            <template #title>
                                <div class="text-center mb-4">
                                    <h2 class="font-bold text-4xl mb-1">{{ result.name }}</h2>
                                    <p class="font-light text-xl text-700">Pontuação total:</p>
                                </div>
                            </template>
                            <template #content>
                                <div class="flex justify-content-center align-items-center h-20rem">
                                    <!-- <Knob v-model="result.totalScore" :size="250" :max="1000" readonly /> -->
                                    <span class="font-bold text-8xl">{{ result.totalScore }}</span>
                                </div>
                            </template>
                        </Card>
                    </div>
                </template>

                <template #footer>
                    <div class="flex justify-content-end">
                        <Button class="p-button-lg" label="Ver todas as avaliações" @click="this.assessment.sidebarMetrics.display = false" />
                    </div>
                </template>
            </Card>
        </div>
    </Sidebar>
    <!-- Sidebar for Assessment Details -->
    <Sidebar v-model:visible="assessmentDetailsSidebar.display" :baseZIndex="1000" position="full" :showCloseIcon="false">
        <!-- Loading and Error States -->
        <div v-if="assessmentDetailsSidebar.loading && !assessmentDetailsSidebar.error" class="flex flex-column justify-content-center align-items-center w-full h-screen" style="gap: 10px">
            <progress-template />
            <span class="text-2xl font-bold">Aguarde, estamos carregando algumas informações...</span>
        </div>
        <div v-else-if="!assessmentDetailsSidebar.loading && assessmentDetailsSidebar.error" class="flex flex-column justify-content-center align-items-center w-full h-screen" style="gap: 10px">
            <lottie :lottieProp="this.lottieError" />
            <span class="text-2xl font-bold">Algumas coisas não saíram como esperado, estamos com dificuldade para carregar as informações</span>
            <span class="text-2xl font-bold">Por favor acione o S.O.G.E para verificação</span>
            <span class="text-xl font-bold"
                >Informações Técnicas: <span class="text-orange-600">{{ assessmentDetailsSidebar.error }}</span></span
            >
        </div>
        <!-- Assessment Details Content -->
        <div class="fadein animation-duration-1000 animation-ease-in h-screen px-2" v-else>
            <div class="flex flex-column md:flex-row justify-content-between align-items-start md:align-items-center">
                <div class="flex flex-column w-full">
                    <div class="flex flex-row pl-2 pr-2 justify-content-between align-items-center">
                        <img alt="logo-fibralink" :src="assessmentDetailHeaderLogo" width="350" />
                        <div class="flex justify-content-end w-full" style="gap: 5px">
                            <Button
                                v-if="!assessmentDetailsSidebar.loading"
                                icon="fa-solid fa-file-csv fa-lg"
                                class="p-button-rounded p-button-outlined p-button-rounded"
                                title="Exportar avaliação para CSV"
                                @click="exportAssessmentToCSV()" />
                            <Button
                                v-if="!assessmentDetailsSidebar.loading"
                                icon="fas fa-search-minus fa-lg"
                                class="p-button-rounded p-button-outlined"
                                title="Reduzir zoom de exibição"
                                @click="decrementZoom()" />
                            <Button
                                v-if="!assessmentDetailsSidebar.loading"
                                icon="fas fa-search-plus fa-lg"
                                class="p-button-rounded p-button-outlined"
                                title="Aumentar zoom de exibição"
                                @click="incrementZoom()" />
                            <Button icon="fas fa-times fa-lg" class="p-button-rounded p-button-outlined" title="Fechar janela (ESC)" @click="assessmentDetailsCloseSidebar()" style="color: red" />
                        </div>
                    </div>
                    <hr />
                    <div class="flex flex-column justify-content-between col-12">
                        <div class="text-2xl font-bold dark:text-surface-0">RELATÓRIO DE PERFORMANCE INDIVIDUAL DO COLABORADOR</div>
                        <hr />
                        <div class="text-xl font-semibold text-start mb-2 mt-2">INFORMAÇÕES:</div>
                        <div class="flex justify-content-between">
                            <div class="flex flex-column" style="gap: 5px">
                                <div class="flex" style="gap: 5px">
                                    <span class="font-bold text-lg w-12rem">Avaliação iniciada por:</span>
                                    <span class="font-light text-lg">{{ assessmentDetailsSidebar.assessment?.startedByPerson?.name }}</span>
                                </div>
                                <div class="flex" style="gap: 5px">
                                    <span class="font-bold text-lg w-12rem">Colaborador avaliado:</span>
                                    <span class="font-light text-lg">{{ assessmentDetailsSidebar.assessment?.evaluatedCollaborator?.person?.name }}</span>
                                </div>
                                <div class="flex" style="gap: 5px" v-if="assessmentDetailsSidebar.assessment?.title">
                                    <span class="font-bold text-lg w-12rem">Título:</span>
                                    <span class="font-light text-lg">{{ assessmentDetailsSidebar.assessment?.title }}</span>
                                </div>
                                <div class="flex" style="gap: 5px" v-if="assessmentDetailsSidebar.assessment?.description">
                                    <span class="font-bold text-lg w-12rem">Descrição:</span>
                                    <span class="font-light text-lg">{{ assessmentDetailsSidebar.assessment?.description }}</span>
                                </div>
                            </div>
                            <div class="flex flex-column" style="gap: 5px">
                                <div class="flex" style="gap: 5px">
                                    <span class="font-semibold mr-12 text-lg w-8rem">Referência:</span>
                                    <span class="text-lg">{{ assessmentDetailsSidebar.assessment?.referenceBeginningDate }} - {{ assessmentDetailsSidebar.assessment?.referenceEndDate }}</span>
                                </div>
                                <div class="flex" style="gap: 5px">
                                    <span class="font-semibold mr-12 text-lg w-8rem">Iniciada em:</span>
                                    <span class="text-lg">{{ assessmentDetailsSidebar.assessment?.startedAt }}</span>
                                </div>
                                <div class="flex" style="gap: 5px">
                                    <span class="font-semibold mr-12 text-lg w-8rem">Finalizado em:</span>
                                    <span class="text-lg">{{ assessmentDetailsSidebar.assessment?.finishedAt }}</span>
                                </div>
                                <div class="flex" style="gap: 5px">
                                    <span class="font-semibold mr-16 text-lg w-8rem">Pontuação:</span>
                                    <span class="text-lg">{{ assessmentDetailsSidebar.totalScore || 0 }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div class="flex flex-column pb-6">
                <DataTable
                    v-if="assessmentDetailsSidebar.assessmentMetrics"
                    dataKey="ID"
                    class="p-datatable-md"
                    ref="assessmentDt"
                    responsiveLayout="scroll"
                    showGridlines
                    :exportFilename="`${assessmentDetailsSidebar.assessment?.title ?? 'avaliacao_colaborador'}`"
                    :value="assessmentDetailsSidebar.assessmentMetrics"
                    :paginator="false"
                    :sortField="'assessmentMetricGroup.name'"
                    :sortOrder="1">
                    <!-- Columns -->
                    <template #header>
                        <div class="flex flex-column w-full p-1 text-800">
                            <div class="flex align-items-center" style="gap: 5px">
                                <i class="fa-solid fa-chart-simple fa-lg" />
                                <span class="font-bold text-xl">Pontuação detalhada:</span>
                            </div>
                            <div class="flex flex-column w-full mt-2">
                                <div class="flex pt-1 pb-1" style="gap: 4px" v-for="item in assessmentDetailsSidebar.scoreByAssessmentMetricGroup" :key="item">
                                    <span class="font-light text-lg w-10rem">{{ item.name }}:</span>
                                    <span class="font-bold text-xl">{{ item.totalScore }}</span>
                                </div>
                            </div>
                            <div class="flex align-items-center justify-content-end mt-2 text-bluegray-500" style="gap: 4px">
                                <i class="fa-solid fa-circle-info" />
                                <span class="font-light text-sm">As informações de pontuação exibidas são calculadas com base exclusivamente em métricas qualitativas.</span>
                            </div>
                        </div>
                    </template>
                    <Column field="assessmentMetricGroup.name" header="Grupo" sortable headerStyle="width:4%; min-width:2rem;" />
                    <Column field="assessmentMetricType.name" header="Tipo" sortable headerStyle="width:4%; min-width:2rem;" />
                    <Column field="assessmentMetric.title" header="Métrica" sortable headerStyle="width:6%; min-width:2rem;" />
                    <Column field="weight" header="Peso" headerStyle="width:2%; min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex justify-content-start">
                                <span>{{ slotProps.data?.weight ?? 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="estimatedValue" header="Vlr. ponto" headerStyle="width:2%; min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex justify-content-start">
                                <span>{{ slotProps.data?.estimatedValue ?? 'N/A' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="responseValue.number" header="Resposta" headerStyle="width:2%; min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex justify-content-start">
                                <span>{{ slotProps.data?.responseValue?.number ?? slotProps.data?.responseValue?.text ?? 'NÃO PREENCHIDO' }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="score" header="Total" headerStyle="width:2%; min-width:2rem;">
                        <template #body="slotProps">
                            <div class="flex justify-content-start">
                                <span v-if="slotProps.data.assessmentMetricType?.code === assessmentMetricTypeCodes.QUALITATIVE">{{ slotProps.data?.score ?? 'N/A' }}</span>
                                <span v-else>N/A</span>
                            </div>
                        </template>
                    </Column>
                    <Column field="evaluator.person.name" header="Avaliador" headerStyle="width:6%; min-width:3rem;" />
                    <Column field="evaluatorComments" header="Comentários do avaliador" headerStyle="width:6%; min-width:3rem;" />
                </DataTable>
                <div v-if="!assessmentDetailsSidebar.assessmentMetrics" class="flex flex-column justify-content-center align-items-center w-full mt-8" style="gap: 10px">
                    <lottie :lottieProp="this.lottieError" />
                    <span class="text-2xl font-bold">Algumas informações não foram carregadas corretamente.</span>
                    <span class="text-2xl font-bold">Por favor acione o S.O.G.E para verificação</span>
                </div>
            </div>
        </div>
    </Sidebar>
</template>

<style scoped>
    :deep(.row-assessment-filling) {
        background-color: rgba(94, 255, 0, 0.3) !important;
    }
</style>
