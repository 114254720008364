<script>
import { FilterMatchMode } from "primevue/api";
import { defineComponent } from "vue";
import FilterModal from "../../Utils/Filter.vue";
import ProgressModal from "../../Utils/Progress.vue";
import MenuModal from "./MenuModal.vue";
import MenuRouter from "@/api/routers/Menu";
export default defineComponent({
  components: {
    FilterModal,
    ProgressModal,
    MenuModal,
  },
  data() {
    return {
      loading: false,
      pagination: {},
      pageRows: 10,
      filterModal: {
        show: false,
        showFilters: ["menus", "general"],
        selectedFilters: {},
      },
      menus: {
        records: [],
        totalRecords: 0,
        selection: [],
      },
      hasFilters: false,
      menuModal: {
        show: false,
        save: false,
        valid: false,
        progress: false,
        action: "create",
        title: null,
        menu: {},
      },
      filters: {
        name: {
          value: "",
          matchMode: "contains",
        },
      },
      MenuApi: null,
    };
  },
  created() {
    this.MenuApi = new MenuRouter();
    this.FilterInit();
  },
  mounted() {
    this.pageRows = this.$refs.dt.rows;
    this.LoadMenus();
  },
  methods: {
    ShowMenuModal(action, slot) {
      this.menuModal.action = action;
      this.menuModal.menu = action == "update" ? { ...slot } : null;
      this.menuModal.title = `${
        action == "create" ? "Cadastrar" : "Atualizar"
      } menu`;
      this.menuModal.show = true;
    },
    Valid(event) {
      event ? (this.menuModal.valid = true) : (this.menuModal.valid = false);
    },
    MenuModalResetState() {
      this.menuModal.show = false;
      this.menuModal.valid = false;
      this.menuModal.save = false;
      this.menuModal.menu = {};
      this.LoadMenus();
    },
    MenusRemove() {
      this.$confirm.require({
        header: "Excluir",
        message:
          "Ao realizar a exclusão não será possível desfazer a operação, deseja continuar?",
        icon: "far fa-question-circle",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        acceptClass: "p-button-danger",
        accept: () => {
          this.MenuApi.Remove(
            this.menus.selection.map((m) => {
              return m.ID;
            })
          )
            .then(() => {
              this.$toast.add({
                severity: "success",
                summary: "Sucesso",
                detail: "Operação realizada com sucesso",
                life: 3000,
              });
            })
            .finally(this.LoadMenus());
        },
      });
    },
    FindParent(menuId) {
      let parent = this.menus.records.find((m) => m.ID === menuId);
      return parent?.label;
    },
    LoadMenus() {
      this.loading = true;
      setTimeout(() => {
        this.MenuApi.Search(JSON.stringify(this.pagination))
          .then((resp) => {
            this.menus.records = resp.menus;
            this.menus.totalRecords = resp.totalRecords;
          })
          .finally((this.loading = false));
      }, Math.random() * 1000 + 250);
    },
    CloseFilterModal() {
      this.filterModal.show = false;
    },
    ShowFilterModal() {
      this.filterModal.show = true;
    },
    MakeFilter(filter) {
      this.filterModal.selectedFilters = Object.assign(
        {},
        this.filterModal.selectedFilters,
        filter
      );
      this.filters.global = Object.assign({}, this.filters.global, filter);
    },
    ClearFilters() {
      this.filterModal.selectedFilters = [];
      this.FilterInit();
      this.hasFilters = false;
      this.OnFilter();
    },
    FilterSearch() {
      this.filterModal.show = false;
      this.OnFilter();
    },
    OnPage(event) {
      this.pagination = event;
      this.LoadMenus();
    },
    OnFilter() {
      if (Object.keys(this.filterModal.selectedFilters).length > 0) {
        this.hasFilters = true;
      }
      this.pagination.filters = this.filters;
      this.LoadMenus();
    },
    FilterInit() {
      this.pagination = {
        page: 1,
        rows: this.pageRows,
      };
      this.filters = {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS,
        },
      };
    },
  },
});
</script>

<template>
  <Card>
    <template #title>
      <span class="font-light text-700 text-xl">Menus</span>
    </template>
    <template #content>
      <Toolbar class="h-6rem">
        <template v-slot:start>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-plus"
              class="p-button-rounded p-button-success p-button-outlined p-button-raised"
              @click="ShowMenuModal('create')"
              :disabled="loading"
              title="Adicionar novo menu"
            />
            <Button
              icon="fas fa-trash"
              class="p-button-rounded p-button-danger p-button-outlined p-button-raised"
              @click="MenusRemove()"
              :disabled="menus.selection.length == 0"
              title="Excluir menus(s) selecionado(s)"
            />
          </div>
        </template>
        <template v-slot:end>
          <div class="lg:mt-0 sm:mt-2 flex flex-row" style="gap: 0.5rem" >
            <Button
              icon="fas fa-redo-alt"
              class="p-button-rounded p-button-info p-button-outlined p-button-raised"
              @click="LoadMenus()"
              :disabled="loading"
              title="Atualizar lista"
            />
            <div class="relative">
              <Button
                icon="fas fa-filter"
                class="p-button p-button-rounded p-button-info p-button-outlined p-button-raised"
                @click="ShowFilterModal()"
                :disabled="loading"
                title="Escolher filtros"
              />
              <Button
                v-if="hasFilters"
                icon="fas fa-broom"
                class="p-button-rounded p-button-raised clear-filter"
                title="Limpar filtros"
                @click="ClearFilters()"
                :disabled="loading"
              />
            </div>
          </div>
        </template>
      </Toolbar>
    </template>
    <template #footer>
      <DataTable
        ref="dt"
        :value="menus.records"
        dataKey="ID"
        :paginator="true"
        :rows="10"
        v-model:filters="filters"
        :loading="loading"
        :totalRecords="menus.totalRecords"
        @page="OnPage($event)"
        @filter="OnFilter($event)"
        @sort="onSort($event)"
        lazy="true"
        v-model:selection="menus.selection"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[10, 20, 50, 100, 300]"
        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} entradas"
        responsiveLayout="scroll"
        class="p-datatable-sm"
      >
        <template #header />
        <Column
          selectionMode="multiple"
          headerStyle="width:.2%; min-width:1rem;"
        />
        <Column
          field="ID"
          header="ID"
          :sortable="false"
          headerStyle="width:.30%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">ID</span>
            <span>{{ slotProps.data?.ID }}</span>
          </template>
        </Column>
        <Column
          field="name"
          header="Nome"
          :sortable="false"
          headerStyle="width:1%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Nome</span>
            <span>{{ slotProps.data?.label }}</span>
          </template>
        </Column>
        <Column
          field="icon"
          header="Ícone"
          :sortable="false"
          headerStyle="width:.4%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Icone</span>
            <i :class="slotProps.data?.icon" />
          </template>
        </Column>
        <Column
          field="type"
          header="Tipo"
          :sortable="false"
          headerStyle="width:.6%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Tipo</span>
            <span>{{ slotProps.data?.menuType.label }}</span>
          </template>
        </Column>
        <Column
          field="action"
          header="Ação"
          :sortable="false"
          headerStyle="width:2%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Ação</span>
            <span>{{ slotProps.data?.action }}</span>
          </template>
        </Column>
        <Column
          field="order"
          header="Ordem"
          :sortable="false"
          headerStyle="width:1%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Ordem</span>
            <span>{{ slotProps.data?.showOrder }}</span>
          </template>
        </Column>
        <Column
          field="parent"
          header="Pai"
          :sortable="false"
          headerStyle="width:2%; min-width:2rem;"
        >
          <template #body="slotProps">
            <span class="p-column-title">Pai</span>
            <span>{{ FindParent(slotProps.data?.parentId) }}</span>
          </template>
        </Column>
        <Column headerStyle="width: 1%; min-width:5rem;">
          <template #body="slotProps">
            <Button
              icon="fas fa-pencil"
              class="p-button-rounded p-button-sm p-button-warning p-button-outlined p-button-raised mr-2 action-button"
              title="Editar pessoa"
              @click="ShowMenuModal('update', slotProps.data)"
            />
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
  <Dialog
    v-model:visible="menuModal.show"
    :header="!menuModal.progress ? menuModal.title : 'Aguarde...'"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
    :closable="!menuModal.progress"
    @hide="MenuModalResetState()"
  >
    <MenuModal
      v-if="!menuModal.progress"
      :menu="menuModal.menu"
      :action="menuModal.action"
      :save="menuModal.save"
      @valid="Valid($event)"
      @progress="menuModal.progress = $event"
      @close="menuModal.show = false"
    />
    <ProgressModal v-if="menuModal.progress" />
    <template #footer>
      <div
        class="flex flex-row justify-content-between"
        v-if="!menuModal.progress"
      >
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="menuModal.show = false"
          class="w-12rem"
        />
        <Button
          label="Salvar"
          iconPos="right"
          icon="pi pi-check"
          autofocus
          :disabled="!menuModal.valid"
          @click="menuModal.save = true"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="filterModal.show"
    header="Filtro avançado"
    :modal="true"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '30vw' }"
    :maximizable="false"
  >
    <FilterModal
      @filterSelection="MakeFilter($event)"
      :showFilters="filterModal.showFilters"
      :currentFilters="filterModal.selectedFilters"
    />
    <template #footer>
      <div class="flex flex-row justify-content-between">
        <Button
          label="Cancelar"
          iconPos="right"
          icon="pi pi-times"
          autofocus
          @click="CloseFilterModal()"
          class="w-12rem"
        />
        <Button
          label="Pesquisar"
          iconPos="right"
          icon="pi pi-search"
          autofocus
          @click="FilterSearch()"
          class="w-12rem"
        />
      </div>
    </template>
  </Dialog>

</template>
